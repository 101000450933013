import {
    Avatar,
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid, Link,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import React, {useState} from "react";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {Email, PhoneNumber, HoverIconButton} from "@cambianrepo/cambianreact";
import {useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {
    ACCEPT_CONNECTION_REQUEST,
    IGNORE_CONNECTION_REQUEST,
    ORGANIZATION_DISCONNECT_REQUEST,
    CHANGE_USER_PROFILE
} from "../../../../gqlMutators";
import {
    GET_CLIENT_ATTRIBUTE_SET,
    CURRENT_CAMBIAN_USER_FULL_DETAILS
} from "../../../../gqlQueries";
import {setSelectedOrganizationId, JURISDICTIONS} from "../../../utility";
import Loader from "../../../Loader";
import { useAuthContext } from "navigator/security";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

function ConnectedOrganization(props) {
    const {selectedViewCallback, organization, status} = props;
    const { t } = useTranslation();
    const { currentUser } = useAuthContext();
    const theme = useTheme();
    const isGreaterThanSm = useMediaQuery(theme.breakpoints.up('sm'));

    let organizationId = organization.organizationId;
    let name = organization.name;
    let tagLine = organization.tagLine;
    let logoImageUrl = organization.logoImageUrl;

    const [anchorConnectedEl, setAnchorConnectedEl] = useState(null);
    const [anchorNewConnectionEl, setNewAnchorConnectionEl] = useState(null);
    const [ignoreConnectionRequest, setIgnoreConnectionRequest] = useState(false);
    const [organizationRemoveRequest, setOrganizationRemoveRequest] = useState(false);
    const [acceptConnectionRequest, setAcceptConnectionRequest] = useState(false);
    const [consentAgreeementUrl, setConsentAgreeementUrl] = useState("");
    const [updateProfileRequest, setUpdateProfileRequest] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [connectionOperationPending, setConnectionOperationPending] = useState(false);
    const [newEmail, setNewEmail] = React.useState("");
    const [newPhone, setNewPhone] = React.useState("");
    const getConnectionDetails = (organization) => {
        console.log(organization);
        let cambianUserId = currentUser.userReference.globalId;
        let targetUserId = organization.organizationUserId;
        let orgId = organization.organizationId;
        let requestMessageId;
        let taskId;

        if (status === 'PENDING') {
            organization.connectionRequest.forEach((item, index) => {
                if (item.state === 'PENDING') {
                    requestMessageId = item.requestMessageId;
                    taskId = item.taskId;
                }
            });
        }

        return(
            {
                cambianUserId: cambianUserId,
                targetUserId: targetUserId,
                requestMessageId: requestMessageId,
                taskId: taskId,
                orgId: orgId
            }
        );
    }
    
    
    const [connectionData] = React.useState(status === 'PENDING' ? getConnectionDetails(organization): null);

    const isConnectedOpen = Boolean(anchorConnectedEl);
    const isNewConnectionOpen = Boolean(anchorNewConnectionEl);

    const [ignoreConnectionRequestGraphQL] = useMutation(IGNORE_CONNECTION_REQUEST);
    const [acceptConnectionRequestGraphQL] = useMutation(ACCEPT_CONNECTION_REQUEST);
    const [organizationDisconnectGraphQL] = useMutation(ORGANIZATION_DISCONNECT_REQUEST);

    const {data: clientAttributeSet, refetch: refetchClientAttributeSet} = useQuery(GET_CLIENT_ATTRIBUTE_SET, {
        variables: {
            "OrganizationId": organizationId
        }
    });

    const {data: fullDetails, refetch: refetchFullDetails} = useQuery(CURRENT_CAMBIAN_USER_FULL_DETAILS);

    const [saveUserProfileFunction] = useMutation(CHANGE_USER_PROFILE, {
        variables: {
            "editCambianUser": userDetails
        }
    });

    const buildConsentAgreementUrl = (organizationId) => {
        let consentAgreementUrl = process.env.REACT_APP_GATEWAY_HOST + "/consent/consentdocuments/" + organizationId;
        console.log(consentAgreementUrl)
        return consentAgreementUrl;
    }

    const refreshNetworkConnectionList = () => {
        window.location.replace(process.env.REACT_APP_WEBSITE_CONTEXT);
    }

    const handleHoverIconClick = (event) => {
        if (status === 'CONNECTED') {
            setAnchorConnectedEl(event.currentTarget);
        } else if (status === 'PENDING') {
            setNewAnchorConnectionEl(event.currentTarget);
        }
    };

    const handleClose = (event) => {
        console.log('handle close');
        if (status === 'CONNECTED') {
            setAnchorConnectedEl(null);
        } else if (status === 'PENDING') {
            setNewAnchorConnectionEl(null);
        }
    };

    const handleViewChange = (status, organizationId, view) => {
        if (status === "CONNECTED") {
            setSelectedOrganizationId(organizationId);
            setAnchorConnectedEl(null);
            selectedViewCallback(view);
        }
    };

    const handleOrganizationDisconnect = (organizationId) => {
        console.log("Disconnect from Organization " + organizationId)
        setAnchorConnectedEl(null);
        setConnectionOperationPending(true);

        organizationDisconnectGraphQL({
            variables: {
                "CambianUserId": currentUser.userReference.globalId,
                "TargetUserId": organization.organizationUserId,
                "OrgId": organization.organizationId,
            }
        }).then(() => {
            refreshNetworkConnectionList();
	    setConnectionOperationPending(false);
        });
    }

    const handleIgnoreConnectionMenuSelection = () => {
        console.log('handleIgnoreConnectionMenuSelection');
        setNewAnchorConnectionEl(null);
        setIgnoreConnectionRequest(true);
    }


    const handleIgnoreConnectionRequest = () => {
        let taskId =  connectionData.taskId;
        console.log('handleIgnoreConnectionRequest  TaskId: ' + taskId);
        setConnectionOperationPending(true);
        setIgnoreConnectionRequest(false);

        ignoreConnectionRequestGraphQL({
                                        variables: {
                                            "TaskId": taskId
                                        }
                                    })
        .then(() => {
            refreshNetworkConnectionList();
            setConnectionOperationPending(false);
        });
    }

    function createUserProfileInput(userDetails) {
        let initialUserDetails = {
            ...userDetails,
            userEmailList: [],
            userPhoneList: [],
            primaryEmail: '',
            primaryPhone: ''
        }

        userDetails.userIdentity.forEach(item => {
            if (item.type === 'EMAIL') {
                initialUserDetails.userEmailList.push(item.value);
                if (item.primary) {
                    initialUserDetails.primaryEmail = item.value;
                }
            } else if (item.type === 'CELL_PHONE') {
                initialUserDetails.userPhoneList.push(item.value);
                if (item.primary) {
                    initialUserDetails.primaryPhone = item.value;
                }
            }
        });

        delete initialUserDetails['__typename'];
        delete initialUserDetails['patientReference'];
        delete initialUserDetails['userIdentity'];
        delete initialUserDetails['userReference'];
        return initialUserDetails;
    }

    const handleAcceptConnectionRequest = () => {
        if (fullDetails.currentCambianUser) {
            setAcceptConnectionRequest(false);

            let currentCambianUser = fullDetails.currentCambianUser;
            let fieldsRequired = determineFieldsRequiredByOrganization(
                                      clientAttributeSet.getClientAttributeSet, currentCambianUser);

            if (fieldsRequired && fieldsRequired.length) {
                setUserDetails(createUserProfileInput(currentCambianUser));
                setUpdateProfileRequest(true);
            } else {
                acceptConnection();
            }
        }
    }

    const acceptConnection = () => {
        setConnectionOperationPending(true);
        acceptConnectionRequestGraphQL({
            variables: {
                "CambianUserId": connectionData.cambianUserId,
                "TargetUserId": connectionData.targetUserId,
                "RequestMessageId": connectionData.requestMessageId,
                "OrgId": connectionData.orgId,
            }
        }).then(() => {
            refreshNetworkConnectionList();
            setConnectionOperationPending(false);
        });
    }

    const [showPRCity, setShowPRCity] = useState(false);
    const [showPRDateOfBirth, setShowPRDateOfBirth] = useState(false);
    const [showPREmail, setShowPREmail] = useState(false);
    const [showPRFirstName, setShowPRFirstName] = useState(false);
    const [showPRLastName, setShowPRLastName] = useState(false);
    const [showPRPhone, setShowPRPhone] = useState(false);
    const [showPRHealthcareIdValue, setShowPRHealthcareIdValue] = useState(false);
    const [showPRHealthcareIdType, setShowPRHealthcareIdType] = useState(false);
    const [showPRPostalCode, setShowPRPostalCode] = useState(false);
    const [showPRProvince, setShowPRProvince] = useState(false);
    const [showPRCountry, setShowPRCountry] = useState(false);
    const [showPRGender, setShowPRGender] = useState(false);
    const [showPRAddress1] = useState(false);
    const [showPRAddress2] = useState(false);

    const determineFieldsRequiredByOrganization = (clientAttributeSet, currentCambianUser) => {
        console.log("determineFieldsRequiredByOrganization");

        var fieldsRequired = [];

        if (clientAttributeSet.cityRequired && !currentCambianUser.city) {
            fieldsRequired.push("city");
            setShowPRCity(true);
        }
        if (clientAttributeSet.dateOfBirthRequired &&
            !currentCambianUser.birthMonth && !currentCambianUser.birthDay && !currentCambianUser.birthYear) {
            fieldsRequired.push("dateOfBirth");
            setShowPRDateOfBirth(true);
        }
        if (clientAttributeSet.emailRequired && !currentCambianUser.email) {
            fieldsRequired.push("email");
            setShowPREmail(true);
        }
        if (clientAttributeSet.firstNameRequired && !currentCambianUser.firstName) {
            fieldsRequired.push("firstName");
            setShowPRFirstName(true);
        }
        if (clientAttributeSet.lastNameRequired && !currentCambianUser.lastName) {
            fieldsRequired.push("lastName");
            setShowPRLastName(true);
        }
        if (clientAttributeSet.phoneNumberRequired && !currentCambianUser.phone) {
            fieldsRequired.push("phone");
            setShowPRPhone(true);
        }
        if (clientAttributeSet.phnRequired &&
            !currentCambianUser.healthcareIdValue && !currentCambianUser.healthcareIdType) {
            fieldsRequired.push("healthcareIdValue");
            fieldsRequired.push("healthcareIdType");
            setShowPRHealthcareIdValue(true);
            setShowPRHealthcareIdType(true);
        }
        if (clientAttributeSet.postalCodeRequired && !currentCambianUser.postalCode) {
            fieldsRequired.push("postalCode");
            setShowPRPostalCode(true);
        }
        if (clientAttributeSet.provinceStateRequired && !currentCambianUser.province) {
            fieldsRequired.push("province");
            setShowPRProvince(true);
        }
        if (clientAttributeSet.countryRequired && !currentCambianUser.country) {
            fieldsRequired.push("country");
            setShowPRCountry(true);
        }
        if (clientAttributeSet.genderRequired && !currentCambianUser.gender) {
            fieldsRequired.push("gender");
            setShowPRGender(true);
        }

        return fieldsRequired;
    }

    function isTextValid(text) {
        return (text !== undefined && text !== null && text.length > 0);
    }

    function isNumberValid(num) {
        return (num !== undefined && num !== null && !isNaN(num));
    }

    const readyToSave = () => {
        let result = true;
        if (userDetails) {
            if (showPREmail === true && !isTextValid(newEmail)) {
                return false;
            }
            if (showPRLastName === true && !isTextValid(userDetails.lastName)) {
                return false;
            }
            if (showPRFirstName === true && !isTextValid(userDetails.firstName)) {
                return false;
            }
            if (showPRDateOfBirth === true && (!isNumberValid(userDetails.birthDay) || !isNumberValid(userDetails.birthMonth) ||
                                                   !isNumberValid(userDetails.birthYear))) {
                return false;
            }
            if (showPRGender === true && !isTextValid(userDetails.gender)) {
                return false;
            }
            if (showPRPhone === true && !isTextValid(newPhone)) {
                return false;
            }
            if (showPRAddress1 === true && !isTextValid(userDetails.address1)) {
                return false;
            }
            if (showPRAddress2 === true && !isTextValid(userDetails.address2)) {
                return false;
            }
            if (showPRCity === true && !isTextValid(userDetails.city)) {
                return false;
            }
            if (showPRProvince === true && !isTextValid(userDetails.province)) {
                return false;
            }
            if (showPRPostalCode === true && !isTextValid(userDetails.postalCode)) {
                return false;
            }
            if (showPRCountry === true && !isTextValid(userDetails.country)) {
                return false;
            }
            if (showPRHealthcareIdType === true && !isTextValid(userDetails.healthcareIdType)) {
                return false;
            }
            if (showPRHealthcareIdValue === true && !isTextValid(userDetails.healthcareIdValue)) {
                return false;
            }
        }

        return result;
    }

    const updateProfileData = (type, operation, key, value) => {
        // adding [key]: value to userDetails means we are adding this info to patient db
        // in this case, the email or phone being added should be the very first (only) email/phone so it needs to be set in the patient db
        // meanwhile in EditUserProfile.jsx, the email or phone being added may not be the first so it does not need to be set in patient db
        // hence setUserDetails must be done for all changes, not only "property" changes
        setUserDetails(prevState => ({
            ...prevState,
            [key]: value
        }));
        if (type === 'emailList') {
            if (operation === 'add') {
                console.log('add ' + value);
                if (userDetails.userEmailList.indexOf(value) === -1) {
                    userDetails.userEmailList.push(value);
                }
            } 
        } else if (type === 'phoneList') {
            if (operation === 'add') {
                console.log('add phone ' + value);
                if (userDetails.userPhoneList.indexOf(value) === -1) {
                    userDetails.userPhoneList.push(value);
                }
            }
        }
    }

    const handleTextFieldChange = (event, key) => {
        updateProfileData("property", "change", key, event.target.value);
    }
    const handleAddNewPhone = (phoneNumber) => {
        if (phoneNumber.trim().length > 0) {
            updateProfileData("phoneList", "add", "phone", phoneNumber)
        }
    }
    const handleAddNewEmail = (email) => {
        if (email.trim().length > 0) {
            updateProfileData("emailList", "add", "email", email)
        }
    }
    const [selectedGender, setSelectedGender] = React.useState("");
    const handleGenderSelection = (event) => {
        setSelectedGender(event.target.value);
        updateProfileData("property", "change", "gender", event.target.value);
    }

    const [birthDate, setBirthDate] = React.useState(null);
    const handleBirthDateChange = (newValue) => {
        if (newValue !== null) {
            setBirthDate(newValue);
            updateProfileData("property", "change", "birthMonth", newValue.getMonth() + 1);
            updateProfileData("property", "change", "birthDay", newValue.getDate());
            updateProfileData("property", "change", "birthYear", newValue.getFullYear());
        } else {
            setBirthDate(null);
            updateProfileData("property", "change", "birthMonth", null);
            updateProfileData("property", "change", "birthDay", null);
            updateProfileData("property", "change", "birthYear", null);
        }
    };

    const saveUserProfile = () => {
        handleAddNewEmail(newEmail);
        handleAddNewPhone(newPhone);
        saveUserProfileFunction()
            .then(result => {
                refetchClientAttributeSet();
                refetchFullDetails();
                acceptConnection();
            })
            .catch(error => {
                console.log(error);
            });
        setUpdateProfileRequest(false);
    }

    const handleAcceptConnectionButtonClick = (useOnlineConsent) => {
        console.log('handleAcceptConnectionButtonClick useOnlineConsent? ' + useOnlineConsent);
        if (useOnlineConsent) {
            setNewAnchorConnectionEl(null);
            setAcceptConnectionRequest(true);
            setConsentAgreeementUrl(buildConsentAgreementUrl(organizationId));
        } else {
            handleAcceptConnectionRequest();
        }
    }

    const organizationMenuItemList = (organizationId) => {
        let cambianOrganizationId = process.env.REACT_APP_CAMBIAN_ORGANIZATION_ID;
        if (organizationId === cambianOrganizationId) {
            return(
                <Menu id="connection-menu"
                      anchorEl={anchorConnectedEl}
                      open={isConnectedOpen}
                      onClose={handleClose}
                      MenuListProps={{
                          'aria-labelledby': 'basic-button',
                      }}>
                    <MenuItem onClick={() => handleViewChange(status, organizationId, 'OrganizationAboutView')}>{t("About")}</MenuItem>
                    <MenuItem onClick={() => handleViewChange(status, organizationId, 'OrganizationResourceView')}>{t("Resources")}</MenuItem>
                </Menu>
            );
        }

        return(
            <Menu id="connection-menu"
                  anchorEl={anchorConnectedEl}
                  open={isConnectedOpen}
                  onClose={handleClose}
                  MenuListProps={{
                      'aria-labelledby': 'basic-button',
                  }}>
                <MenuItem onClick={() => handleViewChange(status, organizationId, 'OrganizationAboutView')}>{t("About")}</MenuItem>
                <MenuItem onClick={() => handleViewChange(status, organizationId, 'OrganizationResourceView')}>{t("Resources")}</MenuItem>
                <MenuItem onClick={() => setOrganizationRemoveRequest(true)}>{t("Remove")}</MenuItem>
            </Menu>
        );
    }

    const buildDisconnectMessage = (organizationName) => {
        let text = t("Removing your connection to {ORGANIZATION_NAME} will permanently remove it from your network. This action cannot be undone.");
        return text.replace('{ORGANIZATION_NAME}', organizationName);
    }

    if (connectionOperationPending === true) {
        return(
            <Loader active={true}/>
        );
    }

    return (
        <Grid container direction="row"
              sx={{
                  '& .MuiTextField-root': {m: 1, width: '40ch'},
              }}>
            {
                isGreaterThanSm ? 
                <>
                    <Box component={Grid} item xs={1} display={{xs: "block"}} onClick={() => handleViewChange(status, organizationId, 'OrganizationAboutView')}>
                        <Avatar alt={name} src={logoImageUrl} sx={{ width: 40, height: 40 }}/>
                    </Box>

                    <Box component={Grid} item xs={8} display={{xs: "block"}} onClick={() => handleViewChange(status, organizationId, 'OrganizationAboutView')}>
                        <Typography sx={{ fontWeight: 'bold'}}>{name}</Typography>
                        <Typography variant="subtitle2">{tagLine}</Typography>
                    </Box>

                </> : 
                <>
                    <Box component={Grid} item xs={10} display={{xs: "block"}} onClick={() => handleViewChange(status, organizationId, 'OrganizationAboutView')}>
                        <Avatar alt={name} src={logoImageUrl} sx={{ width: 40, height: 40, marginBottom: 0.5 }}/>
                        <Typography sx={{ fontWeight: 'bold'}}>{name}</Typography>
                        <Typography variant="subtitle2">{tagLine}</Typography>
                    </Box>
                </>
            }

            <Box component={Grid} item xs={2} sm={3} display={{xs: "block"}}>
                <Stack direction="row-reverse" alignItems="right" spacing={2}>
                    <HoverIconButton variant={"subtitle1"}
                                     icon={<MoreHorizIcon/>}
                                     menuSelectionCallback={(event) => handleHoverIconClick(event)}/>
                    {status === 'PENDING'
                        ? <Button variant="contained"
                                  onClick={() => handleAcceptConnectionButtonClick(organization.useOnlineConsent)}>
                            {t("Connect")}
                          </Button>
                        : <></>}
                </Stack>
            </Box>

            {/* Produces menu item with About, Resource as items.  If anything other than Cambian org, it also produces Remove */}
            {organizationMenuItemList(organizationId)}

            <Menu id="new-connection-menu"
                  anchorEl={anchorNewConnectionEl}
                  open={isNewConnectionOpen}
                  onClose={handleClose}
                  MenuListProps={{
                      'aria-labelledby': 'basic-button',
                  }}>
                <MenuItem onClick={() => handleIgnoreConnectionMenuSelection()}>{t("Ignore")}</MenuItem>
            </Menu>

            <Dialog open={ignoreConnectionRequest}
                    onClose={() => setIgnoreConnectionRequest(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Ignore Connection Request?")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("This invitation will be permanently removed.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" 
                            onClick={() => setIgnoreConnectionRequest(false)}>
                        {t("No")}
                    </Button>
                    <Button variant="contained" 
                            onClick={() => handleIgnoreConnectionRequest()}>
                        {t("Ignore")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={acceptConnectionRequest}
                    onClose={() => setAcceptConnectionRequest(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Accept Connection Request?")}
                </DialogTitle>
                <DialogContent>
                    <span>{t("Please review the ")}</span>
                    <Link href={consentAgreeementUrl}
                          rel="noopener noreferrer"
                          target="_blank">Connection Consent Agreement</Link>
                    <span>{t(" document before accepting the connection request. ")}</span>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" 
                            onClick={() => setAcceptConnectionRequest(false)}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="contained" 
                            onClick={() => handleAcceptConnectionRequest()}>
                        {t("Accept")}
                    </Button>
               </DialogActions>
            </Dialog>


            <Dialog open={organizationRemoveRequest}
                    onClose={() => setOrganizationRemoveRequest(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Remove Connection?")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {buildDisconnectMessage(organization.name)}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" 
                            onClick={() => {
                                    setAnchorConnectedEl(null);
                                    setOrganizationRemoveRequest(false);
                                }}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="contained"
                            onClick={() => {
                                setAnchorConnectedEl(null);
                                setOrganizationRemoveRequest(false);
                                handleOrganizationDisconnect(organizationId);
                            }}>
                        {t("Remove")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={updateProfileRequest}
                    onClose={() => setUpdateProfileRequest(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Update Profile")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{paddingBottom: '15px'}}>
                        {t(name + " requires additional information about you.")}
                    </DialogContentText>
                    <Stack direction="column" alignItems="left" spacing={2}>
                        {showPREmail === true
                            ? <>
                                <Email 
                                    label=""
                                    text={t("Email")}
                                    isRequired={true}
                                    invalidEmailCallback={()=>{}}
                                    setMargin={'0'}
                                    validEmailCallback={(_,email) => setNewEmail(email)}/>
                            </>
                        : <div />}

                        {showPRLastName === true
                            ? <TextField required
                                         id="last-name"
                                         label={t("Last Name")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "lastName")}/>
                            : <></>}

                        {showPRFirstName === true
                            ? <TextField required
                                         id="first-name"
                                         label={t("First Name")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "firstName")}/>
                            : <></>}

                        {showPRDateOfBirth === true
                            ? <DesktopDatePicker required
                                                 label={t("Date of Birth")}
                                                 inputFormat="yyyy-MM-dd"
                                                 value={birthDate}
                                                 defaultValue="yyyy-mm-dd"
                                                 maxDate={Date.now()}
                                                 onChange={handleBirthDateChange}
                                                 renderInput={(params) => <TextField {...params} />}/>
                            : <></>}

                        {showPRGender === true
                            ? <TextField required
                                         id="gender"
                                         select
                                         label={t("Gender")}
                                         value={selectedGender.toUpperCase()}
                                         defaultValue=""
                                         onChange={handleGenderSelection}>
                                  <MenuItem value={""}></MenuItem>
                                  <MenuItem value={"MALE"}>{t("Male")}</MenuItem>
                                  <MenuItem value={"FEMALE"}>{t("Female")}</MenuItem>
                                  <MenuItem value={"OTHER"}>{t("Other")}</MenuItem>
                              </TextField>
                            : <></>}

                         {showPRPhone === true && 
                            <PhoneNumber id="add-phone" 
                                validPhoneNumberCallback={(phoneNumber) => setNewPhone(phoneNumber)}
                                text={t("Phone *")}
                                label=""/>
                        }
                        {showPRAddress1 === true
                            ? <TextField required
                                         id="address-1"
                                         label={t("Address Line 1")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "address1")}/>
                            : <></>}

                        {showPRAddress2 === true
                            ? <TextField required
                                         id="address-2"
                                         label={t("Address Line 2")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "address2")}/>
                            : <></>}

                        {showPRCity === true
                            ? <TextField required
                                         id="address-city"
                                         label={t("City")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "city")}/>
                            : <></>}

                        {showPRProvince === true
                            ? <TextField required
                                         id="address-province"
                                         label={t("Province")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "province")}/>
                            : <></>}

                        {showPRPostalCode === true
                            ? <TextField required
                                         id="address-postalcode"
                                         label={t("Postal Code")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "postalCode")}/>
                            : <></>}

                        {showPRCountry === true
                            ? <TextField required
                                         id="address-country"
                                         label={t("Country")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "country")}/>
                            : <></>}

                        {showPRHealthcareIdType === true
                            ? <TextField required
                                         select
                                         id="jurisdiction"
                                         label={t("Public Health Jurisdiction")}
                                         value={userDetails?.healthcareIdType??""}
                                         onChange={(event) => handleTextFieldChange(event, "healthcareIdType")}>
                                  {JURISDICTIONS.map((jurisdiction, idx) => (
                                      <MenuItem key={idx} value={jurisdiction}>{jurisdiction}</MenuItem>
                                  ))}
                              </TextField>
                            : <></>}

                        {showPRHealthcareIdValue === true
                            ? <TextField required
                                         id="hin"
                                         label={t("Personal Health Number")}
                                         defaultValue=""
                                         onChange={(event) => handleTextFieldChange(event, "healthcareIdValue")}/>
                            : <></>}
                    </Stack>
                    <DialogContentText id="alert-dialog-description" style={{paddingTop: '15px'}}>
                        {t("The information above will be saved to your profile.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        // reset birth date and gender
                        setBirthDate(null);
                        setSelectedGender("");
                        setUpdateProfileRequest(false);
                    }}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="contained" disabled={!readyToSave()} onClick={() => saveUserProfile()}>
                        {t("Save")}
                    </Button>
                </DialogActions>
            </Dialog>

        </Grid>
    );
}


export default ConnectedOrganization;