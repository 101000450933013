import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { CHANGE_PASSWORD } from "../../../gqlMutators";
import { useAuthService } from "../../security";
import { ChangePassword } from "@cambianrepo/cambianreact";
import { useTranslation } from "react-i18next";

function ChangePasswordPage(props) {
    const INTERNAL_SERVER_ERROR_MSG = "An unexpected error occured. Please contact technical support.";
    const INVALID_CURRENT_PASSWORD_ERROR_MSG = "Please re-enter your current password and try again."
    const { t } = useTranslation();
    const [passwordChangeSuccessful, setPasswordChangeSuccessful] = React.useState(false);

    const [changePasswordGraphQL] = useMutation(CHANGE_PASSWORD);
    const { logout } = useAuthService();

    const changePasswordCallback = async ({ currentPassword, newPassword }) => {
        try {
            const { data: { changePassword: isPasswordChanged } } = await changePasswordGraphQL({
                variables: {
                    CurrentPassword: currentPassword,
                    NewPassword: newPassword,
                },
            });
            if (isPasswordChanged) {
                setPasswordChangeSuccessful(true);
                return { success: true };
            }
            return { success: false, errorMsg: INTERNAL_SERVER_ERROR_MSG };
        } catch (error) {
            console.error("Failed to change password", error);
            if (error.message === INVALID_CURRENT_PASSWORD_ERROR_MSG) {
                return { success: false, errorMsg: INVALID_CURRENT_PASSWORD_ERROR_MSG }
            }
            return { success: false, errorMsg: INTERNAL_SERVER_ERROR_MSG };
        }

    }

    const handleCloseChangeResultModal = async () => {
        await logout(true);
    }

    return (
        <div style={{ overflowY: 'scroll', height: '100%' }}>
            <Grid container direction="row"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '40ch' }
                }}>

                <Box component={Grid} item xs={0} sm={2.5} md={4} display={{ xs: "hidden", md: "block" }} />
                <Box component={Grid} item xs={12} sm={7} md={4} display={{ xs: "block" }} sx={{ minWidth: { xs: "250px", sm: '450px' } }}>
                    <div className="componentBorder">
                        <Box sx={{
                            display: 'flex', alignItems: "center", justifyContent: "center",
                            '& .MuiInputLabel-root': {
                                color: 'grey'
                            },
                            '& .MuiIconButton-root': {
                                color: 'grey'
                            },
                        }} >
                            <ChangePassword changePasswordCallback={changePasswordCallback}
                                password={{min:8}}
                                showBackButton={false}
                                checkCurrentPassword={true}
                                inputBoxSize="medium"
                                inputLabelFontSize={16}
                                inputFontSize={16} />
                        </Box>
                    </div>
                </Box>
                <Box component={Grid} item xs={0} sm={2.5} md={4} display={{ xs: "hidden", md: "block" }} />
            </Grid>
            <Dialog open={passwordChangeSuccessful}
                onClose={handleCloseChangeResultModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Password Change Successful")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("Please sign in with the new password.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                        onClick={handleCloseChangeResultModal}>
                        {t("OK")}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default ChangePasswordPage;