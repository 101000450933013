import "./OrganizationHeader.css";
import {Avatar} from "@mui/material";
import React from "react";

function OrganizationHeader(props) {
    const { name, tagLine, logoImageUrl, size } = props;

    var iconSizeStyle = {};
    var orgNameClass = 'orgMetadata organizationNameMedium';
    var orgTagLineClass = 'orgMetadata organizationTagLineMedium';
    if (size === 'small') {
        iconSizeStyle = { width: 24, height: 24 };
        orgNameClass = 'orgMetadata organizationNameSmall';
        orgTagLineClass = 'orgMetadata organizationTagLineSmall';
    } else if (size === 'large') {
        iconSizeStyle = { width: 56, height: 56 };
        orgNameClass = 'orgMetadata organizationNameLarge';
        orgTagLineClass = 'orgMetadata organizationTagLineLarge';
    }

    return (
        <div style={{paddingTop: '15px'}}>
            <div className="imgLogo">
                <Avatar alt={name} src={logoImageUrl} sx={iconSizeStyle}/>
            </div>

            <div className="orgInfo">
                <div className={orgNameClass}>{name}</div>
                <div className={orgTagLineClass}>{tagLine}</div>
            </div>
        </div>
    );
}

export default OrganizationHeader;