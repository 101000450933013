import { Navigate } from "react-router-dom";
import { useAuthContext } from "./security";

// Please see https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
function ProtectedRoute({ children }) {
    const {currentUser} = useAuthContext();
    
    return currentUser ? children : <Navigate to="/public"/>
}

export default ProtectedRoute;