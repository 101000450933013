import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {extractDate, newQuestionnaireDateFormat, setSelectedView} from "../../../utility";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {IGNORE_LINKED_HEALTH_DATA_REQUEST, LINK_QUESTIONNIARE_HEALTH_DATA_REQUEST} from "../../../../gqlMutators";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from "@mui/material/CircularProgress";
import {GET_QUESTIONNAIRE_RESPONSE_SUMMARIES} from "../../../../gqlQueries";
import Loader from "../../../Loader";
import { useAuthContext } from "navigator/security";

function PinLinkToQuestionnaireListContent(props) {
    const {pendingLinkQuestionnaireList} = props;
    const { t } = useTranslation();
    const {currentUser} = useAuthContext();
    const [ignoreLinkHealthDataRequestGraphQL] = useMutation(IGNORE_LINKED_HEALTH_DATA_REQUEST);
    const [linkQuestionnaireHealthDataRequestGraphQL] = useMutation(LINK_QUESTIONNIARE_HEALTH_DATA_REQUEST);
    const [ignoreQuestionnaireOperationPending, setIgnoreQuestionnaireOperationPending] = React.useState(false);
    const [ignoreQuestionnaireLinkConfirmationDialog, setIgnoreQuestionnaireLinkConfirmationDialog] = React.useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState({});
    const [isReadyToLink, setIsReadyToLink] = React.useState(false);
    const [isPinFormatValid, setIsPinFormatValid] = React.useState(false);
    const [linkFailMessage, setLinkFailMessage] = React.useState("");
    const [isQuestionnaireLinked, setIsQuestionnaireLinked] = React.useState();
    const [pinCode, setPinCode] = React.useState("");
    const [showIsBusy, setShowIsBusy] = React.useState("");

    var dense = 'small';

    const {refetch} = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });

    const ignoreInvitationToLinkQuestionnaire = () => {
        if (selectedQuestionnaire !== undefined &&
            selectedQuestionnaire !== null &&
            selectedQuestionnaire.task.taskId !== null &&
            "LINK-PENDING" === selectedQuestionnaire.state)
        {
            setIgnoreQuestionnaireOperationPending(true);
            let taskId = selectedQuestionnaire.task.linkDataRequestId;

            setShowIsBusy(true);
            ignoreLinkHealthDataRequestGraphQL({
                variables: {
                    "TaskId": taskId
                }
            }).then(result => {
                setShowIsBusy(false);
                setSelectedView('QuestionnaireListView');
                refetch().then(() => setIgnoreQuestionnaireOperationPending(false));
            }).catch(error => {
                setShowIsBusy(false);
		setIgnoreQuestionnaireOperationPending(false);
                console.log(error); // TODO - need to get a error dialog up
            });
        }
        setIgnoreQuestionnaireLinkConfirmationDialog(false)
    }

    const linkHealthDataQuestionnaireByPin = (task, questionnaireResponseId, questionnaireResponseType, passcode) => {
            setShowIsBusy(true);
            linkQuestionnaireHealthDataRequestGraphQL({
                variables: {
                    "LinkDataRequestId" : task.linkDataRequestId,
                    "TaskType": task.taskType,
                    "TaskId": task.taskId,
                    "QuestionnaireResponseId": questionnaireResponseId,
                    "QuestionnaireResponseType": questionnaireResponseType,
                    "Passcode": passcode
                }
            }).then(result => {
                setShowIsBusy(false);

                if (result.data.linkQuestionnaireResponseHealthData.isSuccessful === true) {
                    setIgnoreQuestionnaireOperationPending(true);
                    refetch().then(() => setIgnoreQuestionnaireOperationPending(false));

                } else {
                    setIsReadyToLink(false);
                    setIsQuestionnaireLinked(false);
                    setIsPinFormatValid(false);
                    setLinkFailMessage(result.data.linkQuestionnaireResponseHealthData.failureMessage);
                }

            }).catch(error => {
                console.log(error); // TODO - need to get a error dialog up
                setShowIsBusy(false);
                setIsQuestionnaireLinked(false);
            });
    }

    const buildQuestionnaireDetails = (responseSummary) => {
        return(
            <Button onClick={() => setIsReadyToLink(true)} sx={{p: '0px', justifyContent: 'left'}}>
                <Typography variant="subtitle1" sx={{textAlign: 'left'}}>
                    {responseSummary.name}
                </Typography>
            </Button>
        );
    }

    const renderTargetDate = (responseSummary) => {
        if (responseSummary.targetDateTime == null) {
            return( <></>);
        }

        return(
            <Typography variant="subtitle2">{t("Complete by:")} {extractDate(responseSummary.targetDateTime)}</Typography>
        );
    }

    const pinDataHandler = (e) => {
        let regex = new RegExp('^[0-9][0-9][0-9][0-9]$');
        if (regex.test(e.target.value)) {
            setPinCode(e.target.value);
            setIsPinFormatValid(true);
        } else {
            setPinCode("");
            setIsPinFormatValid(false);
        }
    }

    const failureToLinkMessage = () => {
        if (isQuestionnaireLinked === false) {
            return <Typography variant="caption"
                               display="block"
                               gutterBottom
                               sx={{width: 70,
                                   color: "red",
                                   fontSize: '10px',
                                   fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                               }}>{t(linkFailMessage)}
                    </Typography>

        }

        return(<></>);
    }


    const linkUserInterface = (responseSummary) => {
        if (isReadyToLink) {
            if (showIsBusy) {
                return <CircularProgress color="inherit" />;
            }
            const iconButtonSides = 40;
            return(
                <Stack direction="row" alignItems="left">
                    <TextField id="pin-entry"
                               label="PIN"
                               size="small"
                               maxLength={4}
                               onChange={pinDataHandler}
                               sx={{width: 70,
                                   height: iconButtonSides,
                                   fontSize: '14px',
                                   fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                               }} />

                    <IconButton sx={{color:"white",
                                     backgroundColor: "green",
                                     width: iconButtonSides,
                                     height: iconButtonSides,
                                     borderRadius: 0,
                                     border: "1px solid",
                                     borderColor: "primary.main"}}
                                aria-label="button to link"
                                disabled={!isPinFormatValid || showIsBusy === true}
                                onClick={() => {
                                    console.log('pinCode : ' + pinCode);
                                    linkHealthDataQuestionnaireByPin(responseSummary.task,
                                                                     responseSummary.questionnaireResponseId,
                                                                     responseSummary.questionnaireResponseType,
                                                                     pinCode
                                    )
                                }}>
                        <CheckIcon />
                    </IconButton>

                    <IconButton sx={{color:"white",
                                     backgroundColor: "red",
                                     width: iconButtonSides,
                                     height: iconButtonSides,
                                     borderRadius: 0,
                                     border: "1px solid",
                                     borderColor: "primary.main"}}
                                aria-label="revert to default"
                                disabled={showIsBusy === true}
                                onClick={() => {
                                        setIsReadyToLink(false);
                                        setIsPinFormatValid(false);
                                        setIsQuestionnaireLinked();
                                    }
                                }>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            )
        }

        return(
            <Stack direction="column" alignItems="left">
                <Button variant="contained"
                        sx={{marginRight: '12px'}}
                        onClick={() => setIsReadyToLink(true)}>
                    { t("Link") }
                </Button>

                { failureToLinkMessage() }
            </Stack>
        );
    }

    if (ignoreQuestionnaireOperationPending === true) {
        return(
            <Loader active={true}/>
        );
    }

    return (
        <div className="componentBorder">
            <Box>
                <Typography variant="subtitle1"
                            sx={{fontWeight: 'bold'}}>
                    {t("Invitations to Receive")} ({pendingLinkQuestionnaireList.length})
                </Typography>

                {/* Styled for large screens */}
                <TableContainer component={Grid} 
                                display={{xs: 'none', md: 'block'}}>
                    <Table sx={{minWidth: 200}}
                           aria-labelledby="tableTitle"
                           size={dense ? 'small' : 'medium'}>
                        <colgroup>
                            <col width="40%" />
                            <col width="50%" />
                            <col width="5%" />
                            <col width="5%" />
                        </colgroup>
                        <TableBody>
                            {pendingLinkQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.lastModifiedDateTime}
                                              hover>
                                        <TableCell align="left" size="small">
                                            <Typography variant="subtitle1">
                                                {responseSummary.initiatingOrganizationName}
                                            </Typography>
                                        </TableCell>

                                        <TableCell align="left">
                                            <Stack direction="column" alignItems="left">
                                                { buildQuestionnaireDetails(responseSummary) }
                                                {newQuestionnaireDateFormat(responseSummary.createDateTime)}
                                                { renderTargetDate(responseSummary) }
                                            </Stack>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Button sx={{marginRight: '15px'}}
                                                    disabled={showIsBusy === true}
                                                    onClick={() => {
                                                         setSelectedQuestionnaire(responseSummary);
                                                         setIgnoreQuestionnaireLinkConfirmationDialog(true);
                                                    }}>
                                                {t("Ignore")}
                                            </Button>
                                        </TableCell>

                                        <TableCell align="center">
                                            { linkUserInterface(responseSummary) }
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Styled for screens from sm to md*/}
                <TableContainer component={Grid} 
                                display={{xs: 'none', sm: 'block', md: 'none'}}>
                    <Table sx={{minWidth: 200}}
                           aria-labelledby="tableTitle"
                           size={dense ? 'small' : 'medium'}>
                        <colgroup>
                            <col width="60%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <TableBody>
                            {pendingLinkQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.lastModifiedDateTime}
                                              hover>
                                        <TableCell align="left" size="small">
                                            <Typography variant="subtitle1">
                                                { buildQuestionnaireDetails(responseSummary) }
                                            </Typography>
                                            From {responseSummary.initiatingOrganizationName} <br/>
                                            {newQuestionnaireDateFormat(responseSummary.createDateTime)}
                                            { renderTargetDate(responseSummary) }
                                        </TableCell>

                                        <TableCell align="center">
                                            { linkUserInterface(responseSummary) }
                                        </TableCell>

                                        <TableCell align="center">
                                            <Button sx={{marginRight: '15px'}}
                                                    disabled={showIsBusy === true}
                                                    onClick={() => {
                                                         setSelectedQuestionnaire(responseSummary);
                                                         setIgnoreQuestionnaireLinkConfirmationDialog(true);
                                                    }}>
                                                {t("Ignore")}
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Styled for screens from xs(0) to sm */}
                <TableContainer component={Grid} 
                                display={{xs: 'block', sm: 'none'}}>
                    <Table sx={{minWidth: 200}}
                           aria-labelledby="tableTitle"
                           size={dense ? 'small' : 'medium'}>
                        <TableBody>
                            {pendingLinkQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.lastModifiedDateTime}
                                              hover>
                                        <TableCell align="left" size="small">
                                            <Typography variant="subtitle1">
                                                { buildQuestionnaireDetails(responseSummary) }
                                            </Typography>
                                            From {responseSummary.initiatingOrganizationName} <br/>
                                            {newQuestionnaireDateFormat(responseSummary.createDateTime)}
                                            { renderTargetDate(responseSummary) }
                                            <br/>
                                            <Stack direction={"row"}>
                                                { linkUserInterface(responseSummary) }
                                                <Button sx={{marginRight: '15px'}}
                                                        disabled={showIsBusy === true}
                                                        onClick={() => {
                                                            setSelectedQuestionnaire(responseSummary);
                                                            setIgnoreQuestionnaireLinkConfirmationDialog(true);
                                                        }}>
                                                    {t("Ignore")}
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>


            </Box>

            <Dialog open={ignoreQuestionnaireLinkConfirmationDialog}
                    onClose={() => setIgnoreQuestionnaireLinkConfirmationDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    {t("Ignore Invitation to Receive?")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("This invitation will be permanently removed.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                            onClick={() => setIgnoreQuestionnaireLinkConfirmationDialog(false)}>
                        {t("No")}
                    </Button>
                   <Button variant="contained"
                           onClick={() => ignoreInvitationToLinkQuestionnaire()}>
                      {t("Ignore")}
                   </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}


export default PinLinkToQuestionnaireListContent;