import React, {useEffect} from "react";
import html2pdf from 'html2pdf.js';
import {legacyExtractExtension, QuestionnaireHeader, FhirQuestionnaireReport} from "@cambianrepo/cambianreact";
import {Box, Button, Grid, Paper, Skeleton, Stack} from "@mui/material";
import {
    newQuestionnaireDateFormat,
    getSelectedQuestionnaireId,
    getSelectedQuestionnaireResponseId,
    getAuthFromSessionStorage
} from "../../../utility";

import PdfObjectViewer from "./PdfObjectViewer";


function ViewQuestionnaireResults(props) {
    console.log('ViewQuestionnaireResults')
    const {selectedViewCallback} = props;
    let questionnaireIdParameter;
    let questionnaireResponseIdParameter;
    if (getSelectedQuestionnaireId() !== null) {
        questionnaireIdParameter = getSelectedQuestionnaireId();
    }

    if (getSelectedQuestionnaireResponseId() !== null) {
        questionnaireResponseIdParameter = getSelectedQuestionnaireResponseId();
    }

    console.log('Questionnaire: ' + questionnaireIdParameter);
    console.log('Questionnaire Response: ' + questionnaireResponseIdParameter);

    const [questionnaireId, setQuestionnaireId] = React.useState();
    const [questionnaireResponseId, setQuestionnaireResponseId] = React.useState();
    if (questionnaireId === undefined && questionnaireIdParameter !== undefined ) {
        setQuestionnaireId(questionnaireIdParameter);
    }
    if (questionnaireResponseId === undefined && questionnaireResponseIdParameter !== undefined ) {
        setQuestionnaireResponseId(questionnaireResponseIdParameter);
    }
    const [fetchQuestionnairePending, setFetchQuestionnairePending] = React.useState(false);
    const [fetchedQuestionnaire, setFetchedQuestionnaire] = React.useState(false);
    const [questionnaireJSON, setQuestionnaireJSON] = React.useState({});
    const [fetchQuestionnaireResponsePending, setFetchQuestionnaireResponsePending] = React.useState(false);
    const [fetchedQuestionnaireResponse, setFetchedQuestionnaireResponse] = React.useState(false);
    const [questionnaireResponseJSON, setQuestionnaireResponseJSON] = React.useState({});
    const [pageError, setPageError] = React.useState(false);
    const [updateRequired, setUpdateRequired] = React.useState(true);

    const [hasPdfTemplate, setHasPdfTemplate] = React.useState(false);
    const [hasResultPage, setHasResultPage] = React.useState(false);
    const [openHtmlReportViewer, setOpenHtmlReportViewer] = React.useState(true);
    const [openPdfReportViewer, setOpenPdfReportViewer] = React.useState(false);

    const fetchQuestionnaireById = (id) => {
        setUpdateRequired(false);
        setFetchQuestionnairePending(true);

        const accessToken = "Bearer " + getAuthFromSessionStorage()?.accessToken;
        fetch(process.env.REACT_APP_GATEWAY_HOST + '/fhir/questionnaire/' + id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': accessToken
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                console.log(JSON.stringify(data));
                setQuestionnaireJSON(data);
                let templateName = legacyExtractExtension(data.extension, 'http://cambian.com/Questionnaire/pdftemplate-name');

                let hasPdfResults = false;
                if (templateName !== undefined && templateName !== null) {
                    hasPdfResults = true;
                }

                let hasHtmlResults = false;
                let resultPage = legacyExtractExtension(data.extension, 'http://cambian.com/Questionnaire/result-page');
                if (resultPage !== undefined && resultPage !== null) {
                    hasHtmlResults = true;
                }

                if (hasPdfResults && hasHtmlResults) {
                    setHasPdfTemplate(true);
                    setHasResultPage(true);
                    setOpenPdfReportViewer(true);
                    setOpenHtmlReportViewer(false);

                } else if (hasPdfResults) {
                    setHasPdfTemplate(true);
                    setHasResultPage(false);
                    setOpenPdfReportViewer(true);
                    setOpenHtmlReportViewer(false);

                } else {
                    setHasPdfTemplate(false);
                    setHasResultPage(true);
                    setOpenPdfReportViewer(false);
                    setOpenHtmlReportViewer(true);
                }

                setFetchedQuestionnaire(true);
                setFetchQuestionnairePending(false);
                setUpdateRequired(true);
            })
            .catch((error) => {
                setPageError(true);
                setUpdateRequired(true);
            });
    }

    const fetchQuestionnaireResponseById = (id) => {
        setUpdateRequired(false);
        setFetchQuestionnaireResponsePending(true);

        const accessToken = "Bearer " + getAuthFromSessionStorage()?.accessToken;
        fetch(process.env.REACT_APP_GATEWAY_HOST + '/fhir/fhirQuestionnaireResponse/' + id, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': accessToken
            },
            method: 'GET'
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setQuestionnaireResponseJSON(data);
                setFetchedQuestionnaireResponse(true)
                setFetchQuestionnaireResponsePending(false);
                setQuestionnaireId(data.questionnaire);
                setUpdateRequired(true);
            })
            .catch((error) => {
                setPageError(true);
                setUpdateRequired(true);
            });
    }

    const downloadReportCallback = () => {
        var element = document.getElementById('QuestionnaireReportComponent');
        var fileName = questionnaireJSON.name.split(' ').join('-');
        const options = {
            filename: fileName+'.pdf',
        };
        html2pdf().from(element).set(options).save();
    }
    useEffect(() => {
        if (updateRequired && !fetchQuestionnairePending && !fetchQuestionnaireResponsePending) {
            if (questionnaireId === undefined) {
                if (questionnaireResponseId !== undefined && !fetchedQuestionnaireResponse) {
                    fetchQuestionnaireResponseById(questionnaireResponseId);
                }

            } else if (!fetchedQuestionnaire) {
                fetchQuestionnaireById(questionnaireId);
            }

            setUpdateRequired(true);
        }
    });

    if (!fetchedQuestionnaire || !fetchedQuestionnaireResponse) {
        if (!pageError) {
            return (
                <Paper sx={{p: 2, overflowY: 'scroll', height: '100%'}}>
                    <Grid container direction="row"
                          sx={{
                              '& .MuiTextField-root': {m: 1, width: '40ch'}
                          }}>
                        <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>
                        <Box component={Grid} item xs={12} sm={10} md={8} display={{xs: "block"}}>
                           <Skeleton variant="rectangular" width={'100%'} height={600} sx={{m:1}} />
                        </Box>
                        <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>

                    </Grid>
                </Paper>
            );
        }
        return(<div>FAILURE</div>);
    }

    let defaultHeader = <QuestionnaireHeader questionnaireTitle={questionnaireJSON.title}
                                             completionDate={newQuestionnaireDateFormat(questionnaireJSON.date)} />;

    return (
             <Grid container direction="row"
                   sx={{
                       '& .MuiTextField-root': {m: 1, width: '40ch'}, p: 2, height: '100%', backgroundColor: 'rgb(245, 248, 250)'
                   }}>
                 <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>

                 <Box component={Grid} item xs={12} sm={10} md={8} display={{xs: "block"}} height={'82.5vh'}>
                    <Stack direction="column">
                       <Stack direction="row" justifyContent="right" sx={{ pt: 1, pr: 1 , mt: "auto", pb: 2}}>
                          {
                             hasPdfTemplate && hasResultPage
                                ? <>
                                   <Button variant={openHtmlReportViewer ? "outlined" : "text"}
                                           onClick={() => {
                                              setOpenPdfReportViewer(false);
                                              setOpenHtmlReportViewer(true)
                                           }}>
                                      HTML
                                   </Button>
                                   <Button variant={openPdfReportViewer ? "outlined" : "text"}
                                           onClick={() => {
                                              setOpenPdfReportViewer(true);
                                              setOpenHtmlReportViewer(false);
                                           }}>
                                      PDF
                                   </Button>

                                </>
                                : <></>
                          }
                       </Stack>
                       {
                          openPdfReportViewer
                             ? <PdfObjectViewer questionnaireResponseId={questionnaireResponseId}
                                                selectedViewCallback={selectedViewCallback}
                                                questionnaireTitle={questionnaireJSON.title}
                                                setOpenPdfReportViewer={setOpenPdfReportViewer}
                                                setOpenHtmlReportViewer={setOpenHtmlReportViewer}/>

                             : <FhirQuestionnaireReport fhirQuestionnaire={questionnaireJSON}
                                                        fhirResponse={questionnaireResponseJSON}
                                                        downloadReportCallback={downloadReportCallback}
                                                        defaultHeaderComponent={defaultHeader} />
                       }
                    </Stack>
                 </Box>

                 <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}>
                 </Box>
             </Grid>
    );
}

export default ViewQuestionnaireResults;