import {useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {extractDate, newQuestionnaireDateFormat, setSelectedOrganizationId, setSelectedQuestionnaireResponseId} from "../../../utility";
import {useMutation, useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import {HIDE_QUESTIONNAIRE_RESPONSE} from "../../../../gqlMutators";
import {GET_QUESTIONNAIRE_RESPONSE_SUMMARIES} from "../../../../gqlQueries";
import {CambianTheme} from "../../../CambianTheme";
import Loader from "../../../Loader";
import { useAuthContext } from "navigator/security";

function IncompleteSelfServeQuestionnaireListContent(props) {
    const {selectedViewCallback, incompleteSelfQuestionnaireList} = props;
    const { t } = useTranslation();
    const [openDeleteQuestionnaireDialog, setOpenDeleteQuestionnaireDialog] = useState(false);
    const [questionnaireResponseId, setQuestionnaireResponseId] = useState({});
    const [questionnaireOperationPending, setQuestionnaireOperationPending] = useState(false)
    const { currentUser } = useAuthContext();
    const colors = CambianTheme.palette.primary;

    var dense = 'small';

    const {refetch} = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });

    const continueAnsweringQuestionnaire = (organizationId, questionnareResponseId) => {
        setSelectedOrganizationId(organizationId);
        setSelectedQuestionnaireResponseId(questionnareResponseId);
        selectedViewCallback('ContinueQuestionnaireView');
    }

    const renderTargetDate = (responseSummary) => {
        if (responseSummary.targetDateTime == null) {
            return( <></>);
        }

        return(
            <Typography variant="subtitle2">{t("Complete by:")} {extractDate(responseSummary.targetDateTime)}</Typography>
        );
    }

    const [hideQuestionnaireResponse] = useMutation(HIDE_QUESTIONNAIRE_RESPONSE);
    const handleDelete = () => {
        setQuestionnaireOperationPending(true)
        hideQuestionnaireResponse({
            variables: {
                "QuestionnaireResponseId": questionnaireResponseId
            }
        })
            .then(async () => {
                setOpenDeleteQuestionnaireDialog(false);
                await refetch();
                setQuestionnaireOperationPending(false);
            })
            .catch((e) => {
                console.error('failed to hide questionnaire response',e);
                setOpenDeleteQuestionnaireDialog(false);
                setQuestionnaireOperationPending(false);
            })
    };

    if (questionnaireOperationPending) {
        return <Loader active={true}/>
    }

    return (
        <div className="componentBorder">
            <Box>
                <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("Self-serve")}</Typography>

                {/* Styled for large screens */}
                <TableContainer component={Grid} 
                                display={{xs: 'none', md: 'block'}}>
                    <Table
                        sx={{minWidth: 200}}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}>
                        <colgroup>
                            <col width="40%" />
                            <col width="50%" />
                            <col width="5%" />
                            <col width="5%" />
                        </colgroup>
                        <TableBody>
                            {incompleteSelfQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.questionnaireResponseId}
                                              hover>
                                        <TableCell align="left">
                                            <Button sx={{justifyContent: 'left', textAlign: 'left'}} 
                                                onClick={() => continueAnsweringQuestionnaire(responseSummary.initiatingOrganizationId,
                                                    responseSummary.questionnaireResponseId)} >
                                                    <Typography variant="subtitle1">
                                                        {responseSummary.name}
                                                    </Typography>
                                            </Button>
                                        </TableCell>
                                        
                                        <TableCell align="left">
                                            <Stack direction="column">
                                                <Typography variant="subtitle2">{newQuestionnaireDateFormat(responseSummary.lastModifiedDateTime)}</Typography>
                                                { renderTargetDate(responseSummary) }
                                            </Stack>
                                        </TableCell>

                                        <TableCell align="right" style={{width: '0%'}}>
                                            <Button
                                                    onClick={() => {
                                                        setQuestionnaireResponseId(responseSummary.questionnaireResponseId);
                                                        setOpenDeleteQuestionnaireDialog(true)
                                                    }}>
                                                {t("Remove")}
                                            </Button>
                                        </TableCell>

                                        <TableCell align="right" style={{width: '0%'}}>
                                            <Button variant="contained" sx={{backgroundColor: colors.continueOrange.main, '&:hover': {backgroundColor: colors.continueOrange.hover}}}
                                                    onClick={() => continueAnsweringQuestionnaire(responseSummary.initiatingOrganizationId,
                                                        responseSummary.questionnaireResponseId)}>
                                                {t("Continue")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Styled for screens from sm to md*/}
                <TableContainer component={Grid} 
                                display={{xs: 'none', sm: 'block', md: 'none'}}>
                    <Table
                        sx={{minWidth: 200}}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}>
                        <colgroup>
                            <col width="60%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <TableBody>
                            {incompleteSelfQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.questionnaireResponseId}
                                              hover>
                                        <TableCell align="left">
                                            <Button sx={{justifyContent: 'left', textAlign: 'left', padding: '0px'}} 
                                                onClick={() => continueAnsweringQuestionnaire(responseSummary.initiatingOrganizationId,
                                                    responseSummary.questionnaireResponseId)} >
                                                    <Typography variant="subtitle1">
                                                        {responseSummary.name}
                                                    </Typography>
                                            </Button>
                                            <Typography variant="subtitle3"> <br/> {newQuestionnaireDateFormat(responseSummary.lastModifiedDateTime)}</Typography>
                                                { renderTargetDate(responseSummary) }
                                        </TableCell>

                                        <TableCell align="center" style={{width: '0%'}}>
                                            <Button variant="contained" sx={{backgroundColor: colors.continueOrange.main, '&:hover': {backgroundColor: colors.continueOrange.hover}}}
                                                    onClick={() => continueAnsweringQuestionnaire(responseSummary.initiatingOrganizationId,
                                                        responseSummary.questionnaireResponseId)}>
                                                {t("Continue")}
                                            </Button>
                                        </TableCell>

                                        <TableCell align="center" style={{width: '0%'}}>
                                            <Button
                                                    onClick={() => {
                                                        setQuestionnaireResponseId(responseSummary.questionnaireResponseId);
                                                        setOpenDeleteQuestionnaireDialog(true)
                                                    }}>
                                                {t("Remove")}
                                            </Button>
                                        </TableCell>                                    
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Styled for screens from xs(0) to sm */}
                <TableContainer component={Grid} 
                                display={{xs: 'block', sm: 'none'}}>
                    <Table sx={{minWidth: 200}}
                           aria-labelledby="tableTitle"
                           size={dense ? 'small' : 'medium'}>
                        <TableBody>
                            {incompleteSelfQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.questionnaireResponseId}
                                              hover>
                                        <TableCell align="left">
                                            <Button sx={{justifyContent: 'left', textAlign: 'left', padding: '0px'}} 
                                                onClick={() => continueAnsweringQuestionnaire(responseSummary.initiatingOrganizationId,
                                                    responseSummary.questionnaireResponseId)} >
                                                    <Typography variant="subtitle1">
                                                        {responseSummary.name}
                                                    </Typography>
                                            </Button>
                                            <Typography variant="subtitle3"> <br/> {newQuestionnaireDateFormat(responseSummary.lastModifiedDateTime)}</Typography>
                                                { renderTargetDate(responseSummary) }
                                                <br/>
                                                <Stack direction={'row'}>
                                                    <Button variant="contained" sx={{ marginTop:1, backgroundColor: colors.continueOrange.main, '&:hover': {backgroundColor: colors.continueOrange.hover}}}
                                                        onClick={() => continueAnsweringQuestionnaire(responseSummary.initiatingOrganizationId,
                                                            responseSummary.questionnaireResponseId)}>
                                                        {t("Continue")}
                                                    </Button>
                                                    <Button sx={{marginTop: 1, marginLeft: 1}}
                                                            onClick={() => {
                                                                setQuestionnaireResponseId(responseSummary.questionnaireResponseId);
                                                                setOpenDeleteQuestionnaireDialog(true)
                                                            }}>
                                                        {t("Remove")}
                                                    </Button>
                                                </Stack>
                                        </TableCell>                                 
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Dialog open={openDeleteQuestionnaireDialog}
                    onClose={() => setOpenDeleteQuestionnaireDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Remove in-progress questionnaire?")}
                </DialogTitle>
                <DialogActions>
                    <Button variant="outlined"
                            onClick={() => setOpenDeleteQuestionnaireDialog(false)}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="contained"
                            onClick={() => handleDelete()}>
                        {t("Remove")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default IncompleteSelfServeQuestionnaireListContent;