import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

function Loader(props) {
    const {active, message} = props;
    return (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, display: "flex", flexDirection: "column" }}
                  open={active}>
            <CircularProgress color="inherit" />
            {message &&
            <div style={{margin:"20px"}}>{message}</div>}
        </Backdrop>
    );
}

export default Loader;