import {Button, Stack, Typography} from "@mui/material";
import React from "react";


const styleDef =  {
    display: 'block',
    backgroundColor: '#fcf8c3',
    color: '#c09853',
    width: '100%'
}

function PendingLinkDataRequest(props) {
    const {selectedViewCallback, summaries} = props;

    let count = 0;
    summaries.forEach((summary) => {
        if (summary.state === 'LINK-PENDING') {
            count++;
        }
    });

    if (count === 0) {
        return (<></>);
    }

    let subject = " pending invitation" + (count > 1 ? "s" : "");

    return (
        <Stack direction="column" alignItems="left" sx={{mt: '20px'}}>
            <Button underline="none"
                    sx={styleDef}
                    onClick={() => selectedViewCallback("QuestionnaireListView")}>
                <Typography variant="subtitle1">
                    You have <b>{count} {subject}</b> to <b>{"receive health data"}</b>
                </Typography>
            </Button>
        </Stack>
    );
}

export default PendingLinkDataRequest;