import {useTranslation} from "react-i18next";
import {useQuery} from "@apollo/client";
import {GET_ORGANIZATION_PUBLISHED_PLAN_SUMMARY,} from "../../../../gqlQueries";
import {errorMessageAction} from "../../../utility";
import {Accordion, AccordionDetails, AccordionSummary, Box, Skeleton, Typography, Stack, List} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlanDetails from "../plan/PlanDetails";


function AvailablePlans(props) {
   const {organizationId} = props;
   const { t } = useTranslation();

   console.log(organizationId)

   const {loading, error, data} = useQuery(GET_ORGANIZATION_PUBLISHED_PLAN_SUMMARY, {
      variables: {
         "OrganizationId": organizationId
      }
   });

   if (error) {
      return errorMessageAction(error.graphQLErrors);

   } else if (loading) {
      return (
          <Skeleton variant="rectangular" width={'100%'} height={300} />
      );

   } else if (data.getOrganizationPublishedPlanSummary.length === 0) {
      return (<></>);

   } else {
      return (
          <div id="planList" className="componentBorder">
             <h3 id="organizationPlans">{t("Plans")}</h3>
             <h4 id="planByLine">{t("Articles and questionnaires delivered to your Navigator account")}</h4>
             <Box sx={{width: '100%'}}>
                <List sx={{padding:0}}>
                   {data.getOrganizationPublishedPlanSummary.map(item => (
                       <PlanItem key={item.planGlobalId}
                                 planId={item.planGlobalId}
                                 title={item.planTitle}
                                 byline={item.planByline}
                                 description={item.planDescription}
                                 duration={item.planDuration}
                                 startDateStatus={item.startDateStatus}/>
                   ))}
                </List>
             </Box>
          </div>
      );
   }
}

function PlanItem(props) {
    const {planId, title, description, duration, startDateStatus} = props;
    return (
      <>
        <Accordion defaultExpanded={true}>
          <AccordionSummary id={`panel${planId}a-header`}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${planId}a-content`}
                            sx={{ borderBottom: 0 }}
          >
            <Stack spacing={2}>
              <Typography variant='subtitle1'>{title}</Typography>
              {/* { byline.trim().length > 0 && <Typography>{byline}</Typography> } */}
            </Stack>
          </AccordionSummary>

          <AccordionDetails>
            <PlanDetails planId={planId}
                           description={description}
                           duration={duration}
                           startDateStatus={startDateStatus}/>
          </AccordionDetails>
        </Accordion>
      </>
    );
}


export default AvailablePlans;