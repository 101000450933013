import {AppBar, Box, Divider, Grid, Link, Stack, Toolbar, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import { CAMBIAN_REACT_VERSION } from "../../version";
import React from "react";


function PublicFooter() {
    const { t } = useTranslation();
    const applicationName = process.env.REACT_APP_NAME;
    const applicationVersion = process.env.REACT_APP_VERSION;
    const privacyStatementDocument = process.env.REACT_APP_PRIVACYSTATEMENT_DOCUMENT_PDF;
    const [,,cambianReactPatchVersion] = CAMBIAN_REACT_VERSION.split(".");

    return (
        <AppBar position="fixed"
                color="default"
                component={Grid} 
                display={{xs: "none", sm: "block"}}
                sx={{
                       top: 'auto',
                       bottom: 0,
                       minHeight: '66px',
                }}>
            <Toolbar>
                <Grid container justifyContent="flex-start" sx={{flexGrow: 1, width: 1/4}}>
                    <Typography variant="caption">{t("Copyright © Cambian Business Services, Inc.")}</Typography>
                </Grid>
                <Box sx={{flexGrow: 1}}/>
                <Stack direction="row"
                       divider={<Divider orientation="vertical" flexItem/>}
                       spacing={2}>
                    <Link variant="caption" href="mailto:support@cambian.com">{t("Help")}</Link>
                    <Link variant="caption" href={privacyStatementDocument} target="_blank">{t("Privacy Statement")}</Link>
                </Stack>
                <Box sx={{flexGrow: 1}}/>
                <Grid container justifyContent="flex-end" sx={{flexGrow: 1, width: 1/4}}>
                    <Typography variant="caption">{applicationName} {applicationVersion}/{cambianReactPatchVersion}</Typography>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

export default PublicFooter;