import React from "react";
import {
    Box,
    Button,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Grid,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {newQuestionnaireDateFormat, setSelectedQuestionnaireResponseId} from "../../../utility";
import {useTranslation} from "react-i18next";
import DeleteAndShareQuestionnaireResponse from "./DeleteAndShareQuestionnaireResponse";


const useStyles = makeStyles(theme => ({
    cell_date: {
        minWidth: 150

    }
}));

const modelStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
//    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function FinalizedQuestionnaireListContent(props) {
    const {selectedViewCallback, finalizedQuestionnaireList} = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [showQuestionnairePDF, setShowQuestionnairePDF] = React.useState(false);

    const handleQuestionnaireResponseClick = (questionnaireResponseId) => {
        console.log('questionnaire Response ' + questionnaireResponseId);
        setSelectedQuestionnaireResponseId(questionnaireResponseId);
        selectedViewCallback('ViewQuestionnaireResultsView');
    }

    const isSelected = (questionnaireResponseId) => {
        return questionnaireResponseId === "";
    };

    const handleCloseQuestionnairePDF = () => setShowQuestionnairePDF(false);

    var dense = 'small';

    return (
        <div className="componentBorder">
            <Box>
                <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("Reports")}</Typography>
                <TableContainer component={Grid} display={{xs: 'none', sm: 'block'}}>
                    <Table
                        sx={{minWidth: 200}}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}>
                        {/*<EnhancedTableHead*/}
                        {/*    numSelected={selected.length}*/}
                        {/*    order={order}*/}
                        {/*    orderBy={orderBy}*/}
                        {/*    onSelectAllClick={handleSelectAllClick}*/}
                        {/*    onRequestSort={handleRequestSort}*/}
                        {/*    rowCount={rows.length}*/}
                        {/*/>*/}
                        <colgroup>
                            <col width="45%" />
                            <col width="40%"/>
                            <col width="15%" />
                        </colgroup>
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
             rows.slice().sort(getComparator(order, orderBy)) */}
                            {/*{stableSort(rows, getComparator(order, orderBy))*/}
                            {/*    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/}
                            {/*    .map((row, index) => {*/}
                            {finalizedQuestionnaireList
                                .map((responseSummary, index) => {
                                    const isItemSelected = isSelected(responseSummary.questionnaireResponseId);
                                    //const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow key={responseSummary.questionnaireResponseId}
                                                  hover
                                                  // onClick={(event) => {
                                                  //     handleClick(event,
                                                  //                 responseSummary.questionnaireResponseId,
                                                  //                 responseSummary.name,
                                                  //                 responseSummary.description,
                                                  //                 responseSummary.lastModifiedDateTime,
                                                  //                 responseSummary.initiatingOrganizationId,
                                                  //                 responseSummary.summaryScores)
                                                  //}}
                                                  role="checkbox"
                                                  aria-checked={isItemSelected}
                                                  tabIndex={-1}
                                                  selected={isItemSelected}>
                                            {/*<TableCell padding="checkbox">*/}
                                            {/*    <Checkbox*/}
                                            {/*        color="primary"*/}
                                            {/*        checked={isItemSelected}*/}
                                            {/*        inputProps={{*/}
                                            {/*            'aria-labelledby': labelId,*/}
                                            {/*        }}*/}
                                            {/*    />*/}
                                            {/*</TableCell>*/}
                                            {/*<TableCell*/}
                                            {/*    component="th"*/}
                                            {/*    id={labelId}*/}
                                            {/*    scope="row"*/}
                                            {/*    padding="none"*/}
                                            {/*>*/}
                                            {/*    {row.name}*/}
                                            {/*</TableCell>*/}
                                            <TableCell align="left">
                                                <Button sx={{justifyContent: 'left', padding: '0px'}} onClick={(event) => {
                                                    handleQuestionnaireResponseClick(responseSummary.questionnaireResponseId)
                                                }}>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                    {responseSummary.name}</Typography>
                                                </Button>
                                                <Modal
                                                    open={showQuestionnairePDF}
                                                    onClose={handleCloseQuestionnairePDF}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description">
                                                    <Box sx={modelStyle}>
                                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                                            {t("View Questionnaire Response")}
                                                            ID={responseSummary.questionnaireResponseId}
                                                        </Typography>
                                                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                                                            TBD - Need to display Questionnaire Response
                                                        </Typography>
                                                        <Button variant="contained"
                                                                onClick={handleCloseQuestionnairePDF}>
                                                           Close
                                                        </Button>
                                                    </Box>
                                                </Modal>

                                            </TableCell>


                                            <TableCell align="left" className={classes.cell_date}>
                                                {newQuestionnaireDateFormat(responseSummary.lastModifiedDateTime)}
                                            </TableCell>
                                            <TableCell align="left">
                                                <DeleteAndShareQuestionnaireResponse questionnaireResponseId={responseSummary.questionnaireResponseId}
                                                                                     questionnaireName={responseSummary.name}/>
                                            </TableCell>

                                            {/*<TableCell align="left">*/}
                                            {/*    <QuestionnaireResponse questionnaireResponseId={responseSummary.questionnaireResponseId}*/}
                                            {/*                           name={responseSummary.name}*/}
                                            {/*                           description={responseSummary.description}*/}
                                            {/*                           lastModifiedDateTime={responseSummary.lastModifiedDateTime}*/}
                                            {/*                           initiatingOrganizationId={responseSummary.initiatingOrganizationId}*/}
                                            {/*                           summaryScores={responseSummary.summaryScores}/>*/}
                                            {/*</TableCell>*/}
                                        </TableRow>
                                    );
                                })}
                            {/*{emptyRows > 0 && (*/}
                            {/*    <TableRow*/}
                            {/*        style={{*/}
                            {/*            height: (dense ? 33 : 53) * emptyRows,*/}
                            {/*        }}*/}
                            {/*    >*/}
                            {/*        <TableCell colSpan={6} />*/}
                            {/*    </TableRow>*/}
                            {/*)}*/}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* Styled for screens from xs(0) to sm */}
                <TableContainer component={Grid} 
                                display={{xs: 'block', sm: 'none'}}>
                                    <Table
                        sx={{minWidth: 200}}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}>
                        <TableBody>
                     {finalizedQuestionnaireList
                                .map((responseSummary, index) => {
                                    const isItemSelected = isSelected(responseSummary.questionnaireResponseId);

                                    return (
                                        <TableRow key={responseSummary.questionnaireResponseId}
                                                  hover
                                                  role="checkbox"
                                                  aria-checked={isItemSelected}
                                                  tabIndex={-1}
                                                  selected={isItemSelected}>
                                            <TableCell align="left">
                                                <Button sx={{justifyContent: 'left', padding: '0px'}} onClick={(event) => {
                                                    handleQuestionnaireResponseClick(responseSummary.questionnaireResponseId)
                                                }}>
                                                    <Typography sx={{textAlign: 'left'}}>
                                                    {responseSummary.name}</Typography>
                                                </Button>
                                                <Modal
                                                    open={showQuestionnairePDF}
                                                    onClose={handleCloseQuestionnairePDF}
                                                    aria-labelledby="modal-modal-title"
                                                    aria-describedby="modal-modal-description">
                                                    <Box sx={modelStyle}>
                                                        <Typography id="modal-modal-title" variant="h6" component="h2">
                                                            {t("View Questionnaire Response")}
                                                            ID={responseSummary.questionnaireResponseId}
                                                        </Typography>
                                                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                                                            TBD - Need to display Questionnaire Response
                                                        </Typography>
                                                        <Button variant="contained"
                                                                onClick={handleCloseQuestionnairePDF}>
                                                           Close
                                                        </Button>
                                                    </Box>
                                                </Modal>
                                                <br/>
                                                {newQuestionnaireDateFormat(responseSummary.lastModifiedDateTime)}
                                                <br/>
                                                <DeleteAndShareQuestionnaireResponse questionnaireResponseId={responseSummary.questionnaireResponseId}
                                                                                     questionnaireName={responseSummary.name}/>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*<TablePagination*/}
                {/*    rowsPerPageOptions={[5, 10, 25]}*/}
                {/*    component="div"*/}
                {/*    count={rows.length}*/}
                {/*    rowsPerPage={rowsPerPage}*/}
                {/*    page={page}*/}
                {/*    onPageChange={handleChangePage}*/}
                {/*    onRowsPerPageChange={handleChangeRowsPerPage}*/}
                {/*/>*/}
                {/*</Paper>*/}
                {/*<FormControlLabel*/}
                {/*    control={<Switch checked={dense} onChange={handleChangeDense} />}*/}
                {/*    label="Dense padding"*/}
                {/*/>*/}
            </Box>
        </div>
    );
}




export default FinalizedQuestionnaireListContent;