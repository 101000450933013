import React from "react";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {HIDE_QUESTIONNAIRE_RESPONSE} from "../../../../gqlMutators";
import {GET_QUESTIONNAIRE_RESPONSE_SUMMARIES} from "../../../../gqlQueries";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Typography
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ShareQuestionnaireResponse from "./ShareQuestionnaireResponse";
import Loader from "../../../Loader";
import { useAuthContext } from "navigator/security";

function DeleteAndShareQuestionnaireResponse(props) {
    const {questionnaireResponseId, questionnaireName} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDeleteQuestionnaireDialog, setOpenDeleteQuestionnaireDialog] = React.useState(false);
    const [openShareQuestionnaireDialog, setOpenShareQuestionnaireDialog] = React.useState(false);
    const [ignoreQuestionnaireOperationPending, setIgnoreQuestionnaireOperationPending] = React.useState(false);

    const open = Boolean(anchorEl);
    const {t} = useTranslation();
    const { currentUser } = useAuthContext();

    const [hideQuestionnaireResponse] = useMutation(HIDE_QUESTIONNAIRE_RESPONSE);

    const {refetch} = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });

    const handleClick = (event) => {
        console.log(event.name);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
        console.log('handle close');
        setAnchorEl(null);
    };

    const handleDelete = (questionnaireResponseId) => {
        setIgnoreQuestionnaireOperationPending(true);
        hideQuestionnaireResponse({
            variables: {
                "QuestionnaireResponseId": questionnaireResponseId
            }
        })
            .then((results) => {
                refetch().then((data) => {
                    setAnchorEl(null);
                    setOpenDeleteQuestionnaireDialog(false);
                    setIgnoreQuestionnaireOperationPending(false);
                })
            })
            .catch(() => {
                console.log('failed to hide questionnaire response');
                setAnchorEl(null);
                setOpenDeleteQuestionnaireDialog(false);
		setIgnoreQuestionnaireOperationPending(false);
            })
    };


    if (ignoreQuestionnaireOperationPending === true) {
        return(
            <Loader active={true}/>
        );
    }

    return (
        <div>
            <IconButton aria-label="more"
                        id="long-button"
                        aria-controls="long-menu"
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleClick}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                      'aria-labelledby': 'basic-button',
                  }}>
                <MenuItem onClick={() => {
                                            setOpenShareQuestionnaireDialog(true);
                                            setAnchorEl(null);
                                        }}>
                    {t("Share")}
                </MenuItem>
                <MenuItem onClick={() => {
                                            setOpenDeleteQuestionnaireDialog(true);
                                            setAnchorEl(null);
                                        }}>
                    {t("Delete")}
                </MenuItem>
            </Menu>

            <Dialog open={openDeleteQuestionnaireDialog}
                    onClose={() => setOpenDeleteQuestionnaireDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Delete completed questionnaire?")}
                </DialogTitle>
                <DialogActions>
                    <Button variant="outlined" 
                            onClick={() => setOpenDeleteQuestionnaireDialog(false)}>
                        {t("Cancel")}
                    </Button>
                    <Button variant="contained"
                            onClick={() => handleDelete(questionnaireResponseId)}>
                        {t("Delete")}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShareQuestionnaireDialog}
                    onClose={() => setOpenShareQuestionnaireDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    <Typography variant="h4" sx={{textAlign: 'center'}}>
                    {t("Share Your Assessment Data")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <ShareQuestionnaireResponse questionnaireResponseId={questionnaireResponseId}
                                                questionnaireName={questionnaireName}/>
                </DialogContent>
                <DialogActions sx={{marginInline: '34px'}}>
                    <Button variant="contained"
                            onClick={() => setOpenShareQuestionnaireDialog(false)}>
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default DeleteAndShareQuestionnaireResponse;