import { useMemo } from "react";
import { getAuthFromSessionStorage, clearCurrentUserInSessionStorage, clearAuthInSessionStorage, setAuthInSessionStorage, setCurrentUserInSessionStorage } from "../utility";
import {clearStateVariables} from "../utility";
import { CURRENT_CAMBIAN_USER, GET_AUTH_TOKEN } from "gqlQueries";
import { useLazyQuery } from "@apollo/client";
import { useAuthContext } from "./AuthContext";
import { REGISTER_NEW_USER, RESET_PASSWORD_REQUEST, CHECK_EMAIL_OTP_VALIDATION } from "gqlMutators";
import { useMutation } from "@apollo/client";

const LOGOUT_ENDPOINT = process.env.REACT_APP_AUTHSERVER_LOGOUT_URL;

const INTERNAL_SERVER_ERROR_MSG = "An unexpected error occured. Please contact technical support.";
//These messages are defined in Spring api-gateway. These are the error messages that we'll receive. 
//We need to find a better solution to differentiate errors.
//Maybe using a custom ErrorType might be a solution.
const INVALID_OTP_ERROR_MESSAGE = "Invalid OTP value";
const EXPIRED_OTP_ERROR_MESSAGE = "OTP has expired";
const USER_EXISTS_ERROR_MESSAGE = "This email is already in use for an existing account. Please sign-in or reset the password.";

const useAuthService = (gqlClient=null) => {
    const [getCambianUserInfo] = useLazyQuery(CURRENT_CAMBIAN_USER, {...(gqlClient && { client: gqlClient})});
    const [getAuthToken] = useLazyQuery(GET_AUTH_TOKEN, {fetchPolicy: 'network-only', ...(gqlClient && { client: gqlClient})});
    const [checkEmailOtpVerification] = useMutation(CHECK_EMAIL_OTP_VALIDATION, {...(gqlClient && { client: gqlClient})});
    const [createNewUser] = useMutation(REGISTER_NEW_USER, {...(gqlClient && { client: gqlClient})});
    const [resetPasswordRequest] = useMutation(RESET_PASSWORD_REQUEST, {...(gqlClient && { client: gqlClient})});

    const {setCurrentUser} = useAuthContext();

    const signIn = async (
      username,
      password
    ) => {
      //Get access token
      const {data: authData, error: authError} = await getAuthToken({variables: {Username: username, Password: password}});

      if(authError) {
        console.error(authError);
        if(authError.graphQLErrors?.[0]?.extensions?.HttpStatus === 500 || authError.networkError?.message) {
          return {success:false, errorMsg: INTERNAL_SERVER_ERROR_MSG}
        } 
        // use default error message
        return {success:false}
      }

      if(authData){
        setAuthInSessionStorage(authData.getAuthToken)
      } 

      const {data: userData, error: getUserError} = await getCambianUserInfo();
      if(userData){
        setCurrentUserInSessionStorage(userData.currentCambianUser)
        setCurrentUser(userData.currentCambianUser)
        return { success: true }
      }
      console.error(getUserError);
      return { success: false, errorMsg: INTERNAL_SERVER_ERROR_MSG}
    };
  
  
    const logout = async (shouldEndSession = false) => {
      console.log('+++ logout ' + shouldEndSession);
    
      clearStateVariables();
      const accessToken = getAuthFromSessionStorage().accessToken
    
      fetch(LOGOUT_ENDPOINT, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': `Bearer ${accessToken}`
        },
        method: 'POST'
      }).then((response) => {
        console.log('SUCCESSFUL LOGOUT');
      })
        .catch(() => console.log('FAILED LOGOUT'));
    
      console.log("DONE integration api gateway logout")
    
      clearCurrentUserInSessionStorage();
      clearAuthInSessionStorage();
      clearStateVariables();
      setCurrentUser(null)
    }

    const checkEmail = async (username) => {
      try {
        await checkEmailOtpVerification({
          variables: {
            EmailAddress: username,
          },
        });
        return { success: true };
      } catch (error) {
        console.error(error);
        const message = error.graphQLErrors?.[0]?.message
        if(message === USER_EXISTS_ERROR_MESSAGE){
          return {success:false, errorMsg: USER_EXISTS_ERROR_MESSAGE}
        }
        return { success: false, errorMsg: INTERNAL_SERVER_ERROR_MSG };
      }
    }

    const callResetPasswordRequest = async (username) => {
      try {
        await resetPasswordRequest({
          variables: {
            EmailAddress: username,
          },
        });
        return { success: true };
      } catch (error) {
        console.error(error);
        return  { success: false }
      }
    }
  
    const callCreateUser = async({username, password, firstName, lastName, verificationCode, isVerificationDisabled}) => {
      try {
        await createNewUser({
          variables: {
            EmailAddress: username,
            Password: password,
            FirstName: firstName,
            LastName: lastName,
            OneTimePassword: verificationCode,
            IsVerificationDisabled: isVerificationDisabled,
          },
        });
        signIn(username,password);
        return { success: true, isVerificationDisabled };
      } catch (error) {
        console.error(error);
        const message = error.graphQLErrors?.[0]?.message
        if(message === INVALID_OTP_ERROR_MESSAGE) {
          // INVALID_OTP_MESSAGE is the default error message
          return {success:false}
        }
        if(message === EXPIRED_OTP_ERROR_MESSAGE) {
          return {success:false, errorMsg: "Your code has expired. Please try again."}
        }
        if(message === USER_EXISTS_ERROR_MESSAGE){
          return {success:false, errorMsg: USER_EXISTS_ERROR_MESSAGE}
        }
        return { success: false, errorMsg: INTERNAL_SERVER_ERROR_MSG };
      }
    }

    const value = useMemo(
        () => ({
          signIn,
          logout,
          checkEmail,
          callResetPasswordRequest,
          callCreateUser
        }),
        []
    );

    return value;
}

export default useAuthService;