import {useMutation, useQuery} from "@apollo/client";
import {Box, Button, Skeleton, Link, Stack, Typography} from "@mui/material";
import React, {useState} from "react";
import OrganizationHeader from "./OrganizationHeader";
import LocationOnIcon from '@mui/icons-material/LocationOnSharp';
import FaxIcon from '@mui/icons-material/FaxSharp';
import PhoneIcon from '@mui/icons-material/PhoneSharp';
import EmailIcon from '@mui/icons-material/EmailSharp';
import {useTranslation} from "react-i18next";
import {
    errorMessageAction,
    getSelectedOrganizationId,
    setSelectedOrganizationId,
    setSelectedQuestionnaireResponseId
} from "../../../utility";
import {
    GET_ARTICLE_SUMMARY_BY_ORGANIZATION,
    GET_ORGANIZATION_DETAILS,
    GET_QUESTIONNAIRE_SUMMARIES
} from "../../../../gqlQueries";
import Parser from 'html-react-parser';
import {START_ASSIGNED_QUESTIONNAIRE_REQUEST} from "../../../../gqlMutators";
import AvailablePlans from "./AvailablePlans";

function OrganizationView(props) {
    const {
        selectedViewCallback,
        orgView,
        selectedArticleCallback
    } = props;
    const { t } = useTranslation();
    const [organizationView, setOrganizationView] = useState(orgView);
    const organizationId = getSelectedOrganizationId();
    console.log(organizationView);
    console.log(organizationId);
    const {loading, error, data} = useQuery(GET_ORGANIZATION_DETAILS, {
        variables: {
            "OrganizationId": organizationId
        }
    });

    const handleViewChange = (view) => {
        selectedViewCallback(view);
        setOrganizationView(view);
    };

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <div>
                <Skeleton variant="text"  width={'100%'} height={200}/>
                <Skeleton variant="rectangular" width={'100%'} height={500} />
            </div>
           );

    } else {
        return (
            <div>
                <div className="componentBorder">
                    <OrganizationHeader name={data.getOrganizationDetail.name}
                                        tagLine={data.getOrganizationDetail.tagLine}
                                        logoImageUrl={data.getOrganizationDetail.logoImageUrl}
                                        size="large"/>
                    <Box sx={
                        {
                            paddingTop: '10px',
                            typography: 'body1',
                        }
                    }>
                        <Button onClick={() => handleViewChange('OrganizationAboutView')}>{t("About")}</Button>
                        <Button sx={{marginLeft: {xs:0.5, sm:2}}} onClick={() => handleViewChange('OrganizationResourceView')}>{t("Resources")}</Button>
                    </Box>
                </div>

                <OrganizationContent orgName={data.getOrganizationDetail.name}
                                     orgView={organizationView}
                                     organizationId={data.getOrganizationDetail.organizationId}
                                     description={data.getOrganizationDetail.description}
                                     address={data.getOrganizationDetail.address}
                                     contactPhone={data.getOrganizationDetail.contactPhone}
                                     contactFax={data.getOrganizationDetail.contactFax}
                                     contactEmail={data.getOrganizationDetail.contactEmail}
                                     useOnlineConsent={data.getOrganizationDetail.useOnlineConsent}
                                     selectedViewCallback={selectedViewCallback}
                                     selectedArticleCallback={selectedArticleCallback} />
            </div>
        );
    }
}

function OrganizationContent(props) {
    const {
        orgName,
        orgView,
        organizationId,
        description,
        address,
        contactPhone,
        contactFax,
        contactEmail,
        useOnlineConsent,
        selectedViewCallback,
        selectedArticleCallback
    } = props;

    if (orgView === 'OrganizationAboutView') {
        return (
            <div className="componentBorder">
                <AboutOrganizationView organizationId={organizationId}
                                       orgName={orgName}
                                       description={description}
                                       address={address}
                                       contactPhone={contactPhone}
                                       contactFax={contactFax}
                                       contactEmail={contactEmail}
                                       useOnlineConsent={useOnlineConsent}/>
            </div>
        );
    }

    return (
            <ResourcesOrganizationView organizationId={organizationId}
                                       selectedViewCallback={selectedViewCallback}
                                       selectedArticleCallback={selectedArticleCallback}/>
    );
}

function ContactItem(props) {
    const {icon, text} = props;
    if (text == null) {
        return (
            <div/>
        );
    }
    return (
        <Stack direction="row" sx={{mb: '5px'}}>
            {icon}
            <Typography sx={{pl: '15px'}}>{text}</Typography>
        </Stack>
    );
}

function ContactEmail(props) {
    const {icon, text} = props;
    if (text == null) {
        return (
            <div/>
        );
    }
    return (
        <Stack direction="row" sx={{mb: '5px'}}>
            {icon}
            <Link href={"mailto:" + text} sx={{pl: '15px'}}>{text}</Link>
        </Stack>
    );
}

function ContactUs(props) {
    const {address, contactPhone, contactFax, contactEmail} = props;
    const { t } = useTranslation();

    if (address == null && contactPhone == null && contactFax == null && contactEmail == null) {
        return (
            <div/>
        );
    }

    return (
        <div>
            <h3>{t("Contact Us")}</h3>
            <ContactItem text={address}
                         icon={<LocationOnIcon />} />

            <ContactItem text={contactPhone}
                         icon={<PhoneIcon />} />

            <ContactItem text={contactFax}
                         icon={<FaxIcon />} />

            <ContactEmail text={contactEmail}
                         icon={<EmailIcon />} />
        </div>
    );
}


function Consent(props) {
    const { organizationId, useOnlineConsent, name } = props;
    const { t } = useTranslation();

    if (useOnlineConsent !== true) {
        return(<div />);
    }

    let consentAgreementUrl = process.env.REACT_APP_GATEWAY_HOST + "/consent/consentdocuments/" + organizationId;

    return(
        <div>
            <h3>{t('Consent')}</h3>
            <Typography>{t('View the') + ' ' + name + ' ' }
                <Link href={consentAgreementUrl}
                      rel="noopener noreferrer"
                      target="_blank">Consent Agreement</Link>
            </Typography>
        </div>
    );
}


function AboutOrganizationView(props) {
    const {organizationId, orgName, description, address, contactPhone, contactFax, contactEmail, useOnlineConsent} = props;
    const { t } = useTranslation();


    return (
        <div>
            <h3>{t("About Us")}</h3>
            <div dangerouslySetInnerHTML={{__html: description}}/>

            <ContactUs address={address}
                       contactPhone={contactPhone}
                       contactFax={contactFax}
                       contactEmail={contactEmail}/>

            <Consent organizationId={organizationId}
                     name={orgName}
                     useOnlineConsent={useOnlineConsent}/>
        </div>
    );
}

function ResourcesOrganizationView(props) {

    const {organizationId, selectedViewCallback, selectedArticleCallback} = props;
    return (
        <>
            <AvailablePlans organizationId={organizationId}/>

            <ArticleList organizationId={organizationId}
                         selectedViewCallback={selectedViewCallback}
                         selectedArticleCallback={selectedArticleCallback}/>

            <QuestionnaireSummaryList organizationId={organizationId}
                                      selectedViewCallback={selectedViewCallback}/>
        </>
    );
}


function ArticleList(props) {
    const {organizationId, selectedViewCallback, selectedArticleCallback} = props;
    const { t } = useTranslation();
    const {loading, error, data} = useQuery(GET_ARTICLE_SUMMARY_BY_ORGANIZATION, {
        variables: {
            "OrganizationId": organizationId
        }
    });

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Skeleton variant="rectangular" width={'100%'} height={300} />
        );

    } else if (data.getArticleSummariesByOrganizationIds.length === 0) {
        return (<></>);

    } else {
        return (
            <div id="articleList" className="componentBorder">
                <h3>{t("Articles")}</h3>
                <h4 id="articleByLine">{t("Curated content available for you to view")}</h4>
                <Box sx={{width: '100%'}}>
                    <ul>
                        {data.getArticleSummariesByOrganizationIds.map(item => (
                            <ArticleItem key={item.articleReference.globalId}
                                         articleId={item.articleReference.globalId}
                                         title={item.title}
                                         summary={item.summary}
                                         selectedViewCallback={selectedViewCallback}
                                         selectedArticleCallback={selectedArticleCallback}/>
                        ))}
                    </ul>
                </Box>
            </div>
        );
    }
}


function ArticleItem(props) {
    const {articleId, title, summary, selectedViewCallback, selectedArticleCallback} = props;
    const noPointer = {cursor: 'pointer'};

    const articleItem = {
        width: '100%'
    };

    const viewArticle = (articleId) => {
        selectedArticleCallback(articleId);
        selectedViewCallback('ArticleView');
    }

    return (
        <li style={articleItem}>
            <div style={articleItem}>
                <Stack direction="column">
                    <Link onClick={() => viewArticle(articleId)} style={noPointer}>
                        <Typography gutterBottom component="div">{title}</Typography>
                    </Link>
                    <Typography variant="subtitle2" gutterBottom component="div">{Parser(summary !== null ? summary : "")}</Typography>
                </Stack>
            </div>
        </li>
    );
}


function QuestionnaireSummaryList(props) {
    const {organizationId, selectedViewCallback} = props;
    const { t } = useTranslation();

    const {loading, error, data} = useQuery(GET_QUESTIONNAIRE_SUMMARIES, {
        variables: {
            "OrganizationId": organizationId
        }
    });

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Skeleton variant="rectangular" width={'100%'} height={300} />
        );

    } else if (data.getQuestionnaireSummaries.length === 0) {
        return (<></>);

    } else {
        return (
            <div id="questionnaireSummaryList" className="componentBorder">
                <h3>{t("Questionnaires")}</h3>
                <h4 id="questionnairesByLine">{t("Selected questionnaires available for you to complete")}</h4>
                <Box sx={{width: '100%'}}>
                    <ul>
                        {data.getQuestionnaireSummaries.map(item => (
                            <QuestionnaireSummaryItem key={item.questionnaireReference.idValue}
                                                      organizationId={organizationId}
                                                      questionnaireId={item.questionnaireReference.idValue}
                                                      title={item.name}
                                                      description={item.description}
                                                      selectedViewCallback={selectedViewCallback}/>
                        ))}
                    </ul>
                </Box>
            </div>
        );
    }
}


function QuestionnaireSummaryItem(props) {
    const {organizationId, questionnaireId, title, description, selectedViewCallback} = props;

    const [startQuestionnaireRequestGraphQL] = useMutation(START_ASSIGNED_QUESTIONNAIRE_REQUEST);
    const noPointer = {cursor: 'pointer'};
    const articleItem = {
        width: '100%'
    };

    const answerQuestionnaire = () => {
        startQuestionnaireRequestGraphQL({
            variables: {
                "OrganizationId":  organizationId,
                "QuestionnaireId": questionnaireId
            }
        }).then(result => {
            console.log(result);
            setSelectedOrganizationId(organizationId)
            let questionnaireResponseId = result.data.startQuestionnaire;
            setSelectedQuestionnaireResponseId(questionnaireResponseId);
            selectedViewCallback("ContinueQuestionnaireView");
        }).catch(error => {
            console.log(error); // TODO - need to get a error dialog up
        });
    }

    return (
        <li style={articleItem}>
            <Stack direction="column" sx={{mb: '5px'}}>
                <Link onClick={() => answerQuestionnaire()}  style={noPointer}>
                    <Typography gutterBottom component="div">{title}</Typography>
                </Link>
                <Typography variant="subtitle2" gutterBottom component="div">{Parser(description)}</Typography>
            </Stack>
        </li>
    );
}

export default OrganizationView;