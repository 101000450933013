import { Stack, TextField, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import { JURISDICTIONS } from "../../utility";

function IdentificationPanel(props) {

    const { t } = useTranslation();
    const { userDetail: { healthcareIdType, healthcareIdValue }, updateProfileDataCallback } = props;

    const handleTextFieldChange = (event, key) => {
        updateProfileDataCallback("property", "change", key, event.target.value);
    }

    return (
        <Stack direction="column" alignItems="left" spacing={2} sx={{
            '& .MuiInputLabel-root': {
                color: 'grey'
            },
        }}>
            <TextField select
                id="jurisdiction"
                label={t("Public Health Jurisdiction")}
                sx={{ maxWidth: '350px' }}
                value={healthcareIdType ?? ""}
                onChange={(event) => handleTextFieldChange(event, "healthcareIdType")}>
                {JURISDICTIONS.map((jurisdiction, idx) => (
                    <MenuItem key={idx} value={jurisdiction}>{jurisdiction === "" ? "Select Public Health Jurisdiction..." : jurisdiction}</MenuItem>
                ))}
            </TextField>

            <TextField id="hin"
                label={t("Personal Health Number")}
                defaultValue={healthcareIdValue}
                sx={{ maxWidth: '350px' }}
                onChange={(event) => handleTextFieldChange(event, "healthcareIdValue")} />
        </Stack>
    );
}

export default IdentificationPanel;