import { Stack, TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

function AddressPanel(props) {
    const { address1, address2, city, province, country, postalCode } = props.userDetail;
    const { t } = useTranslation();

    const handleTextFieldChange = (event, key) => {
        props.updateProfileDataCallback("property", "change", key, event.target.value);
    }

    return (
        <Stack direction="column" alignItems="left" spacing={2} sx={{
            '& .MuiInputLabel-root': {
                color: 'grey'
            },
        }}>
            <TextField id="address-1"
                label={t("Address Line 1")}
                defaultValue={address1}
                onChange={(event) => handleTextFieldChange(event, "address1")} />

            <TextField id="address-2"
                label={t("Address line 2")}
                defaultValue={address2}
                onChange={(event) => handleTextFieldChange(event, "address2")} />

            <TextField id="address-city"
                label={t("City")}
                defaultValue={city}
                onChange={(event) => handleTextFieldChange(event, "city")} />

            <TextField id="address-province"
                label={t("Province")}
                defaultValue={province}
                onChange={(event) => handleTextFieldChange(event, "province")} />

            <TextField id="address-postalcode"
                label={t("Postal Code")}
                defaultValue={postalCode}
                onChange={(event) => handleTextFieldChange(event, "postalCode")} />

            <TextField id="address-country"
                label={t("Country")}
                defaultValue={country}
                onChange={(event) => handleTextFieldChange(event, "country")} />
        </Stack>
    );
}


export default AddressPanel;