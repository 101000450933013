import {gql} from "@apollo/client";

const NA_VALUE ="N/A";

export const REGISTER_NEW_USER = gql`
    mutation registerNewUser($FirstName: String!,
        $LastName: String!,
        $EmailAddress: String!,
        $Password: String!,
        $OneTimePassword: String,
        $IsVerificationDisabled: Boolean) {
        registerNewUser(firstName: $FirstName,
            lastName: $LastName,
            emailAddress: $EmailAddress,
            password: $Password,
            oneTimePassword: $OneTimePassword,
            isVerificationDisabled: $IsVerificationDisabled) {
            username,
            email,
            firstName,
            lastName
        }
    } 
`;

// TODO: This needs to be improved.
// All of the values are mandatory but (sadly) only the email is even looked at.  
// So as it turns out we don’t really care what the values are, so long as they are provided.  
export const CHECK_EMAIL_OTP_VALIDATION = gql`
    mutation checkEmailOtpVerification($EmailAddress: String!) {
        checkEmailOtpVerification(emailAddress: $EmailAddress, widgetName: null, serviceName: null, organizationId: "${NA_VALUE}", widgetId: "${NA_VALUE}", uniqueKey: "${NA_VALUE}")
    }
`;


export const CHANGE_PASSWORD = gql`
    mutation changePassword($CurrentPassword: String, $NewPassword: String) {
        changePassword(currentPassword: $CurrentPassword, newPassword: $NewPassword)
    }
`;

export const RESET_PASSWORD = gql`
    mutation resetPassword($RequestId: String!, $NewPassword: String!) {
        resetPassword(requestId: $RequestId, newPassword: $NewPassword)
    }
`;

export const CHANGE_USER_PROFILE = gql`
    mutation updateCurrentCambianUser($editCambianUser: EditCambianUser) {
        updateCurrentCambianUser(editCambianUser: $editCambianUser)  {
            username
            userReference {
                globalId
            }
            patientReference {
                idValue
            }
            email
            phone
            firstName
            lastName 
            photoImageUrl
            photoImage
            birthMonth
            birthDay
            birthYear
            gender
            healthcareIdType
            healthcareIdValue
            address1
            address2
            city
            province
            country
            postalCode
            subscribeToEmails
            userIdentity {
                type
                value
                primary
            }
        }
    }
`;



export const IGNORE_CONNECTION_REQUEST = gql`
    mutation ignoreConnectionRequest($TaskId: String!) {
        ignoreConnectionRequest(taskId: $TaskId)
    }
`;


export const ACCEPT_CONNECTION_REQUEST = gql`
    mutation acceptConnectionRequest($CambianUserId: String!, $TargetUserId: String!, $RequestMessageId: String!, $OrgId: String!) {
        acceptConnectionRequest(cambianUserId: $CambianUserId, targetUserId:$TargetUserId, requestMessageId: $RequestMessageId, orgId: $OrgId)
    }
`;

export const ORGANIZATION_DISCONNECT_REQUEST = gql`
    mutation organizationDisconnect($CambianUserId: String!, $TargetUserId: String!, $OrgId: String!) {
        organizationDisconnect(cambianUserId: $CambianUserId, targetUserId:$TargetUserId, orgId: $OrgId)
    }
`;


export const START_ASSIGNED_QUESTIONNAIRE_REQUEST = gql`
    mutation startQuestionnaire($OrganizationId: String!, $QuestionnaireId: String!, $TaskId: String) {
        startQuestionnaire(organizationId: $OrganizationId, questionnaireId: $QuestionnaireId, taskId: $TaskId)
    }
`;


export const START_QUESTIONNAIRE_SURVEY_REQUEST = gql`
    mutation startQuestionnaire($OrganizationId: String!, $QuestionnaireId: String!) {
        startQuestionnaire(organizationId: $OrganizationId, questionnaireId: $QuestionnaireId)
    }
`;


export const IGNORE_QUESTIONNAIRE_REQUEST = gql`
    mutation ignoreAssignedQuestionnaire($TaskId: String!) {
        ignoreAssignedQuestionnaire(taskId: $TaskId)
    }
`;


export const IGNORE_LINKED_HEALTH_DATA_REQUEST = gql`
    mutation ignoreLinkedHealthData($TaskId: String!) {
        ignoreLinkedHealthData(taskId: $TaskId)
    }
`;


export const LINK_QUESTIONNIARE_HEALTH_DATA_REQUEST = gql`
    mutation linkQuestionnaireResponseHealthData($LinkDataRequestId: String, $TaskType: String, $TaskId: String, $QuestionnaireResponseId: String, $QuestionnaireResponseType: String, $Passcode: String) {
        linkQuestionnaireResponseHealthData(linkDataRequestId: $LinkDataRequestId, taskType: $TaskType, taskId: $TaskId, questionnaireResponseId: $QuestionnaireResponseId, questionnaireResponseType: $QuestionnaireResponseType, passcode: $Passcode) {
            isSuccessful
            failureMessage
        }
    }
`;

export const ADD_BP_DATA_POINT = gql`
    mutation createBloodPressureDataPoint($bloodPressureDataPoint: EditBloodPressureDataPoint) {
        createBloodPressureDataPoint(bloodPressureDataPoint: $bloodPressureDataPoint)
    }
`;


export const HIDE_QUESTIONNAIRE_RESPONSE = gql`
    mutation hideQuestionnaireResponse($QuestionnaireResponseId: String!) {
        hideQuestionnaireResponse(questionnaireResponseId: $QuestionnaireResponseId)
    }
`;


export const SHARE_QUESTIONNAIRE_RESPONSE = gql`
    mutation shareQuestionnaireResponse($QuestionnaireResponseId: String!, $GrantAccessToOrgType: String!, $GrantAccessToOrgId: String!) {
        shareQuestionnaireResponse(questionnaireResponseId: $QuestionnaireResponseId, grantAccessToOrgType: $GrantAccessToOrgType, grantAccessToOrgId: $GrantAccessToOrgId)
    }
`;
export const EDIT_BP_DATA_POINT = gql`
    mutation updateBloodPressureDataPoint($bloodPressureDataPoint: EditBloodPressureDataPoint) {
        updateBloodPressureDataPoint(bloodPressureDataPoint: $bloodPressureDataPoint)
    }
`;

export const DELETE_BP_DATA_POINT = gql`
    mutation deleteBloodPressureDataPoint($id: String!) {
        deleteBloodPressureDataPoint(id: $id)
    }
`;

export const SET_BP_TARGET = gql`
    mutation setBloodPressureTarget($patientId: String!, $systolic: Int, $diastolic: Int) {
        setBloodPressureTarget(patientId: $patientId, systolic: $systolic, diastolic: $diastolic) {
            systolic
            diastolic
        }
    }
`;

export const RESET_PASSWORD_REQUEST = gql`
    mutation resetPasswordRequest($EmailAddress: String!) {
        resetPasswordRequest(emailAddress: $EmailAddress)
    }
`;

export const SUBSCRIBE_TO_PLAN_REQUEST = gql`
    mutation subscribeToPlan($PlanId: String!, $StartDate: LocalDateTime!) {
        subscribeToPlan(planId: $PlanId, startDate: $StartDate)
    }
`;

export const CANCEL_SUBSCRIPTION_REQUEST = gql`
    mutation cancelSubscription($SubscriptionGlobalId: String!) {
        cancelSubscription(subscriptionGlobalId: $SubscriptionGlobalId)
    }
`;

export const MODIFY_SUBSCRIPTION_REQUEST = gql`
    mutation modifySubscription($SubscriptionGlobalId: String!, $StartDate: LocalDateTime!) {
        modifySubscription(subscriptionGlobalId: $SubscriptionGlobalId, startDate: $StartDate)
    }
`;
