import React from "react";
import Box from "@mui/material/Box";
import {
    AppBar,
    ClickAwayListener,
    Divider,
    Grid,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Stack,
    Toolbar
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {CambianBranding} from "../CambianBranding";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {HoverIconButton} from "@cambianrepo/cambianreact";
import HomeIcon from "@mui/icons-material/Home";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import ErrorBoundary from "../../../ErrorBoundary";
import {IndividualUser} from "@cambianrepo/cambianreact";
import MenuIcon from "@mui/icons-material/Menu";
import {getSelectedView, setSelectedView} from "../../utility";
import {useApolloClient} from "@apollo/client";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAuthContext, useAuthService } from "navigator/security";
import { CAMBIAN_REACT_VERSION } from "../../../version";

const useStyles = makeStyles(theme => ({
    appbar: {
//        backgroundColor: 'white',
//        color: '#428bca'
          paddingLeft: '24px',
          paddingRight: '24px'
    },
    appbarBranding: {
        flex: 1,
        flexGrow: 1,
        paddingTop: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'right',
        justifyContent: 'left'
    },
    appbarMenu: {
        height: '60px',
        margin: 'auto',
        justifyContent: 'center'
    },
    appbarAccount: {
        height: '60px',
        margin: 'auto',
        alignItems: 'left',
        justifyContent: 'left'
    },
    appbarHamburger: {
        width: '100%',
        height: '60px',
        margin: 'auto',
        alignItems: 'left',
        justifyContent: 'left',
        paddingTop: '10px'
    },
    iconButtonLabel: {
        fontSize: "16px",
        display: "block"
    },
    menuGroup : {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        flex: 1
    },
    menuOption : {
        paddingLeft: "30px"
    },
    healthDataMenuItem: {
        marginLeft: '5px'
    },
    userProfileMenuItem: {
        marginLeft: '8px'
    }
}));


function PublicDesktopToolbar() {
    const classes = useStyles();

    return (
        <AppBar position="sticky"
                color="default"
                sx={{ display: { xs:'none', sm: 'block' } }}>
            <Toolbar className={classes.appbar}>
                <div className={classes.appbarBranding}>
                    <CambianBranding />
                </div>
            </Toolbar>
        </AppBar>
    );
}


function ProtectedToolbar(props) {
    const {selectedViewCallback} = props;
    const classes = useStyles();

    return (
        <AppBar position="sticky"
                color="default">
            <Toolbar className={classes.appbar}>
                <Grid container direction="row">
                    <Box component={Grid} item xs={4} sm={3} md={4} lg={4} xl={4} display={{xs: "block"}}>
                        <div className={classes.appbarBranding}>
                            <CambianBranding selectedViewCallback={selectedViewCallback}/>
                        </div>
                    </Box>

                    <Box component={Grid} item md={6} lg={6} xl={6} display={{xs: "none", md: "block"}}>
                        <DesktopMenuBar selectedViewCallback={selectedViewCallback}/>
                    </Box>

                    <Box component={Grid} item md={2} lg={2} xl={2} display={{xs: "none", md: "block"}} sx={{alignContent: 'end'}} justifyContent="end">
                        <DesktopAccountMenu justifyContent="end"/>
                    </Box>

                    <Box component={Grid} item xs={7} sm={8} md={3} lg={4} xl={5}
                         display={{xs: "block", md: "none"}}  />

                    <Box component={Grid} item xs={1} sm={1} md={3} lg={4} xl={5}
                         display={{xs: "block", md: "none"}}>
                        <MobileHamburgerMenu selectedViewCallback={selectedViewCallback}/>
                    </Box>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}



function DesktopMenuBar(props) {
    const {selectedViewCallback} = props;
    let navigate = useNavigate();
    const [anchorHealthDataEl, setAnchorHealthDataEl] = React.useState(null);
    const {t} = useTranslation();
    const classes = useStyles();

    // const handleMenuSelectionNavigation = (pageURL) => {
    //     navigate(pageURL);
    // };

    const handleMenuSelectionNavigation = (selectedView) => {
        selectedViewCallback(selectedView);
        navigate('/');
    };
    const handleHealthDataClickAway = () => {
        setAnchorHealthDataEl(null);
    }
    const handleCloseHealthDataMenu = () => {
        console.log('handleCloseHealthDataMenu');
    };
    const handleHealthDataMenuSelected = (pageURL) => {
        setAnchorHealthDataEl(null);
        navigate(pageURL);
    };
    const handleHealthDataMenuList = event => {
        setSelectedView("HealthData");
        setAnchorHealthDataEl(event.currentTarget);
    };
    return (
        <div className={classes.appbarMenu}>
            <HoverIconButton text={t('Home')}
                             icon={<HomeIcon/>}
                             isSelected={getSelectedView() === 'HomeView'}
                             menuSelectionCallback={() => handleMenuSelectionNavigation('HomeView')}/>

            <HoverIconButton text={t('Network')}
                             icon={<PeopleAltIcon/>}
                             isSelected={
                                            getSelectedView() === 'NetworkConnectionView' ||
                                            getSelectedView() === 'OrganizationAboutView' ||
                                            getSelectedView() === 'OrganizationResourceView'
                                    }
                             menuSelectionCallback={() => handleMenuSelectionNavigation('NetworkConnectionView')}/>

            <HoverIconButton text={t('Questionnaires')}
                             icon={<ListAltIcon/>}
                             isSelected={
                                 getSelectedView() === 'QuestionnaireListView' ||
                                 getSelectedView() === 'ViewQuestionnaireResultsView' ||
                                 getSelectedView() === 'ContinueQuestionnaireView'
                             }
                             menuSelectionCallback={() => handleMenuSelectionNavigation('QuestionnaireListView')}/>

            <ClickAwayListener onClickAway={handleHealthDataClickAway}>
               <HoverIconButton text={t('Health Data')}
                                icon={<MedicalInformationIcon/>}
                                isSelected={getSelectedView() === 'HealthData'}
                                menuSelectionCallback={handleHealthDataMenuList}/>
            </ClickAwayListener>

            <Menu className={classes.healthDataMenuItem} id="menu-healthData"
                 anchorEl={anchorHealthDataEl}
                 anchorOrigin={{
                     vertical: 'bottom',
                      horizontal: 'left',
                  }} 
                  keepMounted 
                  transformOrigin={{ 
                      vertical: 'top', 
                      horizontal: 'left', 
                  }} 
                  open={Boolean(anchorHealthDataEl)} 
                  onClose={() => handleCloseHealthDataMenu}>

                <MenuItem onClick={() => handleHealthDataMenuSelected(t("/blood-pressure"))}>
                    {t("Blood Pressure")}
                </MenuItem>
                {/* <MenuItem 
                    onClick={() => handleHealthDataMenuSelected(t("/cardiovascular"))}>{t("Cardiovascular")} 
                </MenuItem> 
                <MenuItem 
                    onClick={() => handleHealthDataMenuSelected(t("/medications"))}>{t("Medications")} 
                </MenuItem> 
                <MenuItem 
                    onClick={() => handleHealthDataMenuSelected(t("/symptoms"))}>{t("Symptoms")} 
                </MenuItem> 
                <MenuItem 
                    onClick={() => handleHealthDataMenuSelected(t("/allergies"))}>{t("Allergies")} 
                </MenuItem> 
                <MenuItem 
                    onClick={() => handleHealthDataMenuSelected(t("/answer-questionnaire"))}>{t("Answer Questionnaire (sample)")} 
                </MenuItem>  */}
            </Menu> 
        </div>
    );
}


function DesktopAccountMenu() {
    const classes = useStyles();
    const {t} = useTranslation();
    const { currentUser } = useAuthContext();
    const {logout} = useAuthService();
    let navigate = useNavigate();
    const gqlClient = useApolloClient();
    
    const [anchorAccountEl, setAnchorAccountEl] = React.useState(null);

    const handleAccountMenuList = event => {
        setAnchorAccountEl(event.currentTarget);
    };
    const handleCloseAccountMenu = () => {
        console.log('handleCloseAccountMenu');
    };
    const handleAccountMenuSelected = (pageURL) => {
        setAnchorAccountEl(null);
        setSelectedView("EditProfileOrPassword");
        navigate(pageURL, {replace: true});
    };
    const handleSignOut = () => {
        gqlClient.clearStore()
            .then(() => console.log('Cache Cleared'))
            .catch(() => console.log('Error when attempting to clear cache'));
        
        logout(true)
            .then(() => {
                console.log('logout succeeded');
                navigate('/public');
            })
            .catch(() => {
                console.log('logout failed');
                navigate('/public');
            });
    }

    return (
        <div className={classes.appbarAccount}>
            <ClickAwayListener onClickAway={() => setAnchorAccountEl(null)}>
                <HoverIconButton icon={
                    <div className={classes.iconButtonLabel}>
                        <ErrorBoundary>
                            <IndividualUser firstName={currentUser.firstName} 
                                            lastName={currentUser.lastName} 
                                            photoImageUrl={currentUser.photoImage}
                                            email={currentUser.email} />
                        </ErrorBoundary>
                    </div>
                }
                                 menuSelectionCallback={handleAccountMenuList}>
                </HoverIconButton>
            </ClickAwayListener>

            <Menu className={classes.userProfileMenuItem} id="menu-account"
                  anchorEl={anchorAccountEl}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                  }}
                  open={Boolean(anchorAccountEl)}
                  onClose={() => handleCloseAccountMenu}>
                <MenuItem
                    onClick={() => handleAccountMenuSelected(t("/edit-user-profile"))}>{t("User Profile")}</MenuItem>
                <MenuItem
                    onClick={() => handleAccountMenuSelected(t("/change-password"))}>{t("Change Password")}</MenuItem>
                <MenuItem onClick={() => handleSignOut()}>{t("Sign Out")}</MenuItem>
            </Menu>

        </div>
    );
}


function MobileHamburgerMenu(props) {
    const {selectedViewCallback} = props;
    const classes = useStyles();
    let navigate = useNavigate();
    const {t} = useTranslation();
    const gqlClient = useApolloClient();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {logout} = useAuthService();

    const handleHamburgerMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleHamburgerMenuItemClick = pageURL => {
        setAnchorEl(null);
        navigate(pageURL);
    };

    const handleMenuSelectionNavigation = (selectedView) => {
        selectedViewCallback(selectedView);
        navigate('/');
    };

    const privacyStatementDocument = process.env.REACT_APP_PRIVACYSTATEMENT_DOCUMENT_PDF;
    const userAgreementDocument = process.env.REACT_APP_USERAGREEMENT_DOCUMENT_PDF;
    const applicationName = process.env.REACT_APP_NAME;
    const applicationVersion = process.env.REACT_APP_VERSION;
    const [,,cambianReactPatchVersion] = CAMBIAN_REACT_VERSION.split(".");

    const handleSignOut = () => {
        logout(true)
            .then(() => {
                console.log('logout succeeded');
                navigate('/public');
            })
            .catch(() => {
                console.log('logout failed');
                navigate('/public');
            });

        gqlClient.clearStore()
            .then(() => console.log('Cache Cleared'))
            .catch(() => console.log('Error when attempting to clear cache'));
    }

    const menuItems = [
        {
            id: "homeAction",
            menuTitle: t("Home"),
            pageURL: "/",
            selectedView: "HomeView"
        },
        {
            id: "networkAction",
            menuTitle: t("Network"),
            pageURL: t("/"),
            selectedView: "NetworkConnectionView"
        },
        {
            id: "questionnairesAction",
            menuTitle: t("Questionnaires"),
            pageURL: t("/"),
            selectedView: "QuestionnaireListView"
        },
        {
            id: "healthDataAction",
            menuTitle: t("Health Data"),
            menuItems: [
                {
                    subMenuId: "bloodPressureAction",
                    subMenuTitle: t("Blood Pressure"),
                    subMenuPageURL: t("/blood-pressure")
                },
            ]
        },
        // {
        //     id: "bloodPressureAction",
        //     menuTitle: t("Blood Pressure"),
        //     pageURL: t("/blood-pressure")
        // },
        // {
        //     id: "cardiovascularAction",
        //     menuTitle: t("Cardiovascular"),
        //     pageURL: t("/cardiovascular")
        // },
        // {
        //     id: "medicationsAction",
        //     menuTitle: t("Medications"),
        //     pageURL: t("/medications")
        // },
        // {
        //     id: "symptomsAction",
        //     menuTitle: t("Symptoms"),
        //     pageURL: t("/symptoms")
        // },
        // {
        //     id: "allergiesAction",
        //     menuTitle: t("Allergies"),
        //     pageURL: t("/allergies")
        // },
        {
            id: "editUserProfileAction",
            menuTitle: t("User Profile"),
            pageURL: t("/edit-user-profile")
        },
        {
            id: "changePasswordAction",
            menuTitle: t("Change Password"),
            pageURL: t("/change-password")
        }
    ];

    return (
        <div className={classes.appbarHamburger}>
            <IconButton edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={handleHamburgerMenu}>
                <MenuIcon/>
            </IconButton>
            <Menu id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                  }}
                  open={open}
                  onClose={() => setAnchorEl(null)}>
                {menuItems.map(menuItem => {
                    const {id, menuTitle, pageURL, selectedView} = menuItem;
                    if (menuItem.menuItems) {
                        return (
                                <Accordion key={id} sx={{border: 'none',
                                    '.MuiAccordionSummary-root': {
                                        borderBottom: 'none',
                                    }
                                    }}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                    <Typography>{menuTitle}</Typography>
                                    </AccordionSummary>
                                    {menuItem.menuItems.map((subMenuItem) => {
                                        const {subMenuId, subMenuTitle, subMenuPageURL} = subMenuItem;
                                        return (
                                            <AccordionDetails key={subMenuId}>
                                                <MenuItem key={subMenuId} onClick={() => handleHamburgerMenuItemClick(subMenuPageURL)}>{subMenuTitle}</MenuItem>
                                            </AccordionDetails>
                                        );
                                    })}
                                </Accordion>
                        );
                    }
                    return (
                        <MenuItem key={id} onClick={() => {handleMenuSelectionNavigation(selectedView); handleHamburgerMenuItemClick(pageURL)}}>
                            {menuTitle}
                        </MenuItem>
                    );
                })}
                <MenuItem onClick={() => handleSignOut()}>{t("Sign Out")}</MenuItem>
                <MenuItem title={applicationName+" "+applicationVersion+"/"+cambianReactPatchVersion}>
                    <Stack direction="row"
                            divider={<Divider orientation="vertical" flexItem/>}
                            spacing={1}>
                        <Link variant="caption" href="mailto:support@cambian.com">{t("Help")}</Link>
                        <Link variant="caption" href={privacyStatementDocument} target="_blank">{t("Privacy")}</Link>
                        <Link variant="caption" href={userAgreementDocument} target="_blank">{t("User Agreement")}</Link>
                    </Stack>
                </MenuItem>
            </Menu>
        </div>
    );
}

export {PublicDesktopToolbar, ProtectedToolbar};