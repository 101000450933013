import {useQuery} from "@apollo/client";
import {GET_RANDOM_ADVERTISEMENT} from "../../../gqlQueries";
import {Skeleton, Paper} from "@mui/material";
import {useAuthContext} from "../../security";

import {errorMessageAction, setSelectedOrganizationId, setSelectedView} from "../../utility";
import {useNavigate} from "react-router-dom";
function OrganizationAd(props) {
    let navigate = useNavigate();
    const {organizationId, selectedViewCallback, selectedArticleCallback} = props;
    const {currentUser} = useAuthContext();

    const viewArticle = (articleId) => {
        selectedArticleCallback(articleId);
        selectedViewCallback('ArticleView');
    }

    const noPointer = {cursor: 'pointer'};

    const {loading, error, data} = useQuery(GET_RANDOM_ADVERTISEMENT, {
        variables: {
            "PatientId": currentUser.patientReference.idValue,
            "OrganizationId": organizationId,
        }
    });
    //
    // let x=true;
    // if (x) {
    //     return( <Skeleton variant="rectangular" width={'100%'} height={'100%'} />);
    // }

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Skeleton variant="rectangular" width={'100%'} height={100} />
        );

    } else if (data.getAd === null || data.getAd.imageAd === undefined || data.getAd.imageAd === null) {
        return(
            <></>
        );

    } else {
        const orgId = data.getAd.imageAd.split("/ad")[1].split("/")[1];
        // if (data.getAd.articleUrl === undefined || data.getAd.articleUrl === null) {
        //     return (
        //         <img src={data.getAd.imageAd} width="100%"/>
        //     );
        
        // } else {
            return (
                <Paper onClick={() => {viewArticle(data.getAd.articleId); setSelectedOrganizationId(orgId); setSelectedView("OrganizationAboutView"); navigate('/')}} style={noPointer} sx={{borderRadius: '4px'}}>
                    <img src={data.getAd.imageAd} width="100%" alt="imageAd" />
                </Paper>
            );
        // }
    }
}


export default OrganizationAd;