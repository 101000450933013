function getItem(key) {
    return window.sessionStorage.getItem(key)
}

function setItem(key, value) {
    window.sessionStorage.setItem(key, value);
}

function removeItem(key) {
    window.sessionStorage.removeItem(key)
}

export function convertDateToLocaleString(dateValue) {
    const d = new Date(dateValue);
    const formattedDate = d.toLocaleDateString("en", { // you can use undefined as first argument
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })
    const formattedTime = d.toLocaleTimeString("en", {
        hour: "numeric",
        minute: "2-digit",
    }).toLowerCase()

    const datePart = formattedDate.split('/');
    const reconstructedDate = datePart[2] + "-" + datePart[0] + "-" + datePart[1];

    const result = reconstructedDate + " " + formattedTime;

    return result;
}

export function newQuestionnaireDateFormat(dateValue) {
    const d = new Date(dateValue);
    const formattedDate = d.toLocaleDateString("en", { // you can use undefined as first argument
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    })
    const formattedTime = d.toLocaleTimeString("en", {
        hour: "numeric",
        minute: "2-digit",
    }).toLowerCase()

    const datePart = formattedDate.split('/');
    const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const reconstructedDate = month[datePart[0]-1] + " " + datePart[1] + ", " + datePart[2];

    const result = reconstructedDate + " at " + formattedTime;

    return result;
}


export function extractDate(dateValue) {
    const d = new Date(dateValue);
    const month = d.getMonth(); 
    const day = String(d.getDate());
    const year = d.getFullYear();
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const reconstructedDate = months[month] + " " + day + ", " + year;

    const result = reconstructedDate

    return result;
}

export function extractDateFromLocaleString(dateValue) {
    let dateString = "";
    if (dateValue !== undefined && dateValue !== null) {
        const localeString = convertDateToLocaleString(dateValue);
        const datePart = localeString.split(" " );
        dateString = datePart[0];
    }
    return dateString;
}



export function errorMessageAction(graphqlError) {
    // let component;
    // let statusCode;
    // let message;
    // let errorType;
    // let executionId;
    // let path;
    // console.log(graphqlError);
    // graphqlError.forEach(err => {
    //     console.log(err);
    //     if (err.extensions !== undefined && err.extensions.HttpStatus !== undefined) {
    //         statusCode = err.extensions.HttpStatus;
    //         message = err.message;
    //         errorType = err.extensions.errorType;
    //         if (err.path !== null) {
    //             path = JSON.stringify(err.path);
    //         }
    //         if (err.extensions.debugInfo !== null) {
    //             executionId =  err.extensions.debugInfo.executionId;
    //         }
    //     }
    // });
    //
    // console.log(message);
    // console.log( errorType);
    // console.log( executionId);
    // console.log( path);
    //
    // if (statusCode === 401 || statusCode === 403) {
    //     component = <div>Utility</div>;// <CircularProgress />;//<Loader active={true}/>;
    //    // navigate('/sign-out');
    //     console.log("WOULD LIKE TO BE ABLE TO REDIRECT TO SIGN OUT HERE");
    //
    // } else {
    //     let title = "Application Error";
    //     let text = "An unexpected error occurred - please contact technical support.  " + message;
    //
    //     component = <AlertDialog title={title} text={text}/>;
    // }
    //
    // return component;
    return <></>
}


//
// sanitizeHtmlText
//
// This function changes the HTML that is retrieved for a news feed so that the URLs are compatible with the React
// navigator application when deployed to a static context such as Apache.
//
export function sanitizeHtmlText(htmlString) {
    let result = htmlString;
    let context = process.env.REACT_APP_WEBSITE_CONTEXT;
    if (htmlString !== null && htmlString !== undefined) {

        // //
        // // Change URLs in the newsfeed from something like:
        // //     <a href="/article/3d97970a-2529-42e3-9183-462bb6b41d19"
        // // to
        // //     <a href="/navigator?articleId=3d97970a-2529-42e3-9183-462bb6b41d19"
        // //
        result = htmlString.replace(/<a href="\/article\//g, "<a href=\"" + context + "?articleId=");

        // Change relative path image resources to a explicit URL on the api-gateway.  The reason that this
        // is necessary is that Bootstrap Navigator routed image calls through the Navigator Spring Boot which
        // under the covers would redirect to navigator-api-gateway.  This cannot be done in a React app that is
        // statically deployed into something like Apache.
        // Urls that look like this:
        //      <img class="" src="api/media/F-BIO-2-header-img.jpg" height="100%" width="100%">
        // are changed to Urls that look like this:
        //      <img class="" src="http://localhost:9000/images/api/media/F-BIO-2-header-img.jpg" height="100%" width="100%">
        //
        // Note the part shown above "http://localhost:9000" is pulled in from the environment variable
        //      REACT_APP_GATEWAY_HOST
        //
        // NOTE : This functionality has been moved to the ArticleDataFetcher class in the api-gateway
        //
        // result = result.replace(/\"api\/media\//g, "\"" + apiGatewayUrl +  "/images/api/media/");
    }
    return result;
}


export function cleanAuth(viewName) {
    removeItem('pkce');
    removeItem('auth');
    removeItem('currentUser');
}


export function cleanSessionStorage(viewName) {
    if (viewName === 'HomeView') {
        clearSelectedOrganizationId();
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'NetworkConnectionView') {
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'OrganizationAboutView') {
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'OrganizationResourceView') {
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'ArticleView') {
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'QuestionnaireListView') {
        clearSelectedOrganizationId();
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'PlanListView') {
        clearSelectedOrganizationId();
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'ViewQuestionnaireResultsView') {
        clearSelectedOrganizationId();
        clearSelectedArticleId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'ContinueQuestionnaireView') {
        clearSelectedArticleId();

    } else if (viewName === 'HealthData') {
        clearSelectedOrganizationId();
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();

    } else if (viewName === 'EditProfileOrPassword') {
        clearSelectedOrganizationId();
        clearSelectedArticleId();
        clearSelectedQuestionnaireId();
        clearSelectedQuestionnaireResponseId();
        clearSelectedQuestionnaireAssignmentRequestId();
    }
}

//
// Functions to store the selected view in session storage
//
export function getSelectedView() {
    let view = getItem('selectedView');
    if (view === undefined || view === null) {
        view = 'HomeView';
    }
    cleanSessionStorage(view);
    return view;
}
export function getExactSelectedView() {
    return getItem('selectedView');
}
export function getPreviousSelectedView() {
    return getItem('previousView');
}
export function setSelectedView(viewName) {
    if (getItem('selectedView') !== viewName) {
        setItem('previousView', getItem('selectedView'));
    }
    setItem('selectedView', viewName);
    cleanSessionStorage(viewName);

}
export function clearSelectedView() {
    removeItem('selectedView')
}


//
// Functions to store the selected organization id in session storage
//
export function getSelectedOrganizationId() {
    return getItem('selectedOrganizationId');
}
export function setSelectedOrganizationId(organizationId) {
    setItem('selectedOrganizationId', organizationId);
}
export function clearSelectedOrganizationId() {
    removeItem('selectedOrganizationId')
}


//
// Functions to store the selected article id in session storage
//
export function getSelectedArticleId() {
    return getItem('selectedArticleId');
}
export function setSelectedArticleId(articleId) {
    setItem('selectedArticleId', articleId);
}
export function clearSelectedArticleId() {
    removeItem('selectedArticleId')
}



//
// Functions to store the selected questionnaire id in session storage
//
export function getSelectedQuestionnaireId() {
    return getItem('selectedQuestionnaireId');
}
export function setSelectedQuestionnaireId(questionnaireId) {
    setItem('selectedQuestionnaireId', questionnaireId);
}
export function clearSelectedQuestionnaireId() {
    removeItem('selectedQuestionnaireId')
}



//
// Functions to store the selected questionnaire response id in session storage
//
export function getSelectedQuestionnaireResponseId() {
    return getItem('selectedQuestionnaireResponseId');
}
export function setSelectedQuestionnaireResponseId(questionnaireResponseId) {
    setItem('selectedQuestionnaireResponseId', questionnaireResponseId);
}
export function clearSelectedQuestionnaireResponseId() {
    removeItem('selectedQuestionnaireResponseId')
}

//
// Functions to store the selected task id (for an assigned questionnaire) in session storage
//
export function getSelectedQuestionnaireAssignmentRequestId() {
    return getItem('selectedQuestionnaireAssignmentRequestId');
}
export function setSelectedQuestionnaireAssignmentRequestId(questionnaireRequestId) {
    setItem('selectedQuestionnaireAssignmentRequestId', questionnaireRequestId);
}
export function clearSelectedQuestionnaireAssignmentRequestId() {
    removeItem('selectedQuestionnaireAssignmentRequestId')
}

//
// Functions to store user in session storage
//
export function getCurrentUserFromSessionStorage() {
    const user = getItem("user");
    return user ? JSON.parse(user) : null
}
export function setCurrentUserInSessionStorage(user) {
    setItem('user', JSON.stringify(user));
}
export function clearCurrentUserInSessionStorage() {
    removeItem('user')
}


//
// Functions to store auth tokens in session storage
//
export function getAuthFromSessionStorage() {
    const auth = getItem("auth");
    return auth ? JSON.parse(auth) : null
}
export function setAuthInSessionStorage(auth) {
    setItem('auth', JSON.stringify(auth));
}
export function clearAuthInSessionStorage() {
    removeItem('auth')
}


export function clearStateVariables() {
    clearSelectedView();
    clearSelectedOrganizationId();
    clearSelectedArticleId();
    clearSelectedQuestionnaireId();
    clearSelectedQuestionnaireResponseId();
    clearSelectedQuestionnaireAssignmentRequestId();
}



export function userInitials(currentUser) {
    let initials;
    if ((currentUser.firstName != null) && (currentUser.lastName != null)) {
        initials = currentUser.firstName.charAt(0).toUpperCase() +
            currentUser.lastName.charAt(0).toUpperCase();

    } else if ((currentUser.lastName != null)) {
        initials = currentUser.lastName.charAt(0).toUpperCase();

    } else if ((currentUser.firstName != null)) {
        initials = currentUser.firstName.charAt(0).toUpperCase();

    } else {
        initials = '?';
    }
    return initials;
}

export const JURISDICTIONS = [
    "",
    "Canada-BC",
    "Canada-AB",
    "Canada-MB",
    "Canada-NB",
    "Canada-NL",
    "Canada-NS",
    "Canada-NT",
    "Canada-NU",
    "Canada-ON",
    "Canada-PE",
    "Canada-QC",
    "Canada-SK",
    "Canada-YT",
];