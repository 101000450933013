import {AppBar, Box, Divider, Grid, Link, Stack, Toolbar, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useMutation} from "@apollo/client";
import {START_QUESTIONNAIRE_SURVEY_REQUEST} from "../../gqlMutators";
import React from "react";
import {clearSelectedQuestionnaireId, setSelectedQuestionnaireResponseId} from "../utility";
import { CAMBIAN_REACT_VERSION } from "../../version";

function PublicFooter(props) {
    const {selectedViewCallback} = props;
    const { t } = useTranslation();
    const [startQuestionnaireRequestGraphQL] = useMutation(START_QUESTIONNAIRE_SURVEY_REQUEST);
    const [showIsBusy, setShowIsBusy] = React.useState(false);

    const applicationName = process.env.REACT_APP_NAME;
    const applicationVersion = process.env.REACT_APP_VERSION;
    const cambianOrganizationId = process.env.REACT_APP_CAMBIAN_ORGANIZATION_ID;
    const cambianSatisfactionId = process.env.REACT_APP_CAMBIAN_SATISFACTION_SURVEY;
    const privacyStatementDocument = process.env.REACT_APP_PRIVACYSTATEMENT_DOCUMENT_PDF;
    const userAgreementDocument = process.env.REACT_APP_USERAGREEMENT_DOCUMENT_PDF;
    const [,,cambianReactPatchVersion] = CAMBIAN_REACT_VERSION.split(".");

    const answerSurveyQuestionnaire = () => {
        setShowIsBusy(true);
        startQuestionnaireRequestGraphQL({
            variables: {
                "OrganizationId": cambianOrganizationId,
                "QuestionnaireId": cambianSatisfactionId
            }
        }).then(result => {
            console.log(result);
            let questionnaireResponseId = result.data.startQuestionnaire;
            clearSelectedQuestionnaireId();
            setSelectedQuestionnaireResponseId(questionnaireResponseId);
            selectedViewCallback('ContinueQuestionnaireView');
            setShowIsBusy(false);
        }).catch(error => {
            console.log(error); // TODO - need to get a error dialog up
            setShowIsBusy(false);
        });
    }

    return (
        <>
           {/*// <Loader active={showIsBusy} />*/}
            <AppBar position="fixed"
                    color="default"
                    component={Grid} 
                    display={{xs: "none", md: "block"}}
                    sx={{
                          top: 'auto',
                          bottom: 0,
                          minHeight: '66px'
                    }}>
                <Toolbar>
                    <Grid container justifyContent="flex-start" sx={{flexGrow: 1, width: 1/4}}>
                        <Typography variant="caption">{t("Copyright © Cambian Business Services, Inc.")}</Typography>
                    </Grid>
                    <Box sx={{flexGrow: 1}}/>
                    <Stack direction="row"
                           divider={<Divider orientation="vertical" flexItem/>}
                           spacing={2}>
                        {/*<Link sx={{fontSize: fontSize}} onClick={() => answerSurveyQuestionnaire()} style={noPointer}>{t("Feedback")}</Link>*/}
                        <Link variant="caption" href="mailto:support@cambian.com">{t("Help")}</Link>
                        <Link variant="caption" href={privacyStatementDocument} target="_blank">{t("Privacy Statement")}</Link>
                        <Link variant="caption" href={userAgreementDocument} target="_blank">{t("User Agreement")}</Link>

                    </Stack>
                    <Box sx={{flexGrow: 1}}/>
                    <Grid container justifyContent="flex-end" sx={{flexGrow: 1, width: 1/4}}>
                        <Typography variant="caption">{applicationName} {applicationVersion}/{cambianReactPatchVersion}</Typography>
                    </Grid>
                </Toolbar>
            </AppBar>
        </>
    );
}

export default PublicFooter;