import React, {useEffect} from "react";
import {FhirQuestionnaire} from "@cambianrepo/cambianreact";
import {
    retrieveQuestionnaireById,
    retrieveQuestionnaireResponseById
} from "./questionnaireHelper"
import {
    Box, Button,
    ClickAwayListener,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Skeleton,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {
    clearSelectedQuestionnaireId,
    getAuthFromSessionStorage,
    getSelectedOrganizationId,
    getSelectedQuestionnaireAssignmentRequestId,
    getSelectedQuestionnaireId,
    getSelectedQuestionnaireResponseId,
    setSelectedOrganizationId, setSelectedQuestionnaireAssignmentRequestId,
    setSelectedQuestionnaireResponseId
} from "../../../utility";
import {useMutation, useQuery} from "@apollo/client";
import {GET_QUESTIONNAIRE_RESPONSE_SUMMARIES} from "../../../../gqlQueries";
import {START_ASSIGNED_QUESTIONNAIRE_REQUEST} from "../../../../gqlMutators";
import Loader from "../../../Loader";
import { useAuthContext } from "navigator/security";
function AnswerQuestionnaire(props) {
    const handleSaveForLaterRef = React.useRef(null)
    const {selectedViewCallback} = props;
    const { t } = useTranslation();

    let organizationIdParameter;

    let questionnaireIdParameter;
    let questionnaireResponseIdParameter;
    let questionnaireAssignmentRequestIdParameter;
    let autoSave = false;
    if (getSelectedOrganizationId() !== null) {
        organizationIdParameter = getSelectedOrganizationId();
    }

    if (getSelectedQuestionnaireId() !== null) {
        questionnaireIdParameter = getSelectedQuestionnaireId();
    }

    if (getSelectedQuestionnaireResponseId() !== null) {
        questionnaireResponseIdParameter = getSelectedQuestionnaireResponseId();
    }

    if (getSelectedQuestionnaireAssignmentRequestId() !== null) {
        questionnaireAssignmentRequestIdParameter = getSelectedQuestionnaireAssignmentRequestId();
    }


    let isUpdateMode = (questionnaireResponseIdParameter !== undefined);

    const [showBusyRetrievingQuestionnaire,setShowBusyRetrievingQuestionnaire] = React.useState(false);
    const [showBusyRetrievingQuestionnaireResponse,setShowBusyRetrievingQuestionnaireResponse] = React.useState(false);
    const [questionnaireId, setQuestionnaireId] = React.useState();
    const [questionnaireResponseId, setQuestionnaireResponseId] = React.useState();

    if (questionnaireId === undefined && questionnaireIdParameter !== undefined) {
        setQuestionnaireId(questionnaireIdParameter);
    }
    if (questionnaireResponseId === undefined && questionnaireResponseIdParameter !== undefined) {
        setQuestionnaireResponseId(questionnaireResponseIdParameter);
    }
    const [fetchQuestionnairePending, setFetchQuestionnairePending] = React.useState(false);
    const [fetchedQuestionnaire, setFetchedQuestionnaire] = React.useState(false);
    const [questionnaireJSON, setQuestionnaireJSON] = React.useState({});
    const [fetchQuestionnaireResponsePending, setFetchQuestionnaireResponsePending] = React.useState(false);
    const [fetchedQuestionnaireResponse, setFetchedQuestionnaireResponse] = React.useState(false);
    const [questionnaireResponseJSON, setQuestionnaireResponseJSON] = React.useState({});
    const [pageError, setPageError] = React.useState(false);
    const [responseSaving, setResponseSaving] = React.useState(false);
    const [updateRequired, setUpdateRequired] = React.useState(true);
    const [openSaveErrorDialog, setOpenSaveErrorDialog] = React.useState(false);
    const [openQuestionnaireAction, setOpenQuestionnaireAction] = React.useState({});

    const { currentUser } = useAuthContext();;
    const { refetch } = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });
    const [startQuestionnaireRequestGraphQL] = useMutation(START_ASSIGNED_QUESTIONNAIRE_REQUEST);

    // Needed to trigger questionnaire actions
    const continueQuestionnaire = (organizationId, questionnaireResponseId, taskId) => {
        console.log('continue questionnaire response: ' + questionnaireResponseId)
        clearSelectedQuestionnaireId();
        setSelectedOrganizationId(organizationId);
        setSelectedQuestionnaireResponseId(questionnaireResponseId);
        if (taskId !== null) {
            setSelectedQuestionnaireAssignmentRequestId(taskId); // need this to daisy chain the next questionnaire action
        }
        selectedViewCallback('ContinueQuestionnaireView');
        window.location.replace(process.env.REACT_APP_WEBSITE_CONTEXT);
    }

    // Needed to trigger questionnaire actions and all three parameters will be present
    const answerQuestionnaireActionQuestionnaire = (organizationId, questionnareId, taskId) => {
        setResponseSaving(true);
        startQuestionnaireRequestGraphQL({
            variables: {
                "OrganizationId": organizationId,
                "QuestionnaireId": questionnareId,
                "TaskId": taskId
            }
        }).then(result => {
            console.log(result);
            let questionnaireResponseId = result.data.startQuestionnaire;
            refetch().then(() => {
                continueQuestionnaire(organizationId, questionnaireResponseId, taskId);
            });
        }).catch(error => {
            console.log(error); // TODO - need to get a error dialog up
        });
    }

    const handleCloseQuestionnaireAction = () => {
        setOpenQuestionnaireAction();
        selectedViewCallback("ViewQuestionnaireResultsView");
    }
    const handleContinueQuestionnaireAction = () => {
        let questionnaireAction = openQuestionnaireAction;
        setOpenQuestionnaireAction();
        answerQuestionnaireActionQuestionnaire(questionnaireAction.organizationId, questionnaireAction.nextQuestionnaireId, questionnaireAction.taskId);
    }

    const handleCloseSaveError = () => {
        setOpenSaveErrorDialog(false);
 //       selectedViewCallback('QuestionnaireListView');
    }

    const fetchQuestionnaireById = (id) => {
        setUpdateRequired(false);
        setShowBusyRetrievingQuestionnaire(true);
        setFetchQuestionnairePending(true);

        retrieveQuestionnaireById(getAuthFromSessionStorage()?.accessToken, id)
            .then((response) => response.json())
            .then((data) => {
                let hackedQuestionnaire = hackFixStripDisplayQuestion(data);
                setQuestionnaireJSON(hackedQuestionnaire);
                setFetchedQuestionnaire(true);
                setFetchQuestionnairePending(false);
                setUpdateRequired(true);
                setShowBusyRetrievingQuestionnaire(false);
            })
            .catch((error) => {
                setPageError(true);
                setUpdateRequired(true);
                setShowBusyRetrievingQuestionnaire(false);
                console.log(error);
            });
    }

// TODO: REmove
// FROM RNW: Don't recall adding this method and not yet sure why it is here.  Need to work to remove 4/22/22)
    const hackFixStripDisplayQuestion = (fhirQuestionnaire) => {

        let modifiedQuestionnaire = {};
        if (fhirQuestionnaire.item === undefined) {
            console.log("FHIR questionnaire was malformed.  The contents of the questionnaire is:");
            console.log(fhirQuestionnaire);
        } else {
            let filteredQuestions = [];
            fhirQuestionnaire.item.forEach(question => {
                if (question.type !== 'display') {
                    filteredQuestions.push(question);
                }
            })

            modifiedQuestionnaire = {
                resourceType: fhirQuestionnaire.resourceType,
                id: fhirQuestionnaire.id,
                extension: fhirQuestionnaire.extension,
                identifier: fhirQuestionnaire.identifier,
                name: fhirQuestionnaire.name,
                title: fhirQuestionnaire.title,
                status: fhirQuestionnaire.status,
                date: fhirQuestionnaire.date,
                publisher: fhirQuestionnaire.publisher,
                description: fhirQuestionnaire.description,
                item: filteredQuestions
            }
        }

        return modifiedQuestionnaire;
    }





    const fetchQuestionnaireResponseById = (id) => {
        setUpdateRequired(false);
        setShowBusyRetrievingQuestionnaireResponse(true);
        setFetchQuestionnaireResponsePending(true);

        retrieveQuestionnaireResponseById(getAuthFromSessionStorage()?.accessToken, id)
            .then((response) => response.json())
            .then((data) => {
                setQuestionnaireResponseJSON(data);
                setFetchedQuestionnaireResponse(true)
                setFetchQuestionnaireResponsePending(false);
                setQuestionnaireId(data.questionnaire);
                setUpdateRequired(true);
                setShowBusyRetrievingQuestionnaireResponse(false);
            })
            .catch((error) => {
                setPageError(true);
                setUpdateRequired(true);
                setShowBusyRetrievingQuestionnaireResponse(false);
                console.log(error);
            });
    }


    useEffect(() => {
        if (updateRequired && !fetchQuestionnairePending && !fetchQuestionnaireResponsePending) {
            if (questionnaireId === undefined) {
                if (questionnaireResponseId !== undefined && !fetchedQuestionnaireResponse) {
                    fetchQuestionnaireResponseById(questionnaireResponseId);
                }

            } else if (!fetchedQuestionnaire) {
                fetchQuestionnaireById(questionnaireId);
            }

            setUpdateRequired(true);
        }
    });

    // When response is saving, we put up a circular spinner in the center of the screen to
    // provide feedback to the user that something is happening
    //
    if (responseSaving) {
        return (
            <Loader active={true} message={"Saving questionnaire responses. Please wait."}/>
        )
    }
    else if (showBusyRetrievingQuestionnaire || showBusyRetrievingQuestionnaireResponse) {
        return (
            <Loader active={true} message={"Loading questionnaire. Please wait."}/>
        );
    }


    if (!fetchedQuestionnaire || (isUpdateMode && !fetchedQuestionnaireResponse)) {
        if (!pageError) {
            return (
                <Grid container direction="row"
                      sx={{
                          '& .MuiTextField-root': {m: 1, width: '40ch'}, p: 2, overflowY: 'scroll', height: '100%'
                      }}>
                    <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>
                    <Box component={Grid} item xs={12} sm={10} md={8} display={{xs: "block"}}>
                        <Skeleton variant="rectangular" width={'100%'} height={500} />
                    </Box>
                    <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>

                </Grid>
            );
        }
        return (<div>FAILURE</div>);
    }

    //
    //  FHIR Parameter JSON structure should look like this:
    //
    // {
    //         "resourceType":"Parameters",
    //         "parameter":[
    //         {
    //             "name":"organization",
    //             "valueString":"<Organization Id>"
    //         },
    //         {
    //             "name":"patient",
    //             "resource":
    //                 <FHIR Patient structure>
    //         },
    //         {
    //             "name":"questionnaireResponse",
    //             "resource":
    //                 <FHIR Questionnaire Response structure>
    //         }
    //     ]
    // }
    const saveResponse = (isComplete, isSavedForLater, questionnaireGlobalId, fhirQuestionnaireResponse) => {
        if(!autoSave) {
            setResponseSaving(true);
        }
        fhirQuestionnaireResponse.author = {
            "reference": "Patient/" + currentUser.patientReference.idValue
        }

        console.log("organizationId: " + organizationIdParameter);
        let qrMethod;
        let qrPersistPayload;
        let qrPersistUrl;
        qrMethod = 'PUT';
        qrPersistPayload = fhirQuestionnaireResponse;
        qrPersistUrl = process.env.REACT_APP_GATEWAY_HOST + '/fhir/questionnaireresponse/update/' + questionnaireResponseId +
                                        '?finalize=' + isComplete + '&email=' + currentUser.email +
                                        (questionnaireGlobalId !== undefined ? '&questionnaireId=' + questionnaireGlobalId : '') +
                                        (organizationIdParameter !== undefined ? '&organizationId=' + organizationIdParameter : '') +
                                        (questionnaireAssignmentRequestIdParameter !== undefined ? '&requestId=' + questionnaireAssignmentRequestIdParameter : '');

        const accessToken = "Bearer " + getAuthFromSessionStorage()?.accessToken;
        fetch(qrPersistUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': accessToken
                },
                method: qrMethod,
                body: JSON.stringify(qrPersistPayload)
            })
            .then((response) => response.json())
            .then((data) => {
                 if (data.successful === false) {
                     setResponseSaving(false);
                     setOpenSaveErrorDialog(true);

                 } else {
                     if (isComplete) {
                        if (data.questionnaireAction !== undefined && data.questionnaireAction !== null) {
                            refetch().then(()=> {
                                setResponseSaving(false);
                                setQuestionnaireResponseId(questionnaireResponseId);
                                setQuestionnaireResponseJSON(data.questionnaireResponseJson);
                                setSelectedQuestionnaireResponseId(questionnaireResponseId);
                                setOpenQuestionnaireAction(data.questionnaireAction);
                            })
                        } else {
                            refetch().then(() => {
                                    setQuestionnaireResponseId(questionnaireResponseId);
                                    selectedViewCallback('ViewQuestionnaireResultsView');
                                });
                        }
                    } else {
                        if(!autoSave) {
                            refetch().then(() => {
                                        setResponseSaving(false);
                                        setQuestionnaireResponseId(data.id);
                                        setQuestionnaireResponseJSON(data.questionnaireResponseJson);
                                        selectedViewCallback('QuestionnaireListView');
                                    });
                        }
                    }
                }

            })
            .catch((error) => {
                console.log("ERROR: " + error);
            });
    }


    const questionnaireSaveCallback = (fhirQuestionnaire, fhirQuestionnaireResponse, questionMap, isComplete, isCancelled, isSavedForLater) => {
        if (isCancelled) {
            console.log("Questionnaire Response (isCancelled=" + isCancelled + ") ");
            selectedViewCallback('QuestionnaireListView');

        } else if (isComplete) {
            setResponseSaving(true);
            console.log("Questionnaire Response (isComplete=" + isComplete + "): ");
            console.log("Questionnaire: " + questionnaireId);
            console.log(fhirQuestionnaireResponse);
            console.log(JSON.stringify(fhirQuestionnaireResponse));
            saveResponse(isComplete, isSavedForLater, fhirQuestionnaireResponse.questionnaire, fhirQuestionnaireResponse);

        } else if (isSavedForLater) {
            if(!autoSave) {
                setResponseSaving(true);
            }
            console.log("Questionnaire Response (isSavedForLater=" + isSavedForLater + "): ");
            console.log(fhirQuestionnaireResponse);
            console.log(JSON.stringify(fhirQuestionnaireResponse));
            saveResponse(isComplete, isSavedForLater, fhirQuestionnaireResponse.questionnaire, fhirQuestionnaireResponse);
        }
    }

    const autoSaveQuestionnaire = (event) => {
        let menuItem;
        if(event.target.parentElement.children && event.target.parentElement.children.length > 1) {
            menuItem = event.target.parentElement.children[1].innerText;
        }
        if(event.target.classList.contains("MuiMenuItem-root") || event.target.parentElement.tagName === "A" || menuItem === "Home" ||  menuItem === "Network" || menuItem === "Questionnaires") {
            autoSave = true;
            if(handleSaveForLaterRef && handleSaveForLaterRef.current) {
                handleSaveForLaterRef.current();
            }
        }
    }

    return (
         <Grid container direction="row"
              sx={{
                  '& .MuiTextField-root': {m: 1}, '& .css-5n8ohs-MuiGrid-root': {flexBasis: '100%', maxWidth: '100%'}, '& .MuiTypography-root': {m: '5px'}, p: 2, height: '100%'
              }}>
            <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>
            <Box component={Grid} item xs={12} sm={10} md={8} display={{xs: "block"}}>
                <ClickAwayListener onClickAway={autoSaveQuestionnaire} mouseEvent="onMouseDown" touchEvent="onTouchStart" >
                    <div>
                        <FhirQuestionnaire handleSaveForLaterRef={handleSaveForLaterRef}
                                           fhirQuestionnaire={questionnaireJSON}
                                           isProgressPercentageEnabled={true}
                                           fhirResponse={questionnaireResponseJSON}
                                           isSaveForLaterActive={true}
                                           containedQuestionnaire={true}
                                           questionnaireCallback=
                                               {
                                                   (fhirQuestionnaire, fhirQuestionnaireResponse, questionMap, isComplete, isCancelled, isSavedForLater) =>
                                                       questionnaireSaveCallback(fhirQuestionnaire, fhirQuestionnaireResponse, questionMap, isComplete, isCancelled, isSavedForLater)
                                               }/>
                    </div>
                </ClickAwayListener>
                <ClickAwayListener onClickAway={handleCloseQuestionnaireAction}>
                    <Dialog open={openQuestionnaireAction !== undefined && openQuestionnaireAction.nextQuestionnaireId !== undefined}
                            onClose={handleCloseQuestionnaireAction}>
                        <DialogTitle>New Questionnaire To Complete</DialogTitle>
                        <DialogContent>
                            <Box>
                                <Typography id="questionnaireSaved" sx={{m: 2}}>
                                    {t("You have been assigned a new ")}{openQuestionnaireAction.nextQuestionnaireName}{t(" questionnaire by ")}{openQuestionnaireAction.organizationName}{t(". Click the 'Launch Now' button to complete it.")}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="outlined" 
                                    onClick={handleCloseQuestionnaireAction}>
                               {t("Do It Later")}
                            </Button>
                            <Button variant="contained" 
                                    onClick={handleContinueQuestionnaireAction}>
                               {t("Launch Now")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ClickAwayListener>

                <ClickAwayListener onClickAway={handleCloseSaveError}>
                    <Dialog open={openSaveErrorDialog}
                            onClose={handleCloseSaveError}>
                        <DialogTitle>{t("Failure when saving Questionnaire")}</DialogTitle>
                        <DialogContent>
                            <Box>
                                <Typography id="questionnaireSaveFail" sx={{m: 2}}>
                                    {t("The attempt to save the questionnaire has failed.  Please contact technical support.")}
                                </Typography>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button variant="contained"
                                    onClick={handleCloseSaveError}>
                               {t("Close")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </ClickAwayListener>

            </Box>
            <Box component={Grid} item xs={0} sm={1} md={2} display={{xs: "hidden", md: "block"}}/>
        </Grid>
    );
}

export default AnswerQuestionnaire;