import React from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_BP_SUMMARY } from "../../../gqlQueries";
import {ADD_BP_DATA_POINT, EDIT_BP_DATA_POINT, DELETE_BP_DATA_POINT, SET_BP_TARGET} from "../../../gqlMutators";
import {Skeleton} from "@mui/material";
import { BloodPressure } from "@cambianrepo/cambianreact";
import {AddEditBpForm} from "@cambianrepo/cambianreact";
import TwoColumnPage from "../../TwoColumnPage";
import { startOfDay, endOfDay, format, isEqual, addDays } from 'date-fns'
import { useAuthContext } from "navigator/security";

const LOCAL_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

const IntervalTypeEnum = {
    DAY: "OneDay",
    WEEK: "OneWeek",
    MONTH: "OneMonth",
    YEAR: "OneYear"
}

function  calculateStartDate(selectedDate, interval) {
    var startDate = new Date(selectedDate);
    switch (interval) {
        case IntervalTypeEnum.DAY:
            break;
        case IntervalTypeEnum.WEEK:
            startDate.setDate(selectedDate.getDate() - 6);
            break;
        case IntervalTypeEnum.MONTH:
            startDate = addDays(startDate, (-27));
            break;
        case IntervalTypeEnum.YEAR:
            startDate = addDays(startDate.setYear(selectedDate.getFullYear() - 1), 1);
            break;
        default:
            break;
    }
    return startOfDay(startDate);
}

function BloodPressurePage(props) {
    const {currentUser} = useAuthContext();

    const [selectedDateAndInterval, setSelectedDateAndInterval] = React.useState({date: startOfDay(new Date()), interval: IntervalTypeEnum.DAY})
    const [view, setView] = React.useState(0);
    const [bloodPressureDataPoint, setBloodPressureDataPoint] = React.useState(null);
    var graphData;
    const [addBpDataPoint] = useMutation(ADD_BP_DATA_POINT, {
        variables: {
            "bloodPressureDataPoint": bloodPressureDataPoint
        }
    });

    const [editBpDataPoint] = useMutation(EDIT_BP_DATA_POINT, {
        variables: {
            "bloodPressureDataPoint": bloodPressureDataPoint
        }
    });

    const [setBpTarget] = useMutation(SET_BP_TARGET);

    const [deleteBpDataPoint] = useMutation(DELETE_BP_DATA_POINT);
    
    const  { loading, error, data, refetch } = useQuery(GET_BP_SUMMARY, {
        variables: {
            "PatientId": currentUser.patientReference.idValue,
            "EndDate": format(endOfDay(selectedDateAndInterval.date), LOCAL_DATE_TIME_FORMAT),
            "StartDate": format(calculateStartDate(selectedDateAndInterval.date, selectedDateAndInterval.interval), LOCAL_DATE_TIME_FORMAT),
            "DesiredView": selectedDateAndInterval.interval
        }
    });
    React.useEffect(() => {
        if (!bloodPressureDataPoint) {
            refetch();
        }
      });

    const handleBpFieldChange = (key, value) => {
        switch(key) {
            case 'collectedDateTime':
                let bpClone = {...bloodPressureDataPoint};        
                bpClone.utcCollectedDateTime = value;
                bpClone.localCollectedDateTime = format(value,  "yyyy-MM-dd HH:mm:ss");
                bpClone.localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                setBloodPressureDataPoint(bpClone);
                break;
            default:
                setBloodPressureDataPoint(prevState => ({
                    ...prevState,
                    [key]: value
                }));
                break;
            }
    }

    const closeAddEditForm = () => {
        setBloodPressureDataPoint(null);
      };

    const openFormForCreate = () => {
        setBloodPressureDataPoint({patientId: currentUser.patientReference.idValue, systolic: null, diastolic: null, heartRate: null, note: null, unit: "mmHg",  utcCollectedDateTime: new Date(), localCollectedDateTime: format(new Date(),  "yyyy-MM-dd HH:mm:ss"), localTimeZone: "America/Vancouver", dataSource: "MANUAL_ENTRY"});
    }  

    function deleteBpDataPointCallBack (bpDataPoint) {
        deleteBpDataPoint({variables: {id: bpDataPoint.objectId.idValue}})
        .then(result => {
            console.log(result);
            closeAddEditForm();
            // TODO: refresh is called in useEffect not needed here
            if (isEqual(selectedDateAndInterval.date, startOfDay(new Date(bpDataPoint.localCollectedDateTime))) && selectedDateAndInterval.interval === IntervalTypeEnum.DAY) {
                refetch();
            } else {
                setSelectedDateAndInterval({date: startOfDay(new Date(bpDataPoint.localCollectedDateTime)), interval: IntervalTypeEnum.DAY});
            }
            });
    }

    function submitSetTargetFormCallBack (systolic, diastolic) {
        setBpTarget({variables: {patientId: currentUser.patientReference.idValue, systolic: systolic, diastolic: diastolic}})
        .then(result => {
            console.log(result);
            // TODO: refresh is called in useEffect not needed here
            refetch();
            });
    }

    const addOnClickCallBack = () => {
        const newDate = startOfDay(new Date(bloodPressureDataPoint.localCollectedDateTime));
           if ( bloodPressureDataPoint && bloodPressureDataPoint.objectId && bloodPressureDataPoint.objectId.idValue ) {
                editBpDataPoint()
                .then(result => {
                    console.log(result);
                    closeAddEditForm();
            // TODO: refresh is called in useEffect not needed here

                    if (isEqual(selectedDateAndInterval.date, newDate) && selectedDateAndInterval.interval === IntervalTypeEnum.DAY) {
                        refetch();
                    } else {
                        setSelectedDateAndInterval({date: startOfDay(newDate), interval: IntervalTypeEnum.DAY});
                    }
                });

           } else {     
            addBpDataPoint()
                .then(result => {
                    console.log(result);
                   
                    closeAddEditForm();
                    if (isEqual(selectedDateAndInterval.date, newDate) && selectedDateAndInterval.interval === IntervalTypeEnum.DAY) {
                        refetch();
                    } else {
                        setSelectedDateAndInterval({date: startOfDay(newDate), interval: IntervalTypeEnum.DAY});
                    }
                }
            );
            }
        
    }
    

    var dateLabel = new Date(selectedDateAndInterval.date);


    const onIntervalChange = (newInterval, selectedDate) => {
        setSelectedDateAndInterval({date: selectedDate, interval: newInterval});
    }


    const onSelectedDateChange = (selectedInterval, startDate, endDate) => {
        setSelectedDateAndInterval({date: startOfDay(startDate), interval: selectedInterval});
    }

    const rowOnClickCallBack = (event, data) => {
        var interval = selectedDateAndInterval.interval;
    
            if(interval === IntervalTypeEnum.DAY ) {
                console.log("day view datapoint: " + data.systolic);
                let idCopy = {...data.objectId};
                let bpDataCopy = {...data};
                bpDataCopy.objectId = idCopy;
                delete bpDataCopy['__typename'];
                delete bpDataCopy.objectId['__typename'];
                delete bpDataCopy['numEveningReadings'];
                delete bpDataCopy['numMorningReadings'];    
                setBloodPressureDataPoint(bpDataCopy);
                // setIsEditFormOpen(false);
                // open form
                // set bpdatapoint
              
            } else if(interval === IntervalTypeEnum.WEEK ) {
                setSelectedDateAndInterval({date: startOfDay(data), interval: IntervalTypeEnum.DAY});
            } else if(interval === IntervalTypeEnum.MONTH ) {
                setSelectedDateAndInterval({date: startOfDay(data.setDate(data.getDate() + 6)), interval: IntervalTypeEnum.WEEK});
            } else if(interval === IntervalTypeEnum.YEAR ) {
                setSelectedDateAndInterval({date: startOfDay(data.setDate(data.getDate() + 27)), interval: IntervalTypeEnum.MONTH});
            }
    }

    var bpTableData = {dataPoints: []};
   
    if (error) {
        console.log('something failed');
        return <div>Error: Something Failed - bloodPressure view</div>;
     
    } else if (loading) {
        return(
            <div style={{ height: '100%', padding: '20px' }}>
                <TwoColumnPage leftColumn={
                    <Skeleton variant="rectangular" width={'100%'} height={780} />
                } />
            </div>
        );

    } else {
        graphData = data.getBloodPressureSummary;
        return (
            <div style={{ overflowY: 'scroll', height: '100%' }}>
            {/* <Loader active={loading? true : false} />            */}
            <TwoColumnPage
                leftColumn={
                    <div style={{padding: '20px'}}>
                        <BloodPressure style={{ padding: '5px' }}
                                       rowOnclickCallBack={rowOnClickCallBack}
                                       onIntervalChange={onIntervalChange}
                                       onSelectedDateChange={onSelectedDateChange}
                                       graphData={graphData}
                                       selectedInterval={selectedDateAndInterval.interval}
                                       selectedDate={selectedDateAndInterval.date}
                                       dateLabel={dateLabel}
                                       bpTableData={bpTableData}
                                       view={view} viewChangeCallBack={setView}
                                       addOnClickCallBack={openFormForCreate}
                                       submitTargetCallBack={submitSetTargetFormCallBack} />

                        <AddEditBpForm open={bloodPressureDataPoint? true : false}
                                       showRemove={bloodPressureDataPoint && bloodPressureDataPoint.objectId && bloodPressureDataPoint.objectId.idValue ? true : false}
                                       removeCallBack={deleteBpDataPointCallBack}
                                       handleClose={closeAddEditForm}
                                       addOnClickCallBack={addOnClickCallBack}
                                       handleBpFieldChange={handleBpFieldChange}
                                       bloodPressureDataPoint={bloodPressureDataPoint} />
                    </div>
                }
                // rightColumn={<OrganizationAds />}
                // rightColumn={}
                 />
            </div>
        );
    }

}
export default BloodPressurePage;