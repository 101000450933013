import { useState, useRef } from "react";
import { Button, Skeleton, Stack, TextField, Typography, styled, IconButton, useMediaQuery } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useMutation, useQuery } from "@apollo/client";
import { MODIFY_SUBSCRIPTION_REQUEST, SUBSCRIBE_TO_PLAN_REQUEST } from "../../../../gqlMutators";
import {CANCEL_SUBSCRIPTION_REQUEST} from "../../../../gqlMutators";
import { format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { GET_SUBSCRIPTIONS } from "../../../../gqlQueries";
import { errorMessageAction } from "../../../utility";
import { Markdown } from "@cambianrepo/cambianreact";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {useTranslation} from "react-i18next";

const LOCAL_DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
const LOCAL_DATE_FORMAT_WITHOUT_HOURS = "yyyy-MM-dd";

// startDateStatus can be NOW, USR_SELECTED, ORG_SELECTED
function PlanDetails(props) {
    const { planId, description, startDateStatus } = props;
    const { t } = useTranslation();
    const isSmallerThan550 = useMediaQuery('(max-width:550px)');

    const [subscribeToPlan, { loading:subscriptionLoading } ] = useMutation(SUBSCRIBE_TO_PLAN_REQUEST);
    const [modifySubscription, { loading:modifySubscriptionLoading }] = useMutation(MODIFY_SUBSCRIPTION_REQUEST);
    const [cancelSubscription, { loading:cancelSubscriptionLoading }] = useMutation(CANCEL_SUBSCRIPTION_REQUEST);

    const planSubscriptionGlobalId = useRef();
    
    const [startPlanDate, setStartPlanDate] = useState(new Date());
    const [prevStartDate, setPrevStartDate] = useState(new Date())
    const [encounteredError, setEncounteredError] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [isPlanSentOut, setIsPlanSentOut] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    //There's an issue where "onCompleted" can't find variables or functions initialized below its line.
    //https://github.com/apollographql/apollo-client/issues/9846 
    const calculateIsSubscribedAndSetStates = (planId, subscriptionList) => {
        console.log(subscriptionList)
        for (var i = 0; i < subscriptionList.length; i++) {
            if (subscriptionList[i].publishedPlan.planGlobalId === planId) {
                planSubscriptionGlobalId.current = subscriptionList[i].planSubscriptionGlobalId;
                setStartPlanDate(new Date(subscriptionList[i].planStartDate.replace(/-/g, "/")))
                setIsPlanSentOut(subscriptionList[i].hasStarted);
                setIsSubscribed(true)
                break;
            }
        }
    }

    const { refetch, loading, error } = useQuery(GET_SUBSCRIPTIONS, { onCompleted: (data => { calculateIsSubscribedAndSetStates(planId, data.getSubscriptions) }) });

    const handleStartPlanChange = (selectedDate) => {
        setPrevStartDate(startPlanDate);
        setStartPlanDate(selectedDate);
    };

    const closeErrorDialog = () => {
        setEncounteredError(false);
    }

    const handleStartPlan = () => {
        console.log('Start date: ' + startPlanDate);
       
        subscribeToPlan({
            variables: {
                "PlanId": planId,
                "StartDate": format(startPlanDate, LOCAL_DATE_TIME_FORMAT)
            }
        }).then(result => {
            console.log(result)
            if (result.data.subscribeToPlan !== null) {
                setIsSubscribed(true)
                refetch()
            } else {
                setEncounteredError(true);
            }
        }).catch(error => {
            console.error(error); // TODO - need to get a error dialog up
        });
    }

    const handleStartPlanNow = () => {
        const startToday = new Date();
        setStartPlanDate(startToday);
        subscribeToPlan({
            variables: {
                "PlanId": planId,
                "StartDate": format(startToday, LOCAL_DATE_TIME_FORMAT)
            }
        }).then(result => {
            console.log(result)
            if (result.data.subscribeToPlan !== null) {
                setIsSubscribed(true)
                refetch()
            } else {
                setEncounteredError(true);
            }
        }).catch(error => {
            console.error(error); // TODO - need to get a error dialog up
        });
    }

    const handleModifyPlan = () => {
        console.log('modify date: ' + startPlanDate);
        modifySubscription({
            variables: {
                "SubscriptionGlobalId": planSubscriptionGlobalId.current,
                "StartDate": format(startPlanDate, LOCAL_DATE_TIME_FORMAT)
            }
        }).then(result => {
            console.log(result)
            if (result.data.modifySubscription !== true) {
                setIsEdit(false)
                refetch()
            } else {
                setEncounteredError(true);
            }
        }).catch(error => {
            console.error(error); // TODO - need to get a error dialog up
        });
    }


    const handlePlanUnsubscription = () => {
        cancelSubscription({
            variables: {
                "SubscriptionGlobalId": planSubscriptionGlobalId.current
            }
        })
        .then(() => {
            setIsSubscribed(false)
            setIsEdit(false)
            setIsPlanSentOut(false)
            refetch()
        })
        .catch(() => {
            console.error('failed to hide questionnaire response');
        })
    };

    const cancelChanges = () => {
        setIsEdit(false)
        setStartPlanDate(prevStartDate)
    }
    const renderDateHandlers = () => {
        if(isPlanSentOut) {
            return <Typography>{t("Started On:")} {format(startPlanDate, LOCAL_DATE_FORMAT_WITHOUT_HOURS)}</Typography>
        } else if (isSubscribed && !isEdit) {
            return <Typography>{t("Start Date:")} {format(startPlanDate, LOCAL_DATE_FORMAT_WITHOUT_HOURS)}</Typography>
        }
        if (startDateStatus==='USR_SELECTED') {
            return (
                <DatePicker label="Start Date"
                            inputFormat="yyyy-MM-dd"
                            disabled={subscriptionLoading || modifySubscriptionLoading || cancelSubscriptionLoading}
                            value={startPlanDate}
                            onChange={handleStartPlanChange}
                            renderInput={(params) => <TextField size="small" {...params} />}/>
            )
        }
    }

    const renderPlanHandlers = () => {
        // Note: There is an edge case where the plan is sent out in the middle of edit.
        // second OR condition handles case if user misclicked start plan and would like to delay to a later date
        if(isPlanSentOut) {
            return (
                <CustomButton variant="outlined"
                              disabled={modifySubscriptionLoading || cancelSubscriptionLoading}
                              color="error"
                              onClick={handlePlanUnsubscription}>
                    {t("Stop Plan")}
                </CustomButton>
            )
        } else if (!isSubscribed && startDateStatus==='NOW') {
            return (
                <CustomButton variant="contained"
                            disabled={subscriptionLoading || cancelSubscriptionLoading}
                            onClick={handleStartPlanNow}>
                        {t("Start Plan Now")}
                </CustomButton>
            )
        } else if (!isSubscribed) {
            return (
                <CustomButton variant="contained"
                              disabled={subscriptionLoading || cancelSubscriptionLoading}
                              onClick={handleStartPlan}>
                    {t("Start Plan")}
                </CustomButton>
            )
        } else if (!isEdit && isSmallerThan550) {
            return (
                <CustomButton variant="outlined"
                                onClick={()=>setIsEdit(true)}>
                    {t("Edit")}
                </CustomButton>
            )
        } else if (!isEdit && !isSmallerThan550) {
            return (
                <IconButton aria-label="edit" size='small'  onClick={()=>setIsEdit(true)}>
                    <EditIcon fontSize='small'/>
                </IconButton>
            )
        } else if (isEdit) {
            return (
                <>
                {startDateStatus==='USR_SELECTED' &&
                    <CustomButton variant="contained"
                                  disabled={modifySubscriptionLoading || cancelSubscriptionLoading}
                                  onClick={handleModifyPlan}>
                        {t("Change Date")}
                    </CustomButton>
                }
                    <CustomButton variant="outlined"
                                  disabled={modifySubscriptionLoading || cancelSubscriptionLoading}
                                  color="error"
                                  onClick={handlePlanUnsubscription}>
                        {t("Stop Plan")}
                    </CustomButton>
                    <IconButton aria-label="edit" 
                                size='small' 
                                disabled={modifySubscriptionLoading || cancelSubscriptionLoading}
                                onClick={cancelChanges}>
                        <CloseIcon fontSize='small'/>
                    </IconButton>
                </>
            )
        }
    }
    
    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Stack direction="column" alignItems="center" spacing={1} width={'100%'} sx={{ mt: '20px' }}>
                <Skeleton variant="rectangular" width={'100%'} height={500} />
            </Stack>
        );

    } else {
        return (
          <>
            <Markdown>{description}</Markdown>

            {/* <Typography sx={{ pt: "20px" }}>Duration: {duration}</Typography> */}

            {isSmallerThan550 ? (
              <Stack direction="column" spacing={1} sx={{ pt: "20px" }}>
                {renderDateHandlers()}
                {renderPlanHandlers()}
              </Stack>
            ) : (
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={2}
                sx={{ pt: "20px", height: 60 }}
              >
                {renderDateHandlers()}
                {renderPlanHandlers()}
              </Stack>
            )}

            <Dialog open={encounteredError} onClose={closeErrorDialog}>
              <DialogTitle id="error-message-title">
                {isSubscribed
                  ? t("Unable to start plan")
                  : t("Unable to change plan start date")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="error-message-description">
                  {isSubscribed
                    ? t("An error was encountered when attempting to start a plan.  Either try again or contact support.")
                    : t("An error was encountered when attempting to change the plan start date.  Either try again or contact support.")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeErrorDialog}>{"OK"}</Button>
              </DialogActions>
            </Dialog>
          </>
        );
    }
}

const CustomButton = styled(Button)`
  white-space: nowrap;
  min-width: auto;
`;
// This button is so that the Button text does not wrap

export default PlanDetails;