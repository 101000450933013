import { useMemo, useState } from "react";
import { getCurrentUserFromSessionStorage } from 'navigator/utility';
import { AuthContext } from "./AuthContext";

//https://blog.logrocket.com/complete-guide-authentication-with-react-router-v6/

export const AuthProvider = ({ children }) => {
  const [ currentUser, setCurrentUser ] = useState(getCurrentUserFromSessionStorage());

  const value = useMemo(
    () => ({
      currentUser,
      setCurrentUser
    }),
    [currentUser]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};