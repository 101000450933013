import React, {useEffect} from "react";
import {useApolloClient, useQuery} from "@apollo/client";
import NewsItemList from "./NewsItemList";
import {Skeleton, Stack} from "@mui/material";
import {
    CURRENT_CAMBIAN_USER_NEWSFEED,
    GET_NETWORK_REQUESTS_FOR_CAMBIAN_USER,
    GET_QUESTIONNAIRE_RESPONSE_SUMMARIES,
    GET_ARTICLES,
    GET_ORGANIZATION_DETAILS_AS_SUMMARY
} from "../../../../gqlQueries";
import PendingConnectionRequest from "./PendingConnectionRequest";
import PendingQuestionnaireRequest from "./PendingQuestionnaireRequest";
import ErrorPage from "../../ErrorPage";
import PendingLinkDataRequest from "./PendingLinkDataRequest";
import {errorMessageAction} from "../../../utility";
import InProgressQuestionnaireRequest from "./InProgressQuestionnaireRequest";
import { useAuthContext } from "navigator/security";

function HomeView(props) {
    const {selectedViewCallback, updatedScrollPosition} = props;
    const {currentUser} = useAuthContext();

    const gqlClient = useApolloClient();

    let itemsPerPage = process.env.REACT_APP_HOME_PAGE_ITEMSPERPAGE;
    let sortBy = process.env.REACT_APP_HOME_PAGE_SORTBY;
    let orderBy = process.env.REACT_APP_HOME_PAGE_ORDERBY;

    const [isFetching, setIsFetching] = React.useState(true);
    const [fetchFailed, setFetchFailed] = React.useState(false);
    const [articlesFetched, setArticlesFetched] = React.useState(false);
    const [updateRequired, setUpdateRequired] = React.useState(true);
    const [newsFeed, setNewsFeed] = React.useState([]);
    const [complete, setComplete] = React.useState(false);
    const [scrollPosition, setScrollPosition] = React.useState(0);

    const {loading, error, data} = useQuery(GET_NETWORK_REQUESTS_FOR_CAMBIAN_USER, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        },
        pollInterval: process.env.REACT_APP_PENDING_REQUEST_POLLING_INTERVAL_MS
    });


    

    if (updatedScrollPosition > scrollPosition && updateRequired === false) {
        setScrollPosition(updatedScrollPosition);
        setUpdateRequired(true);
    }

    const networkDataRetrievalCallback = (newsFeedSnippet) => {
        setIsFetching(false);

        if (newsFeedSnippet.length === 0) {
            setComplete(true);
        } else {
            let newFeedArray = newsFeed.concat(newsFeedSnippet);
            newFeedArray.sort(function(a,b){
                return new Date(b.creationDate) - new Date(a.creationDate);
            });
            setNewsFeed(newFeedArray);
        }
    }


    // Called several times as workflow to acquire all data.  "updateRequired" is initialized to true
    //
    useEffect(() => {
        if (updateRequired) {
            if (!complete) {
                setUpdateRequired(false);
                fetchNewsFeedItems(gqlClient,
                                     newsFeed.length,
                                     itemsPerPage,
                                     sortBy,
                                     orderBy,
                                     networkDataRetrievalCallback);
            }
        }
        // at the end, return a useEffect cleanup method to cancel all subscriptions
        return () => { setUpdateRequired(false); };
    });

    const newsFeedComponent = () => {
        if (fetchFailed) {
            return(
                <Stack direction="column"
                       alignItems="center"
                       justifyContent="center"
                       spacing={0}
                       width={'100%'}
                       height={400}
                       sx={{border: 1, borderColor: '#D9DAD9', bgcolor: 'grey.200'}}>
                    <ErrorPage content="[unable to retrieve content]" />
                </Stack>
            );
        }

        if (isFetching) {
            return(
                <Stack direction="column" alignItems="center" spacing={0} width={'100%'} sx={{pt: '20px'}}>
                    <Skeleton variant="rectangular" width={'100%'} height={600} />
                </Stack>
            );
        }

      return(
         <div style={{paddingTop: '3px'}}>
            <NewsItemList newsfeedData={newsFeed}/>
         </div>
      );
    }

    // fetching two types, news items and assigned articles items. Assigned articles will appear at the front.
    const fetchNewsFeedItems = async(client, offset, itemsPerPage, sortBy, orderBy, callback) => {
        try{
            const [articles, newsFeed] = await Promise.all([retrieveArticlesForFeed(client), retrieveNewsfeedData(client, offset, itemsPerPage, sortBy, orderBy)])
            callback(articles.concat(newsFeed))
        }
        catch(err)  {
            console.log('Failure in retrieveNewsfeedData');
            console.log(err)
            setFetchFailed(true);
        };
    }
    
    const retrieveNewsfeedData = async(client, offset, itemsPerPage, sortBy, orderBy) => {
        try {
            const snippet = await client.query(
                {
                    query: CURRENT_CAMBIAN_USER_NEWSFEED,
                    variables: {
                        "Offset": offset,
                        "ItemsPerPage": itemsPerPage,
                        "SortBy": sortBy,
                        "OrderBy": orderBy
                    },
                    fetchPolicy: "cache-first"
                }
            )
            return snippet.data.getNewsFeed;
        }
        
        catch(err) {
            console.log('Failure in retrieveNewsfeedData');
            console.log(err)
            setFetchFailed(true);
        };
    }
    
    // for future (back-end) implementation: sort by date and load them sequentially based on offset in a similar fashion as news items
    const retrieveArticlesForFeed = async (client) => {
        // only retrieve articles once as articles cannot be fetched by offset like news items
        if (articlesFetched) {
            return [];
        }
        try {
            const articles = await client.query({
                query: GET_ARTICLES,
                variables: {
                "CambianUserId": currentUser.userReference.globalId,
                "PatientId": currentUser.patientReference.idValue
                },
                fetchPolicy: "cache-first"
            });
        
            const articleItems = [];
        
            await Promise.all(
                articles.data.getArticles.map(async (article) => {
                if (article.organizationId !== "0") {
                    const org = await client.query({
                    query: GET_ORGANIZATION_DETAILS_AS_SUMMARY,
                    variables: {
                        "OrganizationId": article.organizationId
                    },
                    fetchPolicy: "cache-first"
                    });
        
                    let newsItem = {};
                    newsItem.organizationName = org.data.getOrganizationDetail.name;
                    newsItem.creationDate = article.updatedDateTime;
                    newsItem.logo = article.thumbnail;
                    newsItem.imageSrc = null;
                    newsItem.videoSrc = "";
                    newsItem.text = "Check out this article: ";
                    newsItem.text += `<a href=${article.articleUrl}>${article.title}</a>`;
                    articleItems.push(newsItem);
                }
                })
            );
            setArticlesFetched(true);
            return articleItems;
        } catch (err) {
            console.log('Failure in retrieveArticles', err);
            return [];
        }
      };
      
    const pendingNotificationsComponent = () => {
        if (error) {
            return errorMessageAction(error.graphQLErrors);

        } else if (loading) {
            return (<></>);

        } else {

            let connectionRequestCount = 0;
            data.getNetwork.forEach((org) => {
                if (org.connectionRequest !== undefined) {
                    org.connectionRequest.forEach((request) => connectionRequestCount++);
                }
            });

            return (
                <QuestionnaireNotifications selectedViewCallback={selectedViewCallback} connectionRequestCount={connectionRequestCount} />
            );
        }
    }


    return (
            <>
                { pendingNotificationsComponent() }

                { newsFeedComponent() }
            </>
    );
}


function QuestionnaireNotifications(props) {
    const {selectedViewCallback, connectionRequestCount} = props;
    const {currentUser} = useAuthContext();

    const {loading, error, data} = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        },
        pollInterval: process.env.REACT_APP_PENDING_REQUEST_POLLING_INTERVAL_MS
    });

    if (loading === true) {
        return (<></>);

    } else if (error === true) {
        return errorMessageAction(error.graphQLErrors);

    } else {

        return (
            <Stack direction="column" alignItems="left">
                <PendingConnectionRequest selectedViewCallback={selectedViewCallback} count={connectionRequestCount} />
                <PendingQuestionnaireRequest selectedViewCallback={selectedViewCallback} summaries={data.getQuestionnaireResponseSummaries} />
                <PendingLinkDataRequest selectedViewCallback={selectedViewCallback} summaries={data.getQuestionnaireResponseSummaries} />
                <InProgressQuestionnaireRequest selectedViewCallback={selectedViewCallback} summaries={data.getQuestionnaireResponseSummaries} />
            </Stack>
        );
    }
}


export default HomeView;