import {Avatar, Box, Grid, Link, Skeleton, Stack, Typography} from "@mui/material";
import {useQuery} from "@apollo/client";
import {GET_ARTICLES} from "../../../gqlQueries";
import {errorMessageAction} from "../../utility";
import { useAuthContext } from "navigator/security";

const maximumSummaryLength = 100;

function OrganizationArticles(props) {
    const {organizationId, selectedViewCallback, selectedArticleCallback} = props;

    const {currentUser} = useAuthContext();

    const {loading, error, data} = useQuery(GET_ARTICLES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue,
            "OrganizationId": organizationId
        }
    });

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Skeleton variant="rectangular" width={'100%'} height={225} />
        );
    } else {
        return (
            <Stack spacing={2} sx={{padding: '20px'}}>
                {
                    data.getArticles.map((article, index) => {
                        return (<ArticleList key={article.articleReference.idValue}
                                             shortName={article.shortName}
                                             title={article.title}
                                             summary={article.summary}
                                             thumbnail={article.thumbnail}
                                             articleUrl={article.articleUrl}
                                             articleId={article.articleReference.globalId}
                                             selectedViewCallback={selectedViewCallback}
                                             selectedArticleCallback={selectedArticleCallback}/>)
                    })
                }
            </Stack>
        );
    }
}


function ArticleList(props) {
    const {shortName, title, summary, thumbnail, articleId, selectedViewCallback, selectedArticleCallback} = props;
    const noPointer = {cursor: 'pointer'};

    const shorten = (summary) => {
        if (summary === undefined || summary === null) {
            return ("");
        }

        if (summary.length > maximumSummaryLength) {
            return(summary.substring(0, maximumSummaryLength-4) + " ...");
        }

        return summary;
    }

    const viewArticle = (articleId) => {
        selectedArticleCallback(articleId);
        selectedViewCallback('ArticleView');
    }

    return (
        <Grid container direction="row"
              sx={{
                  '& .MuiTextField-root': {m: 1, width: '40ch'},
              }}>
            <Box component={Grid} item xs={2} display={{xs: "block"}}>
                <Avatar alt={shortName} src={thumbnail} sx={{width: 40, height: 40}}/>
            </Box>

            <Box component={Grid} item xs={10} display={{xs: "block"}}>
                {
                    (articleId === undefined || articleId === null)
                        ?
                        <Stack direction="column">
                            <Typography sx={{fontWeight: 'bold'}}>{title}</Typography>
                            <Typography variant="subtitle2">{shorten(summary)}</Typography>
                        </Stack>
                        :
                        <Stack direction="column">
                            <Link sx={{fontWeight: 'bold'}} onClick={() => viewArticle(articleId)} style={noPointer}>{title}</Link>
                            <Link variant="subtitle2" onClick={() => viewArticle(articleId)} style={noPointer}>{shorten(summary)}</Link>
                        </Stack>
                }
            </Box>
        </Grid>
    );
}

export default OrganizationArticles;