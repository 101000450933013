import {useTranslation} from "react-i18next";
import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from "@mui/material";
import ConnectedOrganization from "./ConnectedOrganization";

function SortedConnectionList(props) {
    const {selectedViewCallback, data, status, sortOrder} = props;
    const { t } = useTranslation();

    const deepCopy = (data) => {
        return JSON.parse(JSON.stringify(data));
    }

    const initialCompareFunction = (i, j) => {
        return (i.name.localeCompare(j.name, 'en', {sensitivity: 'base'}));
    }

    const initialSortData = (data) => {
        var itemsToSort = deepCopy(data);
        return itemsToSort.sort(initialCompareFunction);
    }

    const [sortedConnectionData, setSortedConnectionData] = React.useState({
                                                                            data:initialSortData(data),
                                                                            sortDirection: sortOrder
                                                                        });

    const isSelected = (name) => {
        return name === "";
    };

    const handleSort = () => {
        var sortDirection = sortedConnectionData.sortDirection;
        if (sortDirection === 'asc') {
            sortDirection = 'desc';
        } else {
            sortDirection = 'asc';
        }

        var connectionData = sortedConnectionData.data;
        setSortedConnectionData({
            data: sortData(connectionData, sortDirection),
            sortDirection: sortDirection
        });
    }


    const compareFunction = (stringOne, stringTwo) => {
        var comparison = 0;
        if (sortedConnectionData.sortDirection === "asc") {
            comparison = (stringOne.name.localeCompare(stringTwo.name, 'en', {sensitivity: 'base'}));
        } else {
            comparison = (stringTwo.name.localeCompare(stringOne.name, 'en', {sensitivity: 'base'}));
        }

        return comparison;
    }

    const sortData = (data, sortOrder) => {
        var itemsToSort = deepCopy(data);
        var sortedItems =  itemsToSort.sort(compareFunction);

        return sortedItems;
    }

    var dense = 'small';
    return (
        <div className="componentBorder">
            <TableContainer>
                <Table
                    sx={{minWidth: 200}}
                    aria-labelledby="tableTitle"
                    size={dense ? 'small' : 'medium'}>
                    <TableHeader title={status === "CONNECTED" ? t("Connections") : t("Invitations to Connect")}
                                 sortOrder={sortedConnectionData.sortDirection}
                                 requestSort={handleSort}/>
                    <TableBody>
                        {sortedConnectionData.data
                            .filter(function (connection, index) {
                                return connection.status === status;
                            })
                            .map((organization, index) => {
                                const isItemSelected = isSelected(organization.organizationId);
                                return (
                                    <TableRow key={organization.organizationId}
                                              hover={organization.status === "CONNECTED"}
                                              role="checkbox"
                                              aria-checked={isItemSelected}
                                              tabIndex={-1}
                                              selected={isItemSelected}>
                                        <TableCell align="left">
                                            <ConnectedOrganization organization={organization}
                                                                   selectedViewCallback={selectedViewCallback}
                                                                   status={organization.status} />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

function TableHeader(props) {
    const {title, sortOrder, requestSort} = props;
    return (
        <TableHead>
            <TableRow>
                <TableCell>
                    <TableSortLabel active={true}
                                    direction={sortOrder}
                                    onClick={requestSort}>
                        <Typography sx={{fontWeight: 'bold'}}>{title}</Typography>
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        </TableHead>
    );
}

export default SortedConnectionList;