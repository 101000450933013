import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "../../../gqlMutators";
import { ChangePassword } from "@cambianrepo/cambianreact";
import { useTranslation } from "react-i18next";
import background from "../../images/cloud_background.jpeg"
import { makeStyles } from "@mui/styles";
import { useSearchParams } from "react-router-dom";
import { clearStateVariables } from "../../utility";

const useStyles = makeStyles(theme => ({
    pageBackground: {
        position: 'fixed',
        left: '0px',
        right: '0px',
        backgroundImage: `url(${background})`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        height: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    }
}));

function ResetPasswordPage(props) {
    const INTERNAL_SERVER_ERROR_MSG = "An unexpected error occured. Please contact technical support.";
    const { t } = useTranslation();
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const riParameter = searchParams.get("ri");
    const [passwordChangeSuccessful, setPasswordChangeSuccessful] = React.useState(false);

    const [resetPasswordGraphQL] = useMutation(RESET_PASSWORD);

    const changePasswordCallback = async ({ newPassword }) => {
        try {
            const { data: { resetPassword: isPasswordChanged } } = await resetPasswordGraphQL({
                variables: {
                    RequestId: riParameter,
                    NewPassword: newPassword,
                },
            });
            if (isPasswordChanged) {
                setPasswordChangeSuccessful(true);
                return { success: true };
            }
            return { success: false, errorMsg: INTERNAL_SERVER_ERROR_MSG };
        } catch (error) {
            console.error("Failed to change password", error);
            console.error(error?.message);
            return { success: false, errorMsg: INTERNAL_SERVER_ERROR_MSG };
        }

    }

    const handleCloseChangeResultModal = () => {
        clearStateVariables();
        window.location.replace(process.env.REACT_APP_WEBSITE_CONTEXT);
    }

    return (
        <div id="mainContainer" className={classes.pageBackground}>
            <Grid container direction="row"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '40ch' }
                }}>

                <Box component={Grid} item xs={0} sm={2.5} md={4} display={{ xs: "hidden", md: "block" }} />
                <Box component={Grid} item xs={12} sm={7} md={4} display={{ xs: "block" }} sx={{ minWidth: '430px' }}>
                    <div className="componentBorder">
                        <Box sx={{
                            display: 'flex', alignItems: "center", justifyContent: "center",
                            '& .MuiInputLabel-root': {
                                color: 'grey'
                            }
                            }} >
                            <ChangePassword changePasswordCallback={changePasswordCallback}
                                password={{min:8}}
                                showBackButton={false} />
                        </Box>
                    </div>
                </Box>
                <Box component={Grid} item xs={0} sm={2.5} md={4} display={{ xs: "hidden", md: "block" }} />
            </Grid>
            <Dialog open={passwordChangeSuccessful}
                onClose={handleCloseChangeResultModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {t("Password Reset Successful")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("Password has been reset.  Please sign in with the new password.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained"
                        onClick={handleCloseChangeResultModal}>
                        {t("OK")}
                    </Button>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default ResetPasswordPage;