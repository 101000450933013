import {useQuery} from "@apollo/client";
import {GET_QUESTIONNAIRE_RESPONSE_PDF} from "../../../../gqlQueries";
import {useTranslation} from "react-i18next";
import {Dialog, DialogContent, DialogTitle, DialogActions, Skeleton, Stack, Button} from "@mui/material";
import React from "react";
import {errorMessageAction} from "../../../utility";


function PdfObjectViewer(props) {
    const {questionnaireResponseId, selectedViewCallback, questionnaireTitle, setOpenPdfReportViewer, setOpenHtmlReportViewer} = props;
    console.log('questionnaireResponseId '+ questionnaireResponseId)
    const { t } = useTranslation();
    const {loading, error, data} = useQuery(GET_QUESTIONNAIRE_RESPONSE_PDF, {
        variables: {
            "QuestionnaireResponseId": questionnaireResponseId
        }
    });

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Stack direction="column" alignItems="center" spacing={1} width={'100%'} sx={{mt:'25px'}}>
                <Skeleton variant="rectangular" width={'100%'} height={'100%'} />
            </Stack>
        );

    } else {

        if (data.getQuestionnaireResponsePdf.base64 === null) {
            return (<div width={'100%'} height={'100%'}>
                        <Dialog open={true}
                                width={600}
                                height={600}>
                            <DialogTitle id="error-message-title">
                                {t("Unable to find PDF for Questionnaire Response")}
                            </DialogTitle>
                            <DialogContent>
                                {t("Unable to find PDF for Questionnaire Response")}. {t("Please contact technical support.")}
                            </DialogContent>
                            <DialogActions>
                                <Button variant='contained'
                                        onClick={() => {
                                            setOpenPdfReportViewer(false);
                                            setOpenHtmlReportViewer(true);
                                            selectedViewCallback('ViewQuestionnaireResultsView');
                                        }}>
                                    {"Close"}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>);
        }
        
        let base64Data = data.getQuestionnaireResponsePdf.base64;
        let dataArray = base64Data.split(',');
        let binaryData = atob(dataArray[1]);
        if (!binaryData.includes("Title")) {
            const substring = "/Producer (www.ilovepdf.com)"
            const index = binaryData.indexOf(substring);
            if (index !== -1) {
              binaryData = binaryData.slice(0, index + substring.length) + "\n/Title " + `(${questionnaireTitle})` + binaryData.slice(index + substring.length);
            }
            const base64_withTitle = btoa(binaryData);
            dataArray[1] = base64_withTitle;
            base64Data = dataArray.join();
        }
        
        return(  <object type="application/pdf"
                         title="PDF Viewer"
                         width="100%"
                         height="715vh"
                         data={base64Data}/>
               );
    }
}

export default PdfObjectViewer;