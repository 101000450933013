import { PublicDesktopToolbar } from "../header/toolbar/NavigatorToolbar";
import { Notification } from "../header/Notification";
import { Box, useMediaQuery } from "@mui/material";
import PublicFooter from "../footer/PublicFooter";
import background from '../images/cloud_background.jpeg';
import { makeStyles } from "@mui/styles";
import { SignInFlow } from "@cambianrepo/cambianreact";
import { useAuthContext, useAuthService } from "navigator/security";
import { useNavigate, Navigate } from "react-router-dom";

/*
^ - matches the start of the string
[a-zA-Z0-9]+ - matches one or more consecutive characters that can be letters or digits
@mega- - matches the literal string "@mega-"
[a-zA-Z]{1,4} - matches one to four consecutive letters (upper or lowercase)
\.cambian\.net - matches the literal string ".cambian.net"
$ - matches the end of the string
*/
const MEGA_EMAIL_REGEX = /^[a-zA-Z0-9]+@mega-[a-zA-Z]{1,4}\.cambian\.net$/;

const useStyles = (isSmallHeight) => makeStyles(theme => ({
  pageBackground: {
    paddingBottom: isSmallHeight ? 0 : '66px', // Adjust for footer
    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh', // Adjust for when headers are gone.
      paddingBottom: 0
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100vh - 66px)`, // Adjust for when headers get smaller
    },
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - 75px)`, // header is 75px which was computed using chrome inspect tool
    },
    width: '100vw',
    background: `url(${background}) center center/cover no-repeat`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
}));

function SignInPage() {
  const isSmallHeight = useMediaQuery("(max-height: 700px)");
  const classes = useStyles(isSmallHeight)();
  const { currentUser } = useAuthContext();

  if (currentUser) {
    return <Navigate to="/" />
  }

  return (
    <Box className={classes.wrapper}>
      <PublicDesktopToolbar />
      <Box id="mainContainer" className={classes.pageBackground}>
        {/* Uncomment to display notification */}
        {/* <Notification/> */}
        <SignInFlowComponent />
      </Box>
      {!isSmallHeight && <PublicFooter />}
    </Box>
  );
}

function SignInFlowComponent() {
  const { signIn, checkEmail, callResetPasswordRequest, callCreateUser } = useAuthService();

  const navigate = useNavigate();
  const signInCallback = async ({ username, password }) => {
    const result = await signIn(username, password);
    if (result.success) {
      navigate("/");
    }
    return result;
  };

  const signUpCallback = async ({
    username,
    password,
    firstName,
    lastName,
  }) => {
    if (MEGA_EMAIL_REGEX.test(username)) {
      //skip email verification and create user right away
      const data = await callCreateUser({ username, password, firstName, lastName, verificationCode: undefined, isVerificationDisabled: true });
      if (data.success) return {success:null,errorMsg:" "};
      return {success:null,errorMsg:null};
    } else {
      return await checkEmail(username);
    }
  }

  const forgotPasswordCallback = async ({ username }) => { return await callResetPasswordRequest(username); }

  const verifyRegistrationCallback = async ({
    username,
    password,
    firstName,
    lastName,
    verificationCode,
  }) => {
    if (verificationCode !== null) {
      verificationCode = verificationCode.trim();
    }
    const data = await callCreateUser({ username, password, firstName, lastName, verificationCode });
    if (data.success) {
      // make success null so that cambianreact component does not redirect back to sign in page
      return {success:null,errorMsg:" "};
    }
      
    return data;
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box className="componentBorder" sx={{
        '& .MuiInputLabel-root': {
          color: 'grey'
        },
      }}>
        <SignInFlow
          signInProps={{ signInCallback }}
          signUpProps={{ signUpCallback }}
          forgotPasswordProps={{ forgotPasswordCallback }}
          verifyRegistrationProps={{ verifyRegistrationCallback }} />
      </Box>
    </Box>
  );
}


export default SignInPage;