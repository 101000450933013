import OrganizationAd from "./OrganizationAd";
import {Stack} from "@mui/material";
import OrganizationArticles from "./OrganizationArticles";
import React from "react";

function OrganizationEducation(props) {
    const {organizationId, selectedViewCallback, selectedArticleCallback} = props;
    return(
        <Stack direction="column" sx={{m:'10px', pt: '10px'}} spacing={2.5}>
            <OrganizationAd organizationId={organizationId}
                            selectedViewCallback={selectedViewCallback}
                            selectedArticleCallback={selectedArticleCallback}/>

            <OrganizationArticles organizationId={organizationId}
                                  selectedViewCallback={selectedViewCallback}
                                  selectedArticleCallback={selectedArticleCallback}/>
        </Stack>
    );
}

export default OrganizationEducation;