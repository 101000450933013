import React from "react";
import {useQuery} from "@apollo/client";
import {Button, Skeleton} from "@mui/material";
import {GET_ARTICLE} from "../../../../gqlQueries";
import {
    errorMessageAction,
    getPreviousSelectedView,
    sanitizeHtmlText
} from "../../../utility";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useParams } from "react-router-dom";


function ViewArticle(props) {
    // useParams handles redirect via url case
    let {articleId} = useParams();
    const {selectedViewCallback } = props;
    // if not redirected by url, the selectedView was changed so ID accessible by props
    if (!articleId) {
        articleId = props.articleId;
    }
    const [currentArticleId, setCurrentArticleId] = React.useState();

    console.log(articleId)
    console.log(currentArticleId)
    if (articleId !== currentArticleId) {
        setCurrentArticleId(articleId);
    }

    const {loading, error, data } = useQuery(GET_ARTICLE, {
        variables: {
            "ArticleId": articleId
        }
    });

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return(
            <Skeleton variant="rectangle" height={700} sx={{mt: '25px'}}/>
        );

    } else {
        return (
            <>
                <div className="componentBorder"
                     dangerouslySetInnerHTML={{__html: sanitizeHtmlText(data.getArticle.body)}}/>
                {
                    selectedViewCallback
                    ?
                    <Button onClick={() => selectedViewCallback(getPreviousSelectedView())}
                        variant="outlined"
                        startIcon={<ArrowBackIosIcon />} />
                    :
                    <></>
                }
                
            </>
        );
    }
}


export default ViewArticle;