import React, { useLayoutEffect } from "react";
import {Route, Routes, useLocation} from "react-router-dom";
import BloodPressurePage from "./pages/bloodPressure/BloodPressurePage";
import EditUserProfile from "./pages/editUserProfile/EditUserProfile";
import Splash from "./pages/Splash";
import {Paper, Grid} from "@mui/material";
import ProtectedFooter from "./footer/ProtectedFooter";
import {CambianTheme} from "./CambianTheme";
import {ProtectedToolbar} from "./header/toolbar/NavigatorToolbar";
import MainPage from "./pages/main/MainPage";
import {setSelectedView} from "./utility";
import ChangePasswordPage from "./pages/changePassword/ChangePasswordPage";
import { useTheme } from "@mui/styles";
import {useMediaQuery} from "@mui/material";
import ViewArticle from "./pages/main/viewArticle/ViewArticle";

function Navigator() {
    const [currentView, setCurrentView] = React.useState();
    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    const smallToMid = useMediaQuery(theme.breakpoints.between("sm", "md"));

    const selectedViewCallback = (selectedView) => {
        console.log('selectedViewCallback : ' + selectedView)
        setSelectedView(selectedView);
        if (selectedView !== currentView) {
            console.log('Current View: ' + currentView + '   Setting Current View to : ' + selectedView)
            setCurrentView(selectedView);
        }
    }

    const Wrapper = ({children}) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
    }

    return (
        <div id="mainContainer">

            <ProtectedToolbar selectedViewCallback={selectedViewCallback}/>

            <Paper component={Grid} 
                    bottom={{xs: '0px', md: '65px'}}
                    style={{
                            position: 'fixed',
                            top: (greaterThanMid) ? '74px' : ((smallToMid) ? '64px' : '61px'),
                            left: '0px',
                            right: '0px',
                            backgroundColor: CambianTheme.palette.background.default
                         }} square>
                <Wrapper>
                    <Routes>
                        {/* These are routes that require an authenticated user */}
                        {/**/}
                        <Route exact path="/" element={<MainPage selectedViewCallback={selectedViewCallback} />} />
                        <Route exact path='/blood-pressure' element={<BloodPressurePage />}/>
                        <Route exact path='/edit-user-profile' element={<EditUserProfile />}/>
                        <Route exact path='/change-password' element={<ChangePasswordPage/>}/>
                        <Route exact path='/article/:articleId' element={<ViewArticle/>}
                        />
                        {/* This is the 'catch all' route if there have been no matches on a specific route */}
                        {/**/}
                        <Route path="*" element={<Splash />}/>
                    </Routes>
                </Wrapper>
            </Paper>

            <ProtectedFooter selectedViewCallback={selectedViewCallback}/>
        </div>
    );
}


export default Navigator;