import {useQuery} from "@apollo/client";
import {useTranslation} from "react-i18next";
import { Skeleton, Stack, Typography } from "@mui/material";
import IncompleteSelfServeQuestionnaireListContent from "./IncompleteSelfServeQuestionnaireListContent";
import FinalizedQuestionnaireListContent from "./FinalizedQuestionnaireListContent";
import InvitationToShareQuestionnaireListContent from "./InvitationToShareQuestionnaireListContent";
import {GET_QUESTIONNAIRE_RESPONSE_SUMMARIES} from "../../../../gqlQueries";
import {errorMessageAction} from "../../../utility";
import PinLinkToQuestionnaireListContent from "./PinLinkToQuestionnaireListContent";
import { useAuthContext } from "navigator/security";

function QuestionnaireList(props) {
    const {selectedViewCallback} = props;
    const { t } = useTranslation();
    const {currentUser} = useAuthContext();

    const {loading, error, data} = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });


    if (currentUser.patientReference === undefined) {
        return (
            <EmptyQuestionnaireListContent />
        );
    }

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Stack direction="column" alignItems="center" spacing={1} width={'100%'} sx={{mt:'20px'}}>
                <Skeleton variant="rectangular" width={'100%'} height={100} />
                <Skeleton variant="rectangular" width={'100%'} height={100} />
                <Skeleton variant="rectangular" width={'100%'} height={250} />
            </Stack>
        );

    } else {
        const invitationToShareQuestionnaireList = data.getQuestionnaireResponseSummaries
            .filter(function (responseSummary, index) {
                return ((responseSummary.task !== null) &&
                        (responseSummary.state === 'PENDING' ||
                          (responseSummary.state === 'INCOMPLETE' && responseSummary.initiatingOrganizationId !== null)))
            });

        const incompleteSelfQuestionnaireList = data.getQuestionnaireResponseSummaries
            .filter(function (responseSummary, index) {
                return (responseSummary.task === null && responseSummary.state === 'INCOMPLETE')
            });

        const finalizedQuestionnaireList = data.getQuestionnaireResponseSummaries
            .filter(function (responseSummary, index) {
                return responseSummary.state === 'FINALIZED';
            });

        const pendingLinkQuestionnaireList = data.getQuestionnaireResponseSummaries
            .filter(function (responseSummary, index) {
                return responseSummary.state === 'LINK-PENDING';
            });

        return (
            <>
                {
                    pendingLinkQuestionnaireList.length > 0 &&
                        <PinLinkToQuestionnaireListContent pendingLinkQuestionnaireList={pendingLinkQuestionnaireList} />
                }
                {
                    invitationToShareQuestionnaireList.length > 0 && 
                        <InvitationToShareQuestionnaireListContent selectedViewCallback={selectedViewCallback}
                                                                    invitationToShareQuestionnaireList={invitationToShareQuestionnaireList} />
                }
                {
                    incompleteSelfQuestionnaireList.length > 0 &&
                        <IncompleteSelfServeQuestionnaireListContent selectedViewCallback={selectedViewCallback}
                                                                    incompleteSelfQuestionnaireList={incompleteSelfQuestionnaireList}/>
                }
                {
                    finalizedQuestionnaireList.length > 0 &&
                        <FinalizedQuestionnaireListContent selectedViewCallback={selectedViewCallback}
                                                            finalizedQuestionnaireList={finalizedQuestionnaireList} />
                }
                {
                    finalizedQuestionnaireList.length === 0 &&      
                        <Typography sx={{fontStyle: 'italic', pt: '25px'}}>
                            {t("You currently do not have any completed questionnaires. Once you complete one, it will show up here.")}
                        </Typography>
                }
            </>
        );
    }
}


function EmptyQuestionnaireListContent(props) {
    return (
        <div>TBD : return empty table</div>
    );
}

export default QuestionnaireList;