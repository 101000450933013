import {Button, Stack, Typography} from "@mui/material";
import React from "react";

const styleDef =  {
    display: 'block',
    backgroundColor: '#d9edf7',
    color: '#3a87ad',
    width: '100%'
}

function PendingConnectionRequest(props) {
    const {selectedViewCallback, count} = props;

    if (count === 0) {
        return( <></>);
    }

    let subject = " pending invitation" + (count > 1 ? "s" : "");

    return (
        <Stack direction="column" alignItems="left" sx={{mt: '20px'}}>
            <Button underline="none"
                    sx={styleDef}
                    onClick={() => selectedViewCallback("NetworkConnectionView")}>
                <Typography variant="subtitle1">
                    You have <b>{count} {subject}</b> to <b>{"connect"}</b>
                </Typography>
            </Button>
        </Stack>
    );
}

export default PendingConnectionRequest;