function retrieveQuestionnaireById(accessToken, id) {
    const bearerToken = "Bearer " + accessToken;
    let fetchPromise = fetch(process.env.REACT_APP_GATEWAY_HOST + '/fhir/questionnaire/' + id, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': bearerToken
        },
        method: 'GET'
    });
    return fetchPromise;
}

function retrieveQuestionnaireResponseById(accessToken, id) {
    const bearerToken = "Bearer " + accessToken;
    let fetchPromise = fetch(process.env.REACT_APP_GATEWAY_HOST + '/fhir/fhirQuestionnaireResponse/' + id, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': bearerToken
        },
        method: 'GET'
    });

    return fetchPromise;
}

function createFhirPatient(navigatorUser) {
    let fhirPatient = {
        resourceType: "Patient",
        id: navigatorUser.patientReference.idValue,
        name: [
            {
                family: navigatorUser.lastName,
                given: [
                    navigatorUser.firstName
                ]
            }
        ],
        gender: navigatorUser.gender,
        birthDate: navigatorUser.birthDate
    }

    return fhirPatient;
}

function createFhirParameterByOrganizationId(organizationId, patient, questionnaireResponse) {
    let parameters = {
        resourceType: "Parameters",
        parameter: [
            {
                name: "organization",
                valueString: organizationId
            },
            {
                name: "patient",
                resource: patient
            },
            {
                name: "questionnaireResponse",
                resource: questionnaireResponse
            }
        ]
    };

    return parameters;
}

function saveQuestionnaireResponse(accessToken, isUpdate, isComplete, orgId, fhirPatient, fhirQuestionnaireResponse, taskId, taskStatus) {
    console.log(taskId)
    console.log(taskStatus)
    console.log(isUpdate)
    let qrMethod;
    let qrPersistPayload;
    let qrPersistUrl;

    if (isUpdate) {
        qrMethod = 'PUT';
        qrPersistPayload = fhirQuestionnaireResponse;
        qrPersistUrl = process.env.REACT_APP_GATEWAY_HOST + '/fhir/questionnaireresponse/update/' + fhirQuestionnaireResponse.id + "?finalize=" + isComplete;
        console.log(qrPersistUrl);
    } else {
        qrMethod = 'POST';
        let setTaskStateParameter = '';
        if (taskStatus !== null && taskId !== null) {
            setTaskStateParameter = '?taskStatus=' + taskStatus + '&taskId=' + taskId;
        }
        qrPersistUrl = process.env.REACT_APP_GATEWAY_HOST + '/fhir/questionnaireresponse/$submit' + setTaskStateParameter;
        let organizationId = orgId;
        if (organizationId !== undefined) {
            qrPersistPayload = createFhirParameterByOrganizationId(organizationId, fhirPatient, fhirQuestionnaireResponse);
        } else {
            qrPersistPayload = createFhirParameterByOrganizationId("e46ddab2-284b-47d6-91af-f03e6775183a", fhirPatient, fhirQuestionnaireResponse);
        }
    }

    console.log(qrPersistUrl);
    const bearerToken = "Bearer " + accessToken;
    let fetchPromise = fetch(qrPersistUrl, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': bearerToken
        },
        method: qrMethod,
        body: JSON.stringify(qrPersistPayload)
    });


    return fetchPromise;
}

function startQuestionnaire(accessToken,  orgId, fhirPatient, questionnaireId, taskId, taskStatus) {
    let d = new Date();
    let fhirQuestionnaireResponse = {
        resourceType :"QuestionnaireResponse",
        questionnaire: questionnaireId,
        status: "in-progress",
        authored: d.toJSON(),
        item: []
    };

    let savePromise = saveQuestionnaireResponse(accessToken, false, false, orgId, fhirPatient, fhirQuestionnaireResponse, taskId, taskStatus);

    return savePromise;
}

export {retrieveQuestionnaireById, retrieveQuestionnaireResponseById, startQuestionnaire, createFhirPatient, createFhirParameterByOrganizationId}