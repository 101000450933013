import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import {
    clearSelectedQuestionnaireId,
    extractDate,
    newQuestionnaireDateFormat,
    setSelectedOrganizationId,
    setSelectedQuestionnaireAssignmentRequestId,
    setSelectedQuestionnaireId,
    setSelectedQuestionnaireResponseId,
    setSelectedView
} from "../../../utility";
import {useTranslation} from "react-i18next";
import {useMutation, useQuery} from "@apollo/client";
import {IGNORE_QUESTIONNAIRE_REQUEST, START_ASSIGNED_QUESTIONNAIRE_REQUEST} from "../../../../gqlMutators";
import {CambianTheme} from "../../../CambianTheme";
import {GET_QUESTIONNAIRE_RESPONSE_SUMMARIES} from "../../../../gqlQueries";
import Loader from "../../../Loader";
import { useAuthContext } from "navigator/security";

function InvitationToShareQuestionnaireListContent(props) {
    const {selectedViewCallback, invitationToShareQuestionnaireList} = props;
    const { t } = useTranslation();
    const { currentUser } = useAuthContext();

    const [ignoreQuestionnaireRequestGraphQL] = useMutation(IGNORE_QUESTIONNAIRE_REQUEST);
    const [startQuestionnaireRequestGraphQL] = useMutation(START_ASSIGNED_QUESTIONNAIRE_REQUEST);
    const [questionnaireOperationPending, setQuestionnaireOperationPending] = React.useState(false);
    const [ignoreQuestionnaireConfirmationDialog, setIgnoreQuestionnaireConfirmationDialog] = React.useState(false);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = React.useState({});
    const colors = CambianTheme.palette.primary;

    var dense = 'small';

    const {refetch} = useQuery(GET_QUESTIONNAIRE_RESPONSE_SUMMARIES, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });

    const ignoreAssignedQuestionnaire = () => {
        if (selectedQuestionnaire !== undefined && selectedQuestionnaire !== null && selectedQuestionnaire.task.taskId !== null && "AnswerQuestionnaireRequest" === selectedQuestionnaire.task.taskType)
        {
            let taskId = selectedQuestionnaire.task.taskId;

            ignoreQuestionnaireRequestGraphQL({
                variables: {
                    "TaskId": taskId
                }
            }).then(result => {
                setSelectedView('QuestionnaireListView');
                setIgnoreQuestionnaireConfirmationDialog(false);
                refetch().then(() => setQuestionnaireOperationPending(false));

            }).catch(error => {
                console.log(error); // TODO - need to get a error dialog up
                setIgnoreQuestionnaireConfirmationDialog(false);
                setQuestionnaireOperationPending(false);
            });
        }

    }

    const answerAssignedQuestionnaire = (organizationId, questionnareId, questionnaireResponseId, task, requestId) => {
        console.log(requestId);
        setQuestionnaireOperationPending(true);
        if (questionnaireResponseId !== null) {
            continueQuestionnaire(organizationId, questionnaireResponseId, task, requestId);

        } else if (task !== undefined && task !== null && task.taskId !== null && "AnswerQuestionnaireRequest" === task.taskType) {
            let taskId = task.taskId;

            console.log("startQuestionnaireRequestGraphQL - organization: " + organizationId + " questionnaireId: " + questionnaireResponseId + " taskId: " + taskId);

            startQuestionnaireRequestGraphQL({
                variables: {
                    "OrganizationId": organizationId,
                    "QuestionnaireId": questionnareId,
                    "TaskId": taskId
                }
            }).then(result => {
                console.log(result);
                questionnaireResponseId = result.data.startQuestionnaire;
                continueQuestionnaire(organizationId, questionnaireResponseId, task, requestId);

            }).catch(error => {
                console.log(error); // TODO - need to get a error dialog up
                setIgnoreQuestionnaireConfirmationDialog(false);
                setQuestionnaireOperationPending(false);
            });

        } else {
            answerNewQuestionnaire(organizationId, questionnareId);
        }
    }

    const answerNewQuestionnaire = (organizationId, questionnareId) => {
        console.log('answerNewQuestionnaire ' + organizationId + ':' + questionnareId)
        setSelectedOrganizationId(organizationId);
        setSelectedQuestionnaireId(questionnareId);
        selectedViewCallback('ContinueQuestionnaireView');
    }

    const continueQuestionnaire = (organizationId, questionnaireResponseId, task, requestId) => {
        console.log('continue questionnaire response: ' + questionnaireResponseId)
        clearSelectedQuestionnaireId();
        setSelectedOrganizationId(organizationId);
        setSelectedQuestionnaireResponseId(questionnaireResponseId);
        setSelectedQuestionnaireAssignmentRequestId(requestId);
        selectedViewCallback('ContinueQuestionnaireView');
    }


    const buildQuestionnaireDetails = (responseSummary) => {
        if (responseSummary.questionnaireResponseId === null) {
            return(
                <Button onClick={() => {answerAssignedQuestionnaire(responseSummary.initiatingOrganizationId,
                                                                    responseSummary.questionnaireId,
                                                                    responseSummary.questionnaireResponseId,
                                                                    responseSummary.task,
                                                                    responseSummary.requestId)}}
                        sx={{p: '0px', justifyContent: 'left'}}>
                    <Typography variant="subtitle1" sx={{textAlign: 'left'}}>
                        {responseSummary.name}
                    </Typography>
                </Button>
            );
        }

        return(
            <Button onClick={() => continueQuestionnaire(responseSummary.initiatingOrganizationId, responseSummary.questionnaireResponseId, responseSummary.task, responseSummary.requestId) }
                    sx={{p: '0px', justifyContent: 'left'}}>
                <Typography variant="subtitle1" sx={{textAlign: 'left'}}>
                    {responseSummary.name}
                </Typography>
            </Button>
        );
    }

    const renderTargetDate = (responseSummary) => {
        if (responseSummary.targetDateTime == null) {
            return( <></>);
        }

        return(
            <Typography variant="subtitle2">{t("Complete by:")} {extractDate(responseSummary.targetDateTime)}</Typography>
        );
    }

    if (questionnaireOperationPending === true) {
        return(
           <Loader active={true}/>
        );
    }

    return (
        <div className="componentBorder">
            <Box>
                <Typography variant="subtitle1"
                            sx={{fontWeight: 'bold'}}>
                    {t("Invitations to Send")} ({invitationToShareQuestionnaireList.length})
                </Typography>

                {/* Styled for large screens */}
                <TableContainer component={Grid} 
                                display={{xs: 'none', md: 'block'}}>
                    <Table sx={{minWidth: 200}}
                           aria-labelledby="tableTitle"
                           size={dense ? 'small' : 'medium'}>
                        <colgroup>
                            <col width="40%" />
                            <col width="50%" />
                            <col width="5%" />
                            <col width="5%" />
                        </colgroup>
                        <TableBody>
                            {invitationToShareQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.requestId}
                                              hover>

                                        <TableCell align="left">
                                            <Stack direction="column" alignItems="left">
                                                <Typography variant="subtitle1">
                                                    { responseSummary.initiatingOrganizationName }
                                                </Typography>
                                            </Stack>
                                        </TableCell>

                                        <TableCell align="left" size="small">
                                            <Typography variant="subtitle1">
                                                {buildQuestionnaireDetails(responseSummary)}
                                            </Typography>
                                            {newQuestionnaireDateFormat(responseSummary.createDateTime)}
                                            { renderTargetDate(responseSummary) }
                                        </TableCell>

                                        <TableCell align="center">
                                            <Button
                                                    onClick={() => {
                                                        setSelectedQuestionnaire(responseSummary);
                                                        setIgnoreQuestionnaireConfirmationDialog(true)
                                                    }}>
                                                {t("Ignore")}
                                            </Button>

                                        </TableCell>

                                        <TableCell align="center">
                                            <Button variant="contained" sx={{backgroundColor: responseSummary.questionnaireResponseId === null ? colors.main : colors.continueOrange.main,
                                                                            '&:hover': {
                                                                                backgroundColor: responseSummary.questionnaireResponseId === null ? colors.hover : colors.continueOrange.hover}}}
                                                    onClick={() => {
                                                        answerAssignedQuestionnaire(responseSummary.initiatingOrganizationId,
                                                                                    responseSummary.questionnaireId,
                                                                                    responseSummary.questionnaireResponseId,
                                                                                    responseSummary.task,
                                                                                    responseSummary.requestId)
                                                    }}>
                                                { t(responseSummary.questionnaireResponseId === null ? "Start" : "Continue") }
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Styled for screens from sm to md*/}
                <TableContainer component={Grid} 
                                display={{xs: 'none', sm: 'block', md: 'none'}}>
                    <Table sx={{minWidth: 200}}
                           aria-labelledby="tableTitle"
                           size={dense ? 'small' : 'medium'}>
                        <colgroup>
                            <col width="60%" />
                            <col width="20%" />
                            <col width="20%" />
                        </colgroup>
                        <TableBody>
                            {invitationToShareQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.requestId}
                                              hover>

                                        <TableCell align="left" size="small">
                                            <Typography variant="subtitle1">
                                                {buildQuestionnaireDetails(responseSummary)}
                                            </Typography>
                                            From { responseSummary.initiatingOrganizationName } <br/>
                                            {newQuestionnaireDateFormat(responseSummary.createDateTime)}
                                            { renderTargetDate(responseSummary) }
                                        </TableCell>

                                        <TableCell align="center">
                                            <Button variant="contained" sx={{backgroundColor: responseSummary.questionnaireResponseId === null ? colors.main : colors.continueOrange.main,
                                                                            '&:hover': {
                                                                                backgroundColor: responseSummary.questionnaireResponseId === null ? colors.hover : colors.continueOrange.hover}}}
                                                    onClick={() => {
                                                        answerAssignedQuestionnaire(responseSummary.initiatingOrganizationId,
                                                                                    responseSummary.questionnaireId,
                                                                                    responseSummary.questionnaireResponseId,
                                                                                    responseSummary.task,
                                                                                    responseSummary.requestId)
                                                    }}>
                                                { t(responseSummary.questionnaireResponseId === null ? "Start" : "Continue") }
                                            </Button>
                                        </TableCell>

                                        <TableCell align="center">
                                            <Button
                                                    onClick={() => {
                                                        setSelectedQuestionnaire(responseSummary);
                                                        setIgnoreQuestionnaireConfirmationDialog(true)
                                                    }}>
                                                {t("Ignore")}
                                            </Button>
                                        </TableCell>

                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>


                {/* Styled for screens from xs(0) to sm */}
                <TableContainer component={Grid} 
                                display={{xs: 'block', sm: 'none'}}>
                    <Table sx={{minWidth: 200}}
                           aria-labelledby="tableTitle"
                           size={dense ? 'small' : 'medium'}>
                        <TableBody>
                            {invitationToShareQuestionnaireList.map((responseSummary, index) => {
                                return (
                                    <TableRow key={responseSummary.requestId}
                                              hover>

                                        <TableCell align="left" size="small">
                                            <Typography variant="subtitle1">
                                                {buildQuestionnaireDetails(responseSummary)}
                                            </Typography>
                                            From { responseSummary.initiatingOrganizationName } <br/>
                                            {newQuestionnaireDateFormat(responseSummary.createDateTime)}
                                            { renderTargetDate(responseSummary) }
                                            <br/>
                                            <Button variant="contained" sx={{marginTop: 1, backgroundColor: responseSummary.questionnaireResponseId === null ? colors.main : colors.continueOrange.main,
                                                                            '&:hover': {
                                                                                backgroundColor: responseSummary.questionnaireResponseId === null ? colors.hover : colors.continueOrange.hover}}}
                                                    onClick={() => {
                                                        answerAssignedQuestionnaire(responseSummary.initiatingOrganizationId,
                                                                                    responseSummary.questionnaireId,
                                                                                    responseSummary.questionnaireResponseId,
                                                                                    responseSummary.task,
                                                                                    responseSummary.requestId)
                                                    }}>
                                                { t(responseSummary.questionnaireResponseId === null ? "Start" : "Continue") }
                                            </Button>
                                            <Button sx={{marginTop: 1, marginLeft: 1}}
                                                    onClick={() => {
                                                        setSelectedQuestionnaire(responseSummary);
                                                        setIgnoreQuestionnaireConfirmationDialog(true)
                                                    }}>
                                                {t("Ignore")}
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Dialog open={ignoreQuestionnaireConfirmationDialog}
                    onClose={() => setIgnoreQuestionnaireConfirmationDialog(false)}>
                <DialogTitle id="alert-dialog-title">
                    {t("Ignore Invitation to Send?")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t("This invitation will be permanently removed.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined"
                            onClick={() => {
                                setIgnoreQuestionnaireConfirmationDialog(false)
                            }}>
                        {t("No")}
                    </Button>
                    <Button variant="contained"
                            onClick={() => {
                                setQuestionnaireOperationPending(true);
                                ignoreAssignedQuestionnaire();
                            }}>
                        {t("Ignore")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default InvitationToShareQuestionnaireListContent;