import React, { useEffect} from "react";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    Skeleton,
    Tab,
    Tabs,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";
import {useMutation, useQuery} from "@apollo/client";
import {CURRENT_CAMBIAN_USER, CURRENT_CAMBIAN_USER_FULL_DETAILS} from '../../../gqlQueries';
import {CHANGE_USER_PROFILE} from "../../../gqlMutators";
import NamePanel from "./NamePanel";
import DemographicsPanel from "./DemographicsPanel";
import ContactPanel from "./ContactPanel";
import AddressPanel from "./AddressPanel";
import IdentificationPanel from "./IdentificationPanel";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../security";
import {clearStateVariables, setCurrentUserInSessionStorage} from "../../utility";

const useStyles = makeStyles(theme => ({
    editUserProfilePage: {
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden'
    },
}));


function EditUserProfile() {
    const classes = useStyles();
    const {loading, error, data} = useQuery(CURRENT_CAMBIAN_USER_FULL_DETAILS);

    clearStateVariables();

    if (loading || error) {
        return (
            <div className={classes.editUserProfilePage}>
                <Grid container direction="row" className={classes.main} sx={{ justifyContent: 'center' }}>
                    <Box component={Grid} item xs={12} sm={6} md={6} display={{ xs: "block" }}>
                        <Skeleton variant="rounded" height={500} sx={{mt: '25px', width: '100%', marginLeft: 3, marginRight: 3}}/>
                    </Box>
                </Grid>
            </div>
        );

    } else {
        return (
            <div className={classes.editUserProfilePage}>
                <Grid container direction="row" className={classes.main} sx={{ justifyContent: 'center' }}>
                    <Box component={Grid} item xs={12} sm={10} md={9} display={{ xs: "block" }}>
                        <BasicTabs userDetails={data.currentCambianUser} />
                    </Box>
                </Grid>
            </div>
        );
    }
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}>
            {value === index && (
                <Box sx={{p: 3}}>
                    {/*<Typography>{children}x</Typography>*/}
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

function createUserProfileInput(userDetails) {
    let initialUserDetails = {
        ...userDetails,
        userEmailList: [],
        userPhoneList: [],
        primaryEmail: '',
        primaryPhone: ''
    }

    userDetails.userIdentity.forEach(item => {
        if (item.type === 'EMAIL') {
            initialUserDetails.userEmailList.push(item.value);
            if (item.primary) {
                initialUserDetails.primaryEmail = item.value;
            }
        } else if (item.type === 'CELL_PHONE') {
            initialUserDetails.userPhoneList.push(item.value);
            if (item.primary) {
                initialUserDetails.primaryPhone = item.value;
            }
        }
    });

    delete initialUserDetails['__typename'];
    delete initialUserDetails['patientReference'];
    delete initialUserDetails['userIdentity'];
    delete initialUserDetails['userReference'];
    return initialUserDetails;
}

const today = new Date(Date.now());

function getBirthMonth(date) {
    return date.getMonth() + 1
}

function getBirthDay(date) {
    return date.getDate()
}

function getBirthYear(date) {
    return date.getFullYear()
}

const ButtonSection = (props) => {
    const { t } = useTranslation();
    const {firstValid, lastValid, isUpdated, isInvalidDateOfBirth, saveUserProfile} = props;
    if (!firstValid) {
        return (
            <Alert severity="error">
                <AlertTitle>Data Entry Error</AlertTitle>
                {t("First Name is a required field.")}
            </Alert>
        );
    }

    if (!lastValid) {
        return (
            <Alert severity="error">
                <AlertTitle>Data Entry Error</AlertTitle>
                {t("Last Name is a required field.")}
            </Alert>
        );
    }

    if (isInvalidDateOfBirth) {
        return (
            <Alert severity="error">
                <AlertTitle>Invalid Date of Birth</AlertTitle>
                {t("Please enter a valid Date of Birth.")}
            </Alert>
        );
    }
    
    return (
        <Button sx={{marginLeft:3}} disabled={!isUpdated} variant="contained" onClick={() => saveUserProfile()}>
            Save
        </Button>
    );
}

function BasicTabs(props) {
    const { t } = useTranslation();
    const {setCurrentUser} = useAuthContext();

    const [isProfileDataChanged, setProfileDataChanged] = React.useState(false);
    const [profileChangeSuccessful, setProfileChangeSuccessful] = React.useState();
    const [emailInUse, setEmailInUse] = React.useState();
    const [isComplete, setIsComplete] = React.useState();
    const [value, setValue] = React.useState(0);
    const [isUpdated, setIsUpdated] = React.useState(false);
    const [userDetails, setUserDetails] = React.useState(createUserProfileInput(props.userDetails));
    const [unsavedProfileData, setUnsavedProfileData] = React.useState(createUserProfileInput(props.userDetails));
    const [isFirstNameValid, setIsFirstNameValid] = React.useState(userDetails.firstName && userDetails.firstName.length > 0 &&
        userDetails.lastName !== null);
    const [isLastNameValid, setIsLastNameValid] = React.useState(userDetails.lastName &&
        userDetails.lastName.length > 0);
    const [saveUserProfileFunction] = useMutation(CHANGE_USER_PROFILE, {
        variables: {
            "editCambianUser": userDetails
        }
    });
    const { refetch } = useQuery(CURRENT_CAMBIAN_USER);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    useEffect(() => {
        saveUserProfile();
    }, [userDetails]);
    // these changes are saved immediately without having to click save
    const updateContactDataCallback = (type, operation, key, value) => {
        if (type === 'property') {
            if (key === 'primaryEmail') {
                setUserDetails(prevState => ({
                    ...prevState,
                    [key]: value,
                    'email': value
                }));
            }
            else {
                setUserDetails(prevState => ({
                        ...prevState,
                        [key]: value
                    }));
            }
        } else if (type === 'emailList') {
            if (operation === 'add') {
                // if user does not have any emails yet this will be set as their email in patientdb
                if (userDetails.userEmailList.length === 0) {
                    setUserDetails(prevState => ({
                        ...prevState,
                        [key]: value
                    }));
                }
                if (userDetails.userEmailList.indexOf(value) === -1) {
                    userDetails.userEmailList.push(value);
                }
            } else if (operation === 'delete') {
                let idx = userDetails.userEmailList.indexOf(value);
                if (idx !== -1) {
                    userDetails.userEmailList.splice(idx, 1);
                    setProfileDataChanged(true);
                }
            }
        } else if (type === 'phoneList') {
            if (operation === 'add') {
                // if user does not have any phone numbers yet this will be set as their phone in patientdb
                if (userDetails.userPhoneList.length === 0) {
                    setUserDetails(prevState => ({
                        ...prevState,
                        [key]: value
                    }));
                }
                if (userDetails.userPhoneList.indexOf(value) === -1) {
                    userDetails.userPhoneList.push(value);
                }
            } else if (operation === 'delete') {
                let idx = userDetails.userPhoneList.indexOf(value);
                if (idx !== -1) {
                    userDetails.userPhoneList.splice(idx, 1);
                    if (value === userDetails.phone) {
                        let newPhone = null;
                        if (userDetails.userPhoneList.length > 0) {
                            // if there are still more phone numbers, set the next available number as primary phone in patientdb
                            // this primary phone will be updated the next time user adds a new "first" phone number
                            newPhone = userDetails.userPhoneList[0];
                        }
                        setUserDetails(prevState => ({
                            ...prevState,
                            [key]: newPhone
                        }));
                    }
                }
            }
        }
        setUserDetails(prevState => ({
            ...prevState}));
    }
    
    // this callback processes differently from contact details callback because changes are not saved until user explicitly clicks save
    const updateProfileDataCallback = (type, operation, key, value) => {
        if (type === 'property') {
            if (key === 'lastName') {
                setIsLastNameValid(value.length > 0);
            }

            if (key === 'firstName') {
                setIsFirstNameValid(value.length > 0);
            }
            
            setUnsavedProfileData(prevState => ({
                ...prevState,
                [key]: value
            }));
        }
        setIsUpdated(true);
    }

    var todayDate = new Date(getBirthYear(today), getBirthMonth(today)-1, getBirthDay(today), 0, 0, 0, 0, 0);
    var currentBirthDate = new Date(userDetails.birthYear, userDetails.birthMonth-1, userDetails.birthDay, 0, 0, 0, 0, 0);

    const saveUserProfile = () => {
        if (isFirstNameValid && isLastNameValid) {
            saveUserProfileFunction(userDetails)
                .then(result => {
                    setProfileChangeSuccessful(true);
                    setEmailInUse(false);

                    refetch().then((result) => {
                        setCurrentUser(result.data.currentCambianUser);
                        setCurrentUserInSessionStorage(result.data.currentCambianUser);
                    })
                })
                .catch(error => {
                    setProfileChangeSuccessful(false);
                    if(error.message.startsWith("User with email:")) {
                        setEmailInUse(true);
                    }
                });

            setProfileDataChanged(true);

        } else {
            console.log('Cannot update as mandatory field(s) are not filled in');
        }
    }
    const handleSave = () => {
        setUserDetails(unsavedProfileData);
        setIsUpdated(false);
    }
    const handleCloseChangeResultModal = () => {
        setProfileDataChanged(false);
        setIsComplete(true);
    }
    
    useEffect(() => {
        if (isComplete) {
            window.location.replace(process.env.REACT_APP_WEBSITE_CONTEXT);
        }
    });

    return (
        <div className="componentBorder">
            <Box>
                <h3 style={{marginLeft: 20}}>{t("User Profile")}</h3>
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable" scrollButtons allowScrollButtonsMobile>
                        <Tab label={<Typography sx={{fontSize:{xs: 15, md: 17}, fontWeight: 500}}>{t("Name")}</Typography>} {...a11yProps(0)} />
                        <Tab label={<Typography sx={{fontSize:{xs: 15, md: 17}, fontWeight: 500}}>{t("Demographics")}</Typography>} {...a11yProps(1)} />
                        <Tab label={<Typography sx={{fontSize:{xs: 15, md: 17}, fontWeight: 500}}>{t("Contact")}</Typography>} {...a11yProps(2)} />
                        <Tab label={<Typography sx={{fontSize:{xs: 15, md: 17}, fontWeight: 500}}>{t("Address")}</Typography>} {...a11yProps(3)} />
                        <Tab label={<Typography sx={{fontSize:{xs: 15, md: 17}, fontWeight: 500}}>{t("Identification")}</Typography>} {...a11yProps(4)} />
                    </Tabs>
                </Box>

                <TabPanel value={value} index={0}>
                    <NamePanel userDetail={unsavedProfileData}
                                   updateProfileDataCallback={updateProfileDataCallback}/>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <DemographicsPanel userDetail={userDetails}
                                  updateProfileDataCallback={updateContactDataCallback}/>
                </TabPanel>

                <TabPanel value={value} index={2}>
                    <ContactPanel userDetail={userDetails}
                                  updateProfileDataCallback={updateContactDataCallback}/>
                </TabPanel>

                <TabPanel value={value} index={3}>
                    <AddressPanel userDetail={unsavedProfileData}
                                  updateProfileDataCallback={updateProfileDataCallback}/>
                </TabPanel>

                <TabPanel value={value} index={4}>
                    <IdentificationPanel userDetail={unsavedProfileData}
                                  updateProfileDataCallback={updateProfileDataCallback}/>
                </TabPanel>
                {value!==1 && value!== 2
                ?
                    <ButtonSection firstValid={isFirstNameValid}
                    lastValid={isLastNameValid}
                    isUpdated={isUpdated}
                    isInvalidDateOfBirth={currentBirthDate.getTime() > todayDate.getTime() || isNaN(currentBirthDate.getTime())}
                    saveUserProfile={() => handleSave()}/>
                : <></>}
                

                <Dialog open={isProfileDataChanged && profileChangeSuccessful === false && emailInUse === false}
                        onClose={handleCloseChangeResultModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title">
                        {t("Profile Update Unsuccessful")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {
                                t("Profile was not updated.  Please try again or contact support.")
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" 
                                onClick={() => handleCloseChangeResultModal()}>
                            {t("OK")}
                        </Button>
                    </DialogActions>
                </Dialog>

            </Box>
        </div>
    );
}

export default EditUserProfile;