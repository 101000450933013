import {
    Box, Button,
    Skeleton,
    Stack,
    Table,
    TableBody, TableCell,
    TableContainer,
    TableRow,
    Typography
} from "@mui/material";
import React from "react";
import {useMutation, useQuery} from "@apollo/client";
import {
    GET_ORGANIZATION_DETAILS_AS_SUMMARY,
    GET_QUESTIONNAIRE_RESPONSE_SHARE_DETAIL
} from "../../../../gqlQueries";
import {extractDateFromLocaleString} from "../../../utility";
import {SHARE_QUESTIONNAIRE_RESPONSE} from "../../../../gqlMutators";
import { useAuthContext } from "navigator/security";

function ShareQuestionnaireResponse(props) {
    const { questionnaireResponseId, questionnaireName } = props;
    console.log(questionnaireResponseId);
    
    const {currentUser} = useAuthContext();
    const { loading, error, data } = useQuery(GET_QUESTIONNAIRE_RESPONSE_SHARE_DETAIL, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue,
            "QuestionnaireResponseId": questionnaireResponseId
        },
        fetchPolicy: "no-cache"
    });

    if (error) {
        console.error('something failed');
        return <div>Error: Something Failed - bloodPressure view</div>;

    } else if (loading) {
        return (
            <Box sx={{ display: 'flex' }}>
                <Skeleton variant="rectangular" width={400} height={100} />
            </Box>);

    } else {
        console.log(data);
        return (
            <Stack direction="column" alignItems="center" spacing={0} width={'100%'}>
                <Typography variant="h6">{questionnaireName}</Typography>
                <Box>
                    {/*<Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("Questionnaires")}</Typography>*/}
                    <TableContainer>
                        <Table
                            sx={{minWidth: 200}}
                            aria-labelledby="tableTitle"
                            size={'small'}>
                            <TableBody>

                                {

                                    data.getQuestionnaireResponseShareDetail
                                    .map((sharedRecord, index) => {
                                        console.log(sharedRecord);
                                        return (
                                                <ShareQuestionnaireResponseRow key={sharedRecord.organizationId}
                                                                               questionnaireResponseId={questionnaireResponseId}
                                                                               organizationId={sharedRecord.organizationId}
                                                                               isShared={sharedRecord.isShared}
                                                                               sharedDate={sharedRecord.sharedDate}/>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Stack>
        );
    }
}

function ShareQuestionnaireResponseRow(props) {
    const {questionnaireResponseId, organizationId} = props;

    const [isShared, setIsShared] = React.useState(props.isShared);
    const [sharedDate, setSharedDate] = React.useState(props.sharedDate);

    const [,setLastSharedWithOrg] = React.useState();

    const [shareQuestionnaireResponse] = useMutation(SHARE_QUESTIONNAIRE_RESPONSE);

    const shareQR = (questionnaireResponseId, organizationType, organizationId) => {
        shareQuestionnaireResponse({
            variables: {
                "QuestionnaireResponseId": questionnaireResponseId,
                "GrantAccessToOrgType": organizationType,
                "GrantAccessToOrgId": organizationId
            }
        }).then(result => {
            setIsShared(true);
            let currentDate =extractDateFromLocaleString(Date.now());
            setSharedDate(currentDate);

            setLastSharedWithOrg(result.data.shareQuestionnaireResponse);
        }).catch(error => {
            console.log(error);
        });
    }

    const {loading, error, data} = useQuery(GET_ORGANIZATION_DETAILS_AS_SUMMARY, {
        variables: {
            "OrganizationId": organizationId
        }
    });

    if (error) {
        console.log('something failed');
        return <div>Error: Something Failed - share QR view</div>;

    } else if (loading) {
        return(
            <TableRow key={organizationId}>
                <TableCell align="left">
                    <Skeleton variant="rectangular" width={300} height={20} />
                </TableCell>
            </TableRow>
        );

    } else {
        return(
            <TableRow key={organizationId}>
                <TableCell align="left">
                    <Stack direction="column" sx={{w: '300px'}}>
                        <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{data.getOrganizationDetail.name}</Typography>
                        <Typography variant="subtitle2">{data.getOrganizationDetail.tagLine}</Typography>
                    </Stack>
                </TableCell>

                <TableCell sx={{width: '45%'}}>
                    {
                        sharedDate !== null
                        ? <Typography variant="subtitle2" sx={{w: '30px'}}>Data sent to {data.getOrganizationDetail.name} on <Typography variant="subtitle2" sx={{fontWeight: 'bold'}}>{extractDateFromLocaleString(sharedDate)}</Typography></Typography>
                        : <Typography sx={{w: '30px'}}></Typography>
                    }
                </TableCell>

                <TableCell>
                    {
                        isShared
                            ?  <></>
                            : <Button variant="contained"
                                      color="success"
                                      onClick={() => shareQR(questionnaireResponseId,
                                                             data.getOrganizationDetail.organizationType,
                                                             data.getOrganizationDetail.organizationId)}>
                                Share
                              </Button>
                    }
                </TableCell>
            </TableRow>
        );
    }
}

export default ShareQuestionnaireResponse;