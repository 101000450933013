import React, { useEffect} from "react";
import {Button, Checkbox, FormControlLabel, FormGroup, Stack} from "@mui/material";
import {Email, PhoneNumber, HoverIconButton, PullDownCollection} from "@cambianrepo/cambianreact";
import UserEmail from "./UserEmail";
import UserPhone from "./UserPhone";
import {useTranslation} from "react-i18next";

function ContactPanel(props) {
    useEffect(() => {
        setUserPhoneList(props.userDetail.userPhoneList);
        setUserEmailList(props.userDetail.userEmailList);
      }, [props.userDetail]);
    const { t } = useTranslation();
    const {primaryEmail, primaryPhone} = props.userDetail;
    const [userEmailList, setUserEmailList] = React.useState(props.userDetail.userEmailList);
    const [userPhoneList, setUserPhoneList] = React.useState(props.userDetail.userPhoneList);
    const [showNewEmailField, setShowNewEmailField] = React.useState(false);
    const [newEmail, setNewEmail] = React.useState("");
    const [showNewPhoneField, setShowNewPhoneField] = React.useState(false);
    const [newPhone, setNewPhone] = React.useState("");
    const [subscribeToEmails, setSubscribeToEmails] = React.useState(props.userDetail.subscribeToEmails);

    const handleAddNewEmailAddress = () => {
        if (newEmail.trim().length > 0) {
            props.updateProfileDataCallback("emailList", "add", "email", newEmail)
            setShowNewEmailField(false);
        }
    }

    const handleAddNewPhone = () => {
        if (newPhone.trim().length > 0) {
            props.updateProfileDataCallback("phoneList", "add", "phone", newPhone)
            setShowNewPhoneField(false);
        }
    }

    const handleSubscribeToEmailsChange = (event, key) => {
        props.updateProfileDataCallback("property", "change", key, event.target.checked);
        setSubscribeToEmails(event.target.checked);
    };

    return (
        <Stack direction="column" alignItems="left" spacing={2}>
            <PullDownCollection title={t("Email Addresses")}
                                titleVariant="subtitle1"
                                isExpanded={true}>
                <Stack direction="column"
                       alignItems="left"
                       spacing={1}>

                    {userEmailList.map((email, index) => {
                            return (
                                <UserEmail key={email}
                                           emailAddress={email}
                                           isPrimary={email === primaryEmail}
                                           updateProfileDataCallback={props.updateProfileDataCallback}/>
                            );
                        }
                    )}
                </Stack>
                <HoverIconButton text={t("Add email address")}
                                 textDecoration="underline"
                                 variant="subtitle2"
                                 menuSelectionCallback={() => setShowNewEmailField(!showNewEmailField)}/>
                {showNewEmailField
                    ? <>
                        <Email id="add-email"
                               label=""
                               isRequired={false}
                               setWidth={"80%"}
                               invalidEmailCallback={()=>{}}
                            //    sx={{minWidth: '150px', maxWidth: '300px'}}
                               validEmailCallback={(_,email) => setNewEmail(email)}/>
                        <Button variant="contained" sx={{marginTop: "8px", marginLeft:"8px"}} onClick={() => handleAddNewEmailAddress()}>
                            {t("Add")}
                        </Button>
                    </>
                    : <div />}
            </PullDownCollection>

            <PullDownCollection title={t("Phone Numbers")}
                                titleVariant="subtitle1"
                                isExpanded={true}>
                <Stack direction="column"
                       alignItems="left"
                       spacing={1}>

                    {userPhoneList.map((phoneNumber, index) => {
                            return (
                                <UserPhone key={phoneNumber}
                                           phoneNumber={phoneNumber}
                                           isPrimary={phoneNumber === primaryPhone}
                                           updateProfileDataCallback={props.updateProfileDataCallback}/>
                            );
                        }
                    )}
                </Stack>
                <HoverIconButton text={t("Add phone number")}
                                 textDecoration="underline"
                                 variant="subtitle2"
                                 menuSelectionCallback={() => setShowNewPhoneField(!showNewPhoneField)}/>
                {showNewPhoneField
                    ? <>
                        <PhoneNumber id="add-phone" setWidth={"80%"}
                                     validPhoneNumberCallback={(phoneNumber) => setNewPhone(phoneNumber)}/>
                        <Button variant="contained" sx={{marginTop: "8px"}} onClick={() => handleAddNewPhone()}>
                            {t("Add")}
                        </Button>
                    </>
                    : <div />}
            </PullDownCollection>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={subscribeToEmails}
                                       onChange={(event) => handleSubscribeToEmailsChange(event, "subscribeToEmails")} />}
                    label={t("Subscribe to email notifications")} />
            </FormGroup>
        </Stack>
    );
}

export default ContactPanel;