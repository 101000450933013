import React from 'react'
import {ApolloClient, ApolloProvider, createHttpLink, from, InMemoryCache} from "@apollo/client";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {clearSelectedView, getAuthFromSessionStorage} from "./utility";
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { useAuthService } from './security';

const GET_AUTH_TOKEN = "getAuthToken"
const GET_CURRENT_USER = "getCurrentCambianUser"
const REGISTER_USER = "registerNewUser"
const RESET_PASSWORD = "resetPassword"
const CHANGE_PASSWORD = "changePassword" // TODO: Make the link abort when an accessToken expires.
const RESET_PASSWORD_REQUEST = "resetPasswordRequest"
const CHECK_EMAIL_OTP_VALIDATION = "checkEmailOtpVerification"
const AUTH_REQUESTS=[GET_AUTH_TOKEN, GET_CURRENT_USER, REGISTER_USER, RESET_PASSWORD, RESET_PASSWORD_REQUEST, CHECK_EMAIL_OTP_VALIDATION]

const CustomApolloProvider = ({children}) => {  
    const { t } = useTranslation();

    const [encounteredError, setEncounteredError] = React.useState(false);
    const [errorTitle, setErrorTitle] = React.useState("");
    const [errorContent, setErrorContent] = React.useState("");
    const errorSignoutNeeded = React.useRef(false);

    const httpLink = createHttpLink({
        uri: process.env.REACT_APP_GRAPHQL_URL
    });
    
    const authLink = setContext((_, {headers}) => {
        // get the authentication token from session storage if it exists
        const auth = getAuthFromSessionStorage();
    
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: auth ? `Bearer ${auth.accessToken}` : "",
            }
        }
    });
    
    const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
        let errorDialogTitle;
        let errorDialogContent;
        // skip error dialog for Auth related requests
        if (AUTH_REQUESTS.includes(operation.operationName)) {
            return;
        }
        if (graphQLErrors !== undefined && graphQLErrors !== null && graphQLErrors.length > 0) {
            clearSelectedView();
            graphQLErrors.forEach(({message, locations, path, extensions}) => {
                if (extensions === undefined || extensions.HttpStatus === undefined) {
                    console.log(`[GraphQL error at ${path}]: Message: ${message}, Extensions: ${extensions}`);
                    errorDialogTitle = t('Application Error');
                    errorDialogContent = t('An unexpected error occurred at ') + path + t(' - please contact technical support.  ') + message;
    
                } else {
                    let httpStatus = extensions.HttpStatus;
                    if (httpStatus === 401 || httpStatus === 403) {
                        errorSignoutNeeded.current=true;
                        errorDialogTitle = t("You have been signed out");
                        errorDialogContent = t("Please sign in again");
                        console.log(`[GraphQL error at ${path}]: HttpStatus: ${httpStatus} Message: ${message}`);
    
                    } else if (httpStatus === 400) {
                        errorDialogTitle = t('Warning');
                        errorDialogContent = message;
                        console.log(`[GraphQL error at ${path}]: HttpStatus: ${httpStatus} Message: ${message}`);
    
                    } else {
                        errorDialogTitle = extensions.errorType;
                        errorDialogContent = t('HttpStatus ') + httpStatus + t(': Error at ') + path;
                        console.log(`[GraphQL error at ${path}]: HttpStatus: ${httpStatus} Message: ${message}`);
                    }
                }
            });
        } else if (networkError) {
            console.log(`[Network error]: ${networkError}`);
            errorDialogTitle = t('Network Error');
            errorDialogContent = t('Back end services are unavailable at this time.  Please contact technical support');
        }
    
        setErrorTitle(errorDialogTitle);
        setErrorContent(errorDialogContent);
        setEncounteredError(true);
    });
    
    
    // Memoize graphql client and use the same instance on rerender 
    const gqlClient = React.useMemo(() => new ApolloClient({
        link: from([errorLink, authLink, httpLink]),
        cache: new InMemoryCache()
    }),[]);

    const {logout} = useAuthService(gqlClient);
    
    const closeErrorDialog = () => {
        if (errorSignoutNeeded.current) {
            logout(true);
        }
        setEncounteredError(false);
    }

    return (
      <>
        <ApolloProvider client={gqlClient}>{children}</ApolloProvider>

        <Dialog open={encounteredError} onClose={closeErrorDialog}>
          <DialogTitle id="error-message-title">{errorTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText id="error-message-description">
              {errorContent}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => closeErrorDialog()}>
              {"OK"}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
}

export default CustomApolloProvider;