import React from "react";
import {Stack} from "@mui/material";
import {NewsItem} from "@cambianrepo/cambianreact";
import {sanitizeHtmlText} from "../../../utility";

function NewsItemList(props) {
    const { newsfeedData } = props;
 
    return(
        <Stack direction="column" alignItems="center" spacing={2.5}>
            {newsfeedData.map(item => (
                <NewsItem key={Math.random()}
                          organizationName={item.organizationName}
                          creationDate={item.creationDate}
                          logo={item.logo}
                          videoSrc={item.videoSrc}
                          imageSrc={item.imageSrc}
                          text={sanitizeHtmlText(item.text)}/>
            ))}
        </Stack>
    );
}

export default NewsItemList;