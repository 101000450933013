import {Link} from "react-router-dom";
import { useTheme } from "@mui/styles";
import {useMediaQuery} from "@mui/material";

function CambianBranding(props) {
    const {selectedViewCallback} = props;
    const theme = useTheme();
    const greaterThanMid = useMediaQuery(theme.breakpoints.up("md"));
    
    return (
        <Link to="/" style={{cursor:'pointer'}} onClick={() => selectedViewCallback('HomeView')}>
            <img src={process.env.REACT_APP_WEBSITE_CONTEXT + "/Cambian_Navigator.png"} 
                alt="Cambian branding for Navigator app" 
                width={greaterThanMid ? 211 : 141} 
                height={greaterThanMid ? 60 : 40}/>
        </Link>
    );
}

export {CambianBranding};