import React from "react";
import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from "react-i18next";
import { CambianTheme } from "navigator/CambianTheme";

function DemographicsPanel(props) {
    const { t } = useTranslation();
    const { userDetail: { birthMonth, birthDay, birthYear, gender }, updateProfileDataCallback } = props;

    var initialBirthDate = null;
    if (birthMonth !== null && birthYear !== null && birthYear !== null) {
        initialBirthDate = new Date(birthYear, birthMonth - 1, birthDay, 0, 0, 0, 0);
    }

    const [birthDate, setBirthDate] = React.useState(initialBirthDate);

    //Disable typing for date picker
    const onKeyDown = (e) => {
        e.preventDefault()
    }

    const handleBirthDateChange = (newValue) => {
        setBirthDate(newValue);
        updateProfileDataCallback("property", "change", "birthMonth", newValue ? newValue.getMonth() + 1 : null);
        updateProfileDataCallback("property", "change", "birthDay", newValue ? newValue.getDate() : null);
        updateProfileDataCallback("property", "change", "birthYear", newValue ? newValue.getFullYear() : null);
    };

    const [selectedGender, setSelectedGender] = React.useState(gender === null ? "none" : gender);
    const handleGenderSelection = (event) => {
        //had to create a hack using "none" for empty value to mitigate floating label "Gender" overlapping "Select.." label when empty value is selected;
        //https://github.com/mui/material-ui/issues/8581#issuecomment-421487873 @brennancheung's comment
        setSelectedGender(event.target.value);
        updateProfileDataCallback("property", "change", "gender", event.target.value === "none" ? null : event.target.value);
    };

    return (
        <Stack direction="column" alignItems="left" spacing={2} sx={{
            '& .MuiInputLabel-root': {
                color: 'grey'
            },
        }}>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1} sx={{ flexWrap: 'wrap', maxWidth: '210px', minWidth: '150px' }}>
                <DatePicker label={t("Date of Birth")}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    value={birthDate}
                    defaultValue="yyyy-mm-dd"
                    maxDate={Date.now()}
                    onChange={handleBirthDateChange}
                    // TODO: componentsProps is deprecated in MUI v6. When migrating to v6, Use slotProps instead.
                    componentsProps={{
                        actionBar: {
                            actions: ['clear']
                        }
                    }}
                    renderInput={(params) => <TextField onKeyDown={onKeyDown} {...params} />} />
            </Stack>

            <TextField id="gender"
                select
                label={t("Gender")}
                value={selectedGender}
                defaultValue="none"
                sx={{ maxWidth: '210px', minWidth: '150px' }}
                onChange={handleGenderSelection}>
                <MenuItem value={"none"}>{t("Select Gender...")}</MenuItem>
                <MenuItem value={"male"}>{t("Male")}</MenuItem>
                <MenuItem value={"female"}>{t("Female")}</MenuItem>
                <MenuItem value={"other"}>{t("Other")}</MenuItem>
            </TextField>


            <Typography variant="caption" sx={{ color: CambianTheme.palette.primary.main, maxWidth: '395px', letterSpacing: '0.00938em' }}>
                {t("Your demographic data is optional and is not shared with others " +
                    "without your explicit agreement. Some organizations may " +
                    "request that you provide this information in order to identify " +
                    "you or to perform analysis. You can always remove all information" +
                    " submitted in these categories.")}
            </Typography>
        </Stack>
    );
}


export default DemographicsPanel;