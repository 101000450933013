import {useQuery} from "@apollo/client";
import {Skeleton} from "@mui/material";
import SortedConnectionList from "./SortedConnectionList";
import {GET_NETWORK_FOR_CAMBIAN_USER} from "../../../../gqlQueries";
import {errorMessageAction} from "../../../utility";
import { useAuthContext } from "navigator/security";

function ConnectionList(props) {
    const {organizationId, selectedViewCallback, selectedOrganizationCallback} = props;
    const {currentUser} = useAuthContext();
 
    const {loading, error, data} = useQuery(GET_NETWORK_FOR_CAMBIAN_USER, {
        variables: {
            "CambianUserId": currentUser.userReference.globalId,
            "PatientId": currentUser.patientReference.idValue
        }
    });

    if (error) {
        return errorMessageAction(error.graphQLErrors);

    } else if (loading) {
        return (
            <Skeleton variant="rectangular" width={'100%'} height={400} sx={{mt:'20px'}} />
        );

    } else {
        const pendingConnectionList = data.getNetwork.filter(function (connection, index) { return connection.status === "PENDING";});

        return (
            <>
                {
                    pendingConnectionList.length > 0 && <SortedConnectionList organizationId={organizationId}
                                                                             selectedViewCallback={selectedViewCallback}
                                                                             selectedOrganizationCallback={selectedOrganizationCallback}
                                                                             data={data.getNetwork}
                                                                             status="PENDING"
                                                                             sortOrder={'desc'}/>
                }

                <SortedConnectionList organizationId={organizationId}
                                      selectedViewCallback={selectedViewCallback}
                                      selectedOrganizationCallback={selectedOrganizationCallback}
                                      data={data.getNetwork}
                                      status="CONNECTED"
                                      sortOrder={'desc'}/>
            </>
        );
    }
}


export default ConnectionList;