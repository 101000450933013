import {
    Box, 
    Card, 
    Grid
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import Typography from '@mui/material/Typography';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    notification: {
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    content : {
        paddingTop: "15px",
        paddingBottom: "15px",
        display: "flex",
        justifyContent: "center",
        flex: 1,
        backgroundColor: "#d9edf7", 
        color: '#3a87ad'
    }
}));

function Notification() {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Grid container direction="row" className={classes.notification}>
            <Box component={Grid} item xs={3} display={{xs: "block"}}/>
            <Box component={Grid} item xs={6} display={{xs: "block"}} >
                <Card className={classes.content}>
                    <Typography variant="subtitle2">
                        {t("Notice something different? Welcome to the new release of Cambian Navigator")}
                    </Typography>
                </Card>    
            </Box>
            <Box component={Grid} item xs={3} display={{xs: "block"}}/>
        </Grid>
    );
}

export {Notification};