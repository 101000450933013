import React from "react";
import {Stack, TextField,Alert} from "@mui/material";
import {UploadProfile} from "@cambianrepo/cambianreact";
import {useTranslation} from "react-i18next";
import {useAuthContext} from "../../security";
import {userInitials} from "../../utility";

function NamePanel(props) {
    const { t } = useTranslation();
    const {currentUser} = useAuthContext();
    const {firstName, lastName, photoImageUrl, photoImage} = props.userDetail;
    const [isFirstNameValid, setIsFirstNameValid] = React.useState(firstName !== null && firstName.length > 0);
    const [isLastNameValid, setIsLastNameValid] = React.useState(lastName !== null && lastName.length > 0);
    const [imageWarningVisible, setImageWarningVisible] = React.useState(false);
    const handleTextFieldChange = (event, key) => {
        if (key === 'firstName') {
            setIsFirstNameValid(event.target.value !== null && event.target.value.length > 0);
        }

        if (key === 'lastName') {
            setIsLastNameValid(event.target.value !== null && event.target.value.length > 0);
        }
        props.updateProfileDataCallback("property", "change", key, event.target.value);
    }

    // Used for the <UploadProfile /> component
    //
    const handleProfileImage = (width, height, size, base64) => {
        console.log("+++ handleProfileImage");
        setImageWarningVisible(false);
        console.log('handleProfileImage - Dimensions: ' + width + ' x ' + height + ', Size: ' + size + ' bytes');
        props.updateProfileDataCallback("property", "change", "photoImage", base64);
        console.log("--- handleProfileImage");
    }

    // Used for the <UploadProfile /> component
    //
    const handleInvalidProfileImage = (target, reason) => {
        console.log("+++ handleInvalidProfileImage");
        console.log('handleInvalidProfileImage ' + reason);
        setImageWarningVisible(true);
        console.log("--- handleInvalidProfileImage");
    }
    
    return (
        <Stack direction="column" alignItems="left" spacing={2} sx={{
            '& .MuiInputLabel-root': {
                color: 'grey'
            },
        }}>
            <TextField required
                       error={!isFirstNameValid}
                       id="first-name"
                       label={t("First Name")}
                       defaultValue={firstName}
                       sx={{maxWidth: '350px'}}
                       onChange={(event) => handleTextFieldChange(event, "firstName")}/>

            <TextField required
                       error={!isLastNameValid}
                       id="last-name"
                       label={t("Last Name")}
                       defaultValue={lastName}
                       sx={{maxWidth: '350px'}}
                       onChange={(event) => handleTextFieldChange(event, "lastName")}/>
            {imageWarningVisible === true &&
                <Stack direction="column" alignItems="left" sx={{mt: '20px'}}>
                    <Alert sx={{width:'fit-content'}} severity="warning">Upload image failed. Only JPG and PNG formats accepted. Image must be under 200kb. Please try again.</Alert>
                </Stack>
            }
            <UploadProfile style={{paddingTop: '55px'}}
                           defaultText={userInitials(currentUser)}
                           photoImageUrl={photoImage ? photoImage : photoImageUrl}
                           changeCallback={handleProfileImage}
                           errorCallback={handleInvalidProfileImage}
                           maximumSize={200 * 1024}
                           textMessage={'Format: PNG, JPG. Recommended: 100 x 100 pixels; < 200kb'}/>
        </Stack>
    );
}


export default NamePanel;