import {useNavigate, useParams} from "react-router-dom";
import {setSelectedOrganizationId, setSelectedView} from "../../../utility";
import React, {useEffect} from "react";
import Loader from "../../../Loader";

function RouteAvailablePlans() {

   let { organizationId } = useParams();
   let navigate = useNavigate();
   
   useEffect(() => {
      setSelectedOrganizationId(organizationId);
      setSelectedView('OrganizationResourceView');
      navigate('/');
   });

   return(<Loader active={true}/>);
}

export default RouteAvailablePlans;