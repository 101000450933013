import Navigator from "./Navigator";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
// import {CambianTheme} from "@cambianrepo/cambianreact";
import { CambianTheme } from "./CambianTheme_CambianReact";
import ProtectedRoute from "./ProtectedRoute";
import SignInPage from "./pages/SignInPage";
import ResetPasswordPage from "./pages/resetpassword/ResetPasswordPage";
import RouteAvailablePlans from "./pages/main/plan/RouteAvailablePlans";
import CustomApolloProvider from "./CustomApolloProvider";
import { AuthProvider } from "./security";
// Here is an example json theme:
//   https://gist.githubusercontent.com/phusick/b5a114b9fd4baeca339b12160139635d/raw/c93c0b71bab55bf2bde09c3a2052718faa445bdc/material-ui-theme.json

function WrappedSecuredApp() {
    return (
        <AuthProvider>
            <CustomApolloProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ThemeProvider theme={CambianTheme}>
                        <CssBaseline/>

                        <BrowserRouter basename={process.env.REACT_APP_WEBSITE_CONTEXT}>  {/* // Will be '/navigator'  */}
                            <Routes>
                                {/* These are public routes (Does not require authenticated user) */}
                                <Route exact path='/public' element={<SignInPage/>}/>
                                <Route exact path='/rcp' element={<ResetPasswordPage/>}/>
                                {/* These are private(protected) routes that require an authenticated user */}
                                <Route path="/availableplans/:organizationId" element={<ProtectedRoute><RouteAvailablePlans/></ProtectedRoute>}/>
                                <Route path="*" element={<ProtectedRoute><Navigator/></ProtectedRoute>}/>
                            </Routes>
                        </BrowserRouter>
                    </ThemeProvider>
                </LocalizationProvider>
            </CustomApolloProvider>
        </AuthProvider>
    );
}

export default WrappedSecuredApp;