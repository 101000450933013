import React from "react";
import {Menu, MenuItem, Stack, Typography, useMediaQuery} from "@mui/material";
import StarRateIcon from "@mui/icons-material/StarRate";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {HoverIconButton} from "@cambianrepo/cambianreact";
import {useTranslation} from "react-i18next";

function UserEmail(props) {
    const { t } = useTranslation();
    const {emailAddress, isPrimary, updateProfileDataCallback} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const isSmallScreen = useMediaQuery('(max-width:320px)');
    const open = Boolean(anchorEl);

    const handleHamburgerMenu = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleHamburgerRemoveMenuItemClick = () => {
        setAnchorEl(null);

        console.log('Remove: ' + emailAddress);
        updateProfileDataCallback("emailList", "delete", "", emailAddress);
    };

    const handleHamburgerMakePrimaryMenuItemClick = () => {
        setAnchorEl(null);
        updateProfileDataCallback("property", "change", "primaryEmail", emailAddress);
    };

    if (isPrimary) {
        return (
          <Stack
            key={emailAddress}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: "30px", marginTop: 2 }}
          >
            <Typography
              noWrap={isSmallScreen}
              sx={!isSmallScreen && {
                flex: 1,
                wordBreak: "break-all",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            >
              {emailAddress}
            </Typography>
            <HoverIconButton icon={<StarRateIcon />} />
          </Stack>
        );
    }

    return (
        <Stack key={emailAddress}
               direction="row"
               justifyContent="space-between"
               alignItems="center"
               sx={{height:"30px", marginTop: 2}}>
            <Typography
              noWrap={isSmallScreen}
              sx={!isSmallScreen && {
                flex: 1,
                wordBreak: "break-all",
                whiteSpace: "pre-wrap",
                overflowWrap: "break-word",
              }}
            >{emailAddress}</Typography>
            <HoverIconButton icon={<MoreHorizIcon/>}
                             menuSelectionCallback={(event) => handleHamburgerMenu(event)} />
            <Menu id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                      vertical: "top",
                      horizontal: "right"
                  }}
                  keepMounted
                  transformOrigin={{
                      vertical: "top",
                      horizontal: "right"
                  }}
                  open={open}
                  onClose={() => setAnchorEl(null)}>

                <MenuItem key='removeitem' onClick={() => handleHamburgerRemoveMenuItemClick()}>
                    {t("Remove")}
                </MenuItem>

                <MenuItem key='makeprimary' onClick={() => handleHamburgerMakePrimaryMenuItemClick()}>
                    {t("Make Primary")}
                </MenuItem>
            </Menu>
        </Stack>
    );
}

export default UserEmail;
