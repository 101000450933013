import React from "react";
import {Box, Grid} from "@mui/material";


function TwoColumnPage(props) {
    return (
            <Grid container direction="row">
                <Box component={Grid} item xs={0}  sm={0}  md={0} lg={1} xl={1} display={{xs: "none", sm: "none", md: "none", lg: "block"}}/>

                <Box component={Grid} item xs={12} sm={12} md={8} lg={6.5} xl={6.5} display={{xs: "block"}} >
                    {props.leftColumn}
                </Box>

                <Box component={Grid} item xs={0}  sm={0}  md={0} lg={0.5} xl={0.5} display={{xs: "none", sm: "none", md: "none", lg: "block"}}/>

                <Box component={Grid} item xs={12} sm={12} md={4} lg={3} xl={3} display={{xs: "block"}} >
                    {props.rightColumn}
                </Box>

                <Box component={Grid} item xs={0}  sm={0}  md={0} lg={1} xl={1} display={{xs: "none", sm: "none", md: "none", lg: "block"}}/>
            </Grid>
    );
}

export default TwoColumnPage;